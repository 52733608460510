import React from 'react';
import {
  Grid, Button,
  Box, Typography, FormControlLabel, Checkbox,
  FormHelperText,
} from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers';
import usePlantsStore from '../../../stores/plantsStore';
import CustomDateTimePicker from '../../DateTimePicker';
import useScenarioStore from '../../../stores/scenarioStore';

export default function TimeSimulationSection() {
  const { selectedDateTime, setSelectedDateTime } = useScenarioStore();
  const adjustCurrentSimulatedDate = (amount, unit) => {
    const newDate = dayjs(selectedDateTime).add(amount, unit).toDate();
    if (newDate >= new Date()) {
      setSelectedDateTime(newDate);
    }
  };

  const isBeforeNow = (amount, unit) => dayjs(selectedDateTime).add(amount, unit).isBefore(dayjs());

  return (
    <Grid container spacing={1} justifyContent="center" mt={2}>
      <Grid item>
        <CustomDateTimePicker label="Simulation date" />
      </Grid>
      <Grid item xs={12} />
      <Grid item>
        <Button onClick={() => setSelectedDateTime(new Date())}>Now</Button>
      </Grid>
      <Grid item>
        <Button onClick={() => adjustCurrentSimulatedDate(-1, 'hour')} disabled={isBeforeNow(-1, 'hour')}>-1 Hour</Button>
      </Grid>
      <Grid item>
        <Button onClick={() => adjustCurrentSimulatedDate(1, 'hour')}>+1 Hour</Button>
      </Grid>
      <Grid item>
        <Button onClick={() => adjustCurrentSimulatedDate(-1, 'week')} disabled={isBeforeNow(-1, 'week')}>-1 Week</Button>
      </Grid>
      <Grid item>
        <Button onClick={() => adjustCurrentSimulatedDate(1, 'week')}>+1 Week</Button>
      </Grid>
      <Grid item>
        <Button onClick={() => adjustCurrentSimulatedDate(-1, 'month')} disabled={isBeforeNow(-1, 'month')}>-1 Month</Button>
      </Grid>
      <Grid item>
        <Button onClick={() => adjustCurrentSimulatedDate(1, 'month')}>+1 Month</Button>
      </Grid>
    </Grid>
  );
}
