import React, { useEffect, useMemo, useRef } from 'react';
import {
  Box, Card, CardContent, Grid, IconButton, Typography,
} from '@mui/material';
import { Close, Edit } from '@mui/icons-material';
import BorderLeftIcon from '@mui/icons-material/BorderLeft';
import { useShallow } from 'zustand/react/shallow';
import useScenarioStore from '../stores/scenarioStore';
import ShadeCard from './ShadeGrade';
import SliderWithButtons from '../components/SliderWithButtons';
import useUiStore from '../stores/uiStore';

export default function EditableCardSection({
  disableEdit = false, label = null, LabelIcon = null, middleLabel = null,
  renderChildrenFunction, onChangeEditable, editable: controlledEditable = false,
  storeKey = null, checkClickedOnCanvasEntity = () => {}, secondHierarchy = false,
  showLabelOnlyEditable = false,
}) {
  const [editable, setEditable] = React.useState(controlledEditable);
  const [wasClickedOnCanvasBefore, setWasClickedOnCanvasBefore] = React.useState(false);
  const [openedEditableSections, addOpenedEditableSection, removeOpenedEditableSection, clickedOnCanvasEntity, setClickedOnCanvasEntityId] = useUiStore(useShallow((state) => [state.openedEditableSections, state.addOpenedEditableSection, state.removeOpenedEditableSection, state.clickedOnCanvasEntity, state.setClickedOnCanvasEntityId]));
  const containerRef = useRef(null);
  const calculatedStoreKey = useMemo(() => storeKey || label + Math.random(), [storeKey, label]);

  const childrenContent = useMemo(() => renderChildrenFunction(editable), [editable, renderChildrenFunction]);

  useEffect(() => {
    setEditable(controlledEditable);
  }, [controlledEditable]);

  useEffect(() => () => {
    if (!storeKey) {
      return;
    }

    removeOpenedEditableSection(storeKey);
  }, []);

  const switchEditable = (ignoreOnChange = false) => {
    if (disableEdit) {
      return;
    }
    const newEditable = !editable;
    if (onChangeEditable && !ignoreOnChange) {
      onChangeEditable(newEditable);
    }
    setEditable(newEditable);

    if (!calculatedStoreKey) {
      return;
    }
    if (newEditable) {
      addOpenedEditableSection(calculatedStoreKey);
    } else {
      removeOpenedEditableSection(calculatedStoreKey);
    }
  };

  useEffect(() => {
    if (clickedOnCanvasEntity
       && (new Date() - clickedOnCanvasEntity.time) < 500
        && checkClickedOnCanvasEntity(clickedOnCanvasEntity.name)) {
      if (!editable) {
        switchEditable();
      } else if (onChangeEditable) {
        onChangeEditable(true);
      }
      setWasClickedOnCanvasBefore(true);
    } else if (clickedOnCanvasEntity) {
      if (editable) {
        switchEditable(clickedOnCanvasEntity.name !== null); // Todo: hack because else parent sets focusOnId to null because it updates after the setting of focusOnId
      }
    }
  }, [clickedOnCanvasEntity]);

  useEffect(() => {
    if (editable) {
      containerRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [editable]);

  const bgColor = editable ? (secondHierarchy ? '#f2f8ea' : '#e1e9d9') : null;

  return (
    <Box ref={containerRef} mt={secondHierarchy ? 1 : 0}>
      <Card
        elevation={0}
        sx={{
          // backgroundColor: bgColor,
          backgroundColor: bgColor,
          // boxShadow: editable ? '0px 0px 10px 0px rgba(0,0,0,0.1)' : 'none',
          boxShadow: 'none',
          border: editable ? '1px solid #b5d885' : undefined,
        }}
      >
        <Grid
          paddingX={secondHierarchy ? 1 : 1}
          paddingBottom={secondHierarchy ? 2 : 1}
          paddingTop={editable || !showLabelOnlyEditable ? 1 : 0}
          container
          rowSpacing={1}
          onClick={(disableEdit || !editable) ? switchEditable : null}
          sx={!editable && !disableEdit ? {
            cursor: 'pointer',

          } : null}
        >
          <Grid
            item
            xs={12}
            onClick={() => switchEditable()}
            sx={!disableEdit ? {
              zIndex: 10,
              cursor: 'pointer',
              '&:hover': {
                textDecoration: !editable ? 'underline' : 'none',
              },
            } : null}
          >
            {showLabelOnlyEditable && !editable ? null : (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={editable ? 1 : 0}
                pl="0px"
              >
                {label === null ? null : (
                  <Box
                    alignItems="center"
                    display="flex"
                  >
                    {LabelIcon !== null && (
                      <LabelIcon sx={{ marginRight: 1 }} fontSize={editable ? 'medium' : 'small'} />
                    )}
                    <Typography fontWeight="400" fontWeight={editable ? 'bold' : ''} variant={secondHierarchy ? (editable ? 'body1' : 'body2') : (editable ? 'body1' : 'body1')}>{label}</Typography>
                  </Box>
                )}
                {(editable && middleLabel) ? (<Box />) : (
                  <Box sx={{ float: 'right' }}>
                    <Typography fontWeight={editable ? 'bold' : ''} variant="caption">{middleLabel}</Typography>
                  </Box>
                )}
                {disableEdit || !editable ? null : (
                  <IconButton color="primary" size="small">
                    <Close fontSize={secondHierarchy ? 'small' : 'medium'} />
                  </IconButton>
                )}
              </Box>
            )}
          </Grid>
          {childrenContent != null && (
            <Grid item xs={12} mt={secondHierarchy ? 0 : 2}>
              <Grid container rowSpacing={editable ? 2 : 1} px={0}>
                {childrenContent}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Card>
    </Box>
  );
}
