import React, { useRef, useEffect } from 'react';
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
import useUiStore, { SELECTION_MODE, TABS_CONFIG } from '../../stores/uiStore';
import BuildingSelectionModeContent from './BuildingSelectionModeContent';
import CameraModeBar from './CameraModeBar';
import BalconyPositionSelectionModeContent from './BalconyPositionSelectionModeContent';
import useViewStore from '../../stores/viewStore';
import useIsMobile from '../../hooks/useIsMobile';
import NavigationBar from './NavigationBar';

export default function Sidebar() {
  const [selectedTab, setSelectedTab, selectionMode, setClickedOnCanvasEntity] = useUiStore((state) => [
    state.selectedTab,
    state.setSelectedTab,
    state.selectionMode,
    state.setClickedOnCanvasEntity,
  ]);
  const [setFocusOnId] = useViewStore((state) => [state.setFocusOnId]);
  const isMobile = useIsMobile();

  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [selectedTab, selectionMode]);

  const handleTabChange = (tab) => {
    if (selectionMode) {
      return;
    }
    setClickedOnCanvasEntity(null);
    setFocusOnId(null);
    setSelectedTab(tab.id);
  };

  const renderSelectionMode = () => {
    if (selectionMode === SELECTION_MODE.BUILDING) {
      return <BuildingSelectionModeContent />;
    }
    if (selectionMode === SELECTION_MODE.BALCONY_POSITION) {
      return <BalconyPositionSelectionModeContent />;
    }
    return 'Unknown selection mode';
  };

  const renderTabContent = () => {
    const tab = TABS_CONFIG[selectedTab];
    if (tab?.Component) {
      return <tab.Component />;
    }
    return 'Unknown tab';
  };

  return (
    <Box display="flex" flexDirection="column" height="100%">
      {!isMobile && (
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: '#8CAA62',
            borderBottom: 0,
            borderColor: 'divider',
          }}
        >
          <NavigationBar />
        </Box>
      )}

      <Box
        ref={contentRef}
        sx={{
          flex: 1,
          overflow: 'auto',
          pb: isMobile ? '72px' : 1,
          pt: 1,
          px: isMobile ? 1 : 1,
        }}
      >
        {selectionMode ? (
          renderSelectionMode()
        ) : (
          <Box sx={{ pt: isMobile ? 0 : 1 }}>
            {renderTabContent()}
          </Box>
        )}
      </Box>

      {isMobile && (
      <NavigationBar />
      )}
    </Box>
  );
}
