import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import {
  MAXTEMPERATUR_ATTRIBUTE,
  MINDESTTEMPERATUR_ATTRIBUTE,
  PLANT_DISTANCE_ATTRIBUTE,
  POT_SIZE_ATTRIBUTE, SOIL_ATTRIBUTE,
  SUN_ATTRIBUTE,
  WATER_NEED_ATTRIBUTE,
  WIND_SUSCEPTIBILITY_ATTRIBUTE,
} from '../../utils/plantAttributeUtils';

export function getOrderedAttributeArray(attributeType) {
  switch (attributeType) {
    case 'SUN':
      return [
        SUN_ATTRIBUTE.SHADE,
        SUN_ATTRIBUTE.MAINLY_SHADE,
        SUN_ATTRIBUTE.PARTIAL_SUN,
        SUN_ATTRIBUTE.MAINLY_SUN,
        SUN_ATTRIBUTE.FULL_SUN,
      ];
    case 'WATER_NEED':
      return [
        WATER_NEED_ATTRIBUTE.FEW,
        WATER_NEED_ATTRIBUTE.MEDIUM,
        WATER_NEED_ATTRIBUTE.MUCH,
      ];
    case 'WIND_SUSCEPTIBILITY':
      return [
        WIND_SUSCEPTIBILITY_ATTRIBUTE.LOW,
        WIND_SUSCEPTIBILITY_ATTRIBUTE.MEDIUM,
        WIND_SUSCEPTIBILITY_ATTRIBUTE.HIGH,
      ];
    case 'POT_SIZE':
      return [
        POT_SIZE_ATTRIBUTE.SMALL,
        POT_SIZE_ATTRIBUTE.MEDIUM,
        POT_SIZE_ATTRIBUTE.BIG,
      ];
    case 'PLANT_DISTANCE':
      return [
        PLANT_DISTANCE_ATTRIBUTE.SMALL,
        PLANT_DISTANCE_ATTRIBUTE.MEDIUM,
        PLANT_DISTANCE_ATTRIBUTE.BIG,
      ];
    case 'SOIL':
      return [
        SOIL_ATTRIBUTE.UNIVERSAL,
        SOIL_ATTRIBUTE.NUTRIENT_RICH,
        SOIL_ATTRIBUTE.DRAINING,
        SOIL_ATTRIBUTE.MOISTURE_RETENTIVE,
        SOIL_ATTRIBUTE.ACIDIC,
        SOIL_ATTRIBUTE.ALKALINE,
      ];
    case 'MINDESTTEMPERATUR':
      return [
        MINDESTTEMPERATUR_ATTRIBUTE.MINUS_FIFTEEN,
        MINDESTTEMPERATUR_ATTRIBUTE.MINUS_TEN,
        MINDESTTEMPERATUR_ATTRIBUTE.MINUS_FIVE,
        MINDESTTEMPERATUR_ATTRIBUTE.ZERO,
        MINDESTTEMPERATUR_ATTRIBUTE.FIVE,
        MINDESTTEMPERATUR_ATTRIBUTE.TEN,
        MINDESTTEMPERATUR_ATTRIBUTE.FIFTEEN,
      ];
    case 'MAXTEMPERATUR':
      return [
        MAXTEMPERATUR_ATTRIBUTE.TWENTYFIVE,
        MAXTEMPERATUR_ATTRIBUTE.THIRTY,
        MAXTEMPERATUR_ATTRIBUTE.THIRTYFIVE,
        MAXTEMPERATUR_ATTRIBUTE.FORTY,
      ];
    default:
      return null;
  }
}

export function getHighlightColor(locationIndex, specIndex) {
  const diff = Math.abs(locationIndex - specIndex);
  if (diff === 0) return 'darkgreen';
  if (diff === 1) return 'orange';
  return 'red';
}

export function compareAttribute(specAttribute, locationAttribute, orderedArray) {
  if (!specAttribute || !locationAttribute) return 'inherit';
  const locationIndex = orderedArray.findIndex((x) => x.label === locationAttribute.label);
  const specIndex = orderedArray.findIndex((x) => x.label === specAttribute.label);
  if (locationIndex < 0 || specIndex < 0) return 'inherit';

  return getHighlightColor(locationIndex, specIndex);
}

export function renderHighlightedAttributes({
  IconComp,
  label,
  specAttributes,
  locationAttribute,
  attributeType,
}) {
  if (!specAttributes || specAttributes.length === 0) return null;

  const orderedArray = getOrderedAttributeArray(attributeType);
  const hasLocation = !!locationAttribute && !!orderedArray;

  return (
    <Stack direction="row" spacing={1} alignItems="flex-start">
      <IconComp fontSize="small" sx={{ marginRight: 0.5 }} />
      <Typography
        variant="body2"
        component="div"
        sx={{ fontWeight: 'bold', minWidth: '130px' }}
      >
        {label}
        :
      </Typography>

      <Box>
        {specAttributes.map((specAttr, idx) => {
          const color = hasLocation
            ? compareAttribute(specAttr, locationAttribute, orderedArray)
            : 'inherit';

          return (
            <Typography
              key={`${specAttr.label}-${idx}`}
              component="span"
              variant="body2"
              color={color}
              sx={{ marginRight: 1 }}
            >
              {specAttr.label}
            </Typography>
          );
        })}
      </Box>
    </Stack>
  );
}
