import React from 'react';
import { Canvas } from '@react-three/fiber';

export const POT_CONTAINER_VARIANTS = {
  SHORT: {
    id: 'SHORT',
    width: 0.2,
  },
  SMALL: {
    id: 'SMALL',
    width: 0.35,
  },
  MIDDLE: {
    id: 'MIDDLE',
    width: 0.7,
  },
  LARGE: {
    id: 'LARGE',
    width: 1.2,
  },
  VERY_LARGE: {
    id: 'VERY_LARGE',
    width: 2,
  },
};

function PlantContainerTwo({
  height = 0.2,
  length = 0.2,
  potColor = '#78866B',
  variant,
}) {
  const { width } = POT_CONTAINER_VARIANTS[variant] || POT_CONTAINER_VARIANTS.MIDDLE;
  const borderThickness = 0.015;
  const soilHeight = 0.02;

  return (
    <group>
      {/* Base of the container */}
      <mesh
        position={[0, 0, 0]}
        castShadow
        receiveShadow
      >
        <boxGeometry args={[width, 0.05, length]} />
        <meshStandardMaterial color={potColor} />
      </mesh>

      {/* Front border */}
      <mesh
        position={[0, height / 2, -length / 2 + borderThickness / 2]}
        castShadow
        receiveShadow
      >
        <boxGeometry args={[width, height, borderThickness]} />
        <meshStandardMaterial color={potColor} />
      </mesh>

      {/* Back border */}
      <mesh
        position={[0, height / 2, length / 2 - borderThickness / 2]}
        castShadow
        receiveShadow
      >
        <boxGeometry args={[width, height, borderThickness]} />
        <meshStandardMaterial color={potColor} />
      </mesh>

      {/* Left border */}
      <mesh
        position={[-width / 2 + borderThickness / 2, height / 2, 0]}
        castShadow
        receiveShadow
      >
        <boxGeometry args={[borderThickness, height, length]} />
        <meshStandardMaterial color={potColor} />
      </mesh>

      {/* Right border */}
      <mesh
        position={[width / 2 - borderThickness / 2, height / 2, 0]}
        castShadow
        receiveShadow
      >
        <boxGeometry args={[borderThickness, height, length]} />
        <meshStandardMaterial color={potColor} />
      </mesh>

      {/* Soil in the middle */}
      <mesh
        position={[0, height - soilHeight / 2 - 0.05, 0]}
        castShadow
        receiveShadow
      >
        <boxGeometry args={[width - borderThickness, soilHeight, length - borderThickness]} />
        <meshStandardMaterial color="#66410b" />
      </mesh>
    </group>
  );
}

export default PlantContainerTwo;
