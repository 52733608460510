import {
  COLOR,
  BLOOMING_TIME_ATTRIBUTE,
  FRUIT_HARVEST_TIME_ATTRIBUTE,
  GROWING_TIME_ATTRIBUTE,
  GROWTH_SPEED_ATTRIBUTE,
  LEAVES_GREEN_TIME_ATTRIBUTE,
  MAXTEMPERATUR_ATTRIBUTE,
  MINDESTTEMPERATUR_ATTRIBUTE, MONTH,
  PLANT_CATEGORY,
  PLANT_DISTANCE_ATTRIBUTE,
  PLANT_MAX_SIZE_ATTRIBUTE, PLANT_SHAPE,
  POT_SIZE_ATTRIBUTE,
  SOIL_ATTRIBUTE,
  SUN_ATTRIBUTE,
  WATER_NEED_ATTRIBUTE,
  WIND_SUSCEPTIBILITY_ATTRIBUTE, YEARS_UNTIL_MAX_SIZE_ATTRIBUTE,
} from '../utils/plantAttributeUtils';

export const PLANT_SPECIFICATION = [
  {
    id: -1,
    name: 'Undefined',
    image: 'https://images.unsplash.com/photo-1554631221-f9603e6808be?q=80&w=2317&auto=format&fit=crop',
    info: 'No specification selected',
    shape: PLANT_SHAPE.FLOWER,
    categories: [PLANT_CATEGORY.CACTUS],
    optimalAttributes: {
      SUN: [SUN_ATTRIBUTE.FULL_SUN],
      WATER_NEED: [WATER_NEED_ATTRIBUTE.FEW],
      PLANT_DISTANCE: [PLANT_DISTANCE_ATTRIBUTE.SMALL],
      SOIL: [SOIL_ATTRIBUTE.DRAINING],
      MINDESTTEMPERATUR: [MINDESTTEMPERATUR_ATTRIBUTE.FIVE],
      MAXTEMPERATUR: [MAXTEMPERATUR_ATTRIBUTE.FORTY],
      WIND_SUSCEPTIBILITY: [WIND_SUSCEPTIBILITY_ATTRIBUTE.LOW],
      POT_SIZE: [POT_SIZE_ATTRIBUTE.SMALL],
      BLOOMING_COLOR: [COLOR.YELLOW],
      LEAF_COLOR: [COLOR.DARK_GREEN],
      LOSING_LEAVES_COLOR: [COLOR.YELLOW],
      GROWTH_SPEED: [GROWTH_SPEED_ATTRIBUTE.SLOW],
      PLANT_MAX_SIZE_ATTRIBUTE: [PLANT_MAX_SIZE_ATTRIBUTE.SMALL],
      YEARS_UNTIL_MAX_SIZE_ATTRIBUTE: [YEARS_UNTIL_MAX_SIZE_ATTRIBUTE.FIVE],
    },
    monthlyAttributes: {
      CUT_BACK_MONTHS: [MONTH.JANUARY, MONTH.FEBRUARY],
      BLOOMING_MONTHS: [MONTH.JULY, MONTH.AUGUST],
      GROWING_MONTHS: [
        MONTH.MARCH,
        MONTH.APRIL,
        MONTH.MAY,
        MONTH.JUNE,
        MONTH.JULY,
        MONTH.AUGUST,
        MONTH.SEPTEMBER,
      ],
      LOSING_LEAVES_MONTHS: [MONTH.SEPTEMBER],
      HARVEST_MONTHS: [],
    },
  },
  {
    id: 1,
    name: 'Cactus',
    image: 'https://images.unsplash.com/photo-1554631221-f9603e6808be?q=80&w=2317&auto=format&fit=crop',
    info: 'Needs minimal water. Thrives in hot and dry conditions.',
    shape: PLANT_SHAPE.BAMBOO,
    categories: [PLANT_CATEGORY.CACTUS],
    optimalAttributes: {
      SUN: [SUN_ATTRIBUTE.FULL_SUN],
      WATER_NEED: [WATER_NEED_ATTRIBUTE.FEW],
      PLANT_DISTANCE: [PLANT_DISTANCE_ATTRIBUTE.SMALL],
      SOIL: [SOIL_ATTRIBUTE.DRAINING],
      MINDESTTEMPERATUR: [MINDESTTEMPERATUR_ATTRIBUTE.FIVE],
      MAXTEMPERATUR: [MAXTEMPERATUR_ATTRIBUTE.FORTY],
      WIND_SUSCEPTIBILITY: [WIND_SUSCEPTIBILITY_ATTRIBUTE.LOW],
      POT_SIZE: [POT_SIZE_ATTRIBUTE.SMALL],
      BLOOMING_COLOR: [COLOR.YELLOW],
      LEAF_COLOR: [COLOR.GREEN],
      LOSING_LEAVES_COLOR: [COLOR.GREEN],
      GROWTH_SPEED: [GROWTH_SPEED_ATTRIBUTE.SLOW],
      PLANT_MAX_SIZE_ATTRIBUTE: [PLANT_MAX_SIZE_ATTRIBUTE.SMALL],
      YEARS_UNTIL_MAX_SIZE_ATTRIBUTE: [YEARS_UNTIL_MAX_SIZE_ATTRIBUTE.FIVE],
    },
    monthlyAttributes: {
      CUT_BACK_MONTHS: [], // Typically not cut
      BLOOMING_MONTHS: [MONTH.JULY], // Some cacti bloom in mid-summer
      GROWING_MONTHS: [MONTH.ALWAYS], // Minimal but year-round growth in warm conditions
      LOSING_LEAVES_MONTHS: [MONTH.SEPTEMBER],
      HARVEST_MONTHS: [],
    },
  },
  {
    id: 2,
    name: 'Basil',
    image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/90/Basil-Basilico-Ocimum_basilicum-albahaca.jpg/2560px-Basil-Basilico-Ocimum_basilicum-albahaca.jpg',
    info: 'Perfect for fresh herbs. Requires frequent watering and warm temperatures.',
    shape: PLANT_SHAPE.FLOWER,
    categories: [PLANT_CATEGORY.HERB],
    optimalAttributes: {
      SUN: [SUN_ATTRIBUTE.MAINLY_SUN],
      WATER_NEED: [WATER_NEED_ATTRIBUTE.MEDIUM],
      PLANT_DISTANCE: [PLANT_DISTANCE_ATTRIBUTE.SMALL],
      SOIL: [SOIL_ATTRIBUTE.NUTRIENT_RICH],
      MINDESTTEMPERATUR: [MINDESTTEMPERATUR_ATTRIBUTE.TEN],
      MAXTEMPERATUR: [MAXTEMPERATUR_ATTRIBUTE.THIRTYFIVE],
      WIND_SUSCEPTIBILITY: [WIND_SUSCEPTIBILITY_ATTRIBUTE.MEDIUM],
      POT_SIZE: [POT_SIZE_ATTRIBUTE.SMALL],
      BLOOMING_COLOR: [COLOR.WHITE],
      LEAF_COLOR: [COLOR.GREEN],
      LOSING_LEAVES_COLOR: [COLOR.GREEN],
      GROWTH_SPEED: [GROWTH_SPEED_ATTRIBUTE.MEDIUM],
      PLANT_MAX_SIZE_ATTRIBUTE: [PLANT_MAX_SIZE_ATTRIBUTE.SMALL],
      YEARS_UNTIL_MAX_SIZE_ATTRIBUTE: [YEARS_UNTIL_MAX_SIZE_ATTRIBUTE.ONE],
    },
    monthlyAttributes: {
      CUT_BACK_MONTHS: [MONTH.JUNE, MONTH.JULY, MONTH.AUGUST],
      BLOOMING_MONTHS: [MONTH.JULY, MONTH.AUGUST],
      GROWING_MONTHS: [MONTH.MAY, MONTH.JUNE, MONTH.JULY, MONTH.AUGUST, MONTH.SEPTEMBER],
      LOSING_LEAVES_MONTHS: [MONTH.SEPTEMBER],
      HARVEST_MONTHS: [MONTH.JUNE, MONTH.JULY, MONTH.AUGUST, MONTH.SEPTEMBER],
    },
  },
  {
    id: 3,
    name: 'Petunia',
    image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/Petunia_Violet.jpg/180px-Petunia_Violet.jpg',
    info: 'Vibrant flowers that bloom all summer. Prefers sunny locations.',
    shape: PLANT_SHAPE.FLOWER,
    categories: [PLANT_CATEGORY.FLOWER],
    optimalAttributes: {
      SUN: [SUN_ATTRIBUTE.MAINLY_SUN],
      WATER_NEED: [WATER_NEED_ATTRIBUTE.MEDIUM],
      PLANT_DISTANCE: [PLANT_DISTANCE_ATTRIBUTE.SMALL],
      SOIL: [SOIL_ATTRIBUTE.UNIVERSAL],
      MINDESTTEMPERATUR: [MINDESTTEMPERATUR_ATTRIBUTE.FIVE],
      MAXTEMPERATUR: [MAXTEMPERATUR_ATTRIBUTE.THIRTYFIVE],
      WIND_SUSCEPTIBILITY: [WIND_SUSCEPTIBILITY_ATTRIBUTE.MEDIUM],
      POT_SIZE: [POT_SIZE_ATTRIBUTE.MEDIUM],
      BLOOMING_COLOR: [COLOR.PURPLE, COLOR.RED, COLOR.WHITE],
      LEAF_COLOR: [COLOR.GREEN],
      LOSING_LEAVES_COLOR: [COLOR.GREEN],
      GROWTH_SPEED: [GROWTH_SPEED_ATTRIBUTE.MEDIUM],
      PLANT_MAX_SIZE_ATTRIBUTE: [PLANT_MAX_SIZE_ATTRIBUTE.VERY_BIG],
      YEARS_UNTIL_MAX_SIZE_ATTRIBUTE: [YEARS_UNTIL_MAX_SIZE_ATTRIBUTE.THREE],
    },
    monthlyAttributes: {
      CUT_BACK_MONTHS: [MONTH.JUNE, MONTH.JULY, MONTH.AUGUST],
      BLOOMING_MONTHS: [MONTH.MAY, MONTH.JUNE, MONTH.JULY, MONTH.AUGUST, MONTH.SEPTEMBER],
      GROWING_MONTHS: [MONTH.MAY, MONTH.JUNE, MONTH.JULY, MONTH.AUGUST, MONTH.SEPTEMBER],
      LOSING_LEAVES_MONTHS: [MONTH.SEPTEMBER],
      HARVEST_MONTHS: [],
    },
  },
  {
    id: 4,
    name: 'Strawberry',
    image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Garden_strawberry_%28Fragaria_%C3%97_ananassa%29_single2.jpg/2880px-Garden_strawberry_%28Fragaria_%C3%97_ananassa%29_single2.jpg',
    info: 'Produces delicious fruits all summer. Requires rich soil and sun.',
    shape: PLANT_SHAPE.FLOWER,
    categories: [PLANT_CATEGORY.FRUIT],
    optimalAttributes: {
      SUN: [SUN_ATTRIBUTE.FULL_SUN],
      WATER_NEED: [WATER_NEED_ATTRIBUTE.MEDIUM],
      PLANT_DISTANCE: [PLANT_DISTANCE_ATTRIBUTE.MEDIUM],
      SOIL: [SOIL_ATTRIBUTE.NUTRIENT_RICH],
      MINDESTTEMPERATUR: [MINDESTTEMPERATUR_ATTRIBUTE.MINUS_FIVE],
      MAXTEMPERATUR: [MAXTEMPERATUR_ATTRIBUTE.THIRTYFIVE],
      WIND_SUSCEPTIBILITY: [WIND_SUSCEPTIBILITY_ATTRIBUTE.MEDIUM],
      POT_SIZE: [POT_SIZE_ATTRIBUTE.MEDIUM],
      BLOOMING_COLOR: [COLOR.WHITE],
      LEAF_COLOR: [COLOR.GREEN],
      LOSING_LEAVES_COLOR: [COLOR.GREEN],
      GROWTH_SPEED: [GROWTH_SPEED_ATTRIBUTE.MEDIUM],
      PLANT_MAX_SIZE_ATTRIBUTE: [PLANT_MAX_SIZE_ATTRIBUTE.SMALL],
      YEARS_UNTIL_MAX_SIZE_ATTRIBUTE: [YEARS_UNTIL_MAX_SIZE_ATTRIBUTE.TWO],
    },
    monthlyAttributes: {
      CUT_BACK_MONTHS: [MONTH.SEPTEMBER],
      BLOOMING_MONTHS: [MONTH.MAY, MONTH.JUNE],
      GROWING_MONTHS: [
        MONTH.MARCH,
        MONTH.APRIL,
        MONTH.MAY,
        MONTH.JUNE,
        MONTH.JULY,
        MONTH.AUGUST,
        MONTH.SEPTEMBER,
      ],
      LOSING_LEAVES_MONTHS: [MONTH.SEPTEMBER],
      HARVEST_MONTHS: [MONTH.JUNE, MONTH.JULY],
    },
  },
  {
    id: 5,
    name: 'Lavender',
    image: 'https://upload.wikimedia.org/wikipedia/commons/7/7e/Single_lavender_flower02.jpg',
    info: 'Aromatic shrub with stunning purple flowers. Thrives in dry and sunny areas.',
    shape: PLANT_SHAPE.FLOWER,
    categories: [PLANT_CATEGORY.SHRUB],
    optimalAttributes: {
      SUN: [SUN_ATTRIBUTE.FULL_SUN],
      WATER_NEED: [WATER_NEED_ATTRIBUTE.FEW],
      PLANT_DISTANCE: [PLANT_DISTANCE_ATTRIBUTE.MEDIUM],
      SOIL: [SOIL_ATTRIBUTE.DRAINING],
      MINDESTTEMPERATUR: [MINDESTTEMPERATUR_ATTRIBUTE.MINUS_FIVE],
      MAXTEMPERATUR: [MAXTEMPERATUR_ATTRIBUTE.THIRTYFIVE],
      WIND_SUSCEPTIBILITY: [WIND_SUSCEPTIBILITY_ATTRIBUTE.MEDIUM],
      POT_SIZE: [POT_SIZE_ATTRIBUTE.MEDIUM],
      BLOOMING_COLOR: [COLOR.PURPLE],
      LEAF_COLOR: [COLOR.GREEN],
      LOSING_LEAVES_COLOR: [COLOR.GREEN],
      GROWTH_SPEED: [GROWTH_SPEED_ATTRIBUTE.MEDIUM],
      PLANT_MAX_SIZE_ATTRIBUTE: [PLANT_MAX_SIZE_ATTRIBUTE.MEDIUM],
      YEARS_UNTIL_MAX_SIZE_ATTRIBUTE: [YEARS_UNTIL_MAX_SIZE_ATTRIBUTE.THREE],
    },
    monthlyAttributes: {
      CUT_BACK_MONTHS: [MONTH.SEPTEMBER],
      BLOOMING_MONTHS: [MONTH.JUNE, MONTH.JULY, MONTH.AUGUST],
      GROWING_MONTHS: [MONTH.APRIL, MONTH.MAY, MONTH.JUNE, MONTH.JULY, MONTH.AUGUST],
      LOSING_LEAVES_MONTHS: [MONTH.SEPTEMBER],
      HARVEST_MONTHS: [MONTH.JULY, MONTH.AUGUST],
    },
  },
  {
    id: 6,
    name: 'Tomato',
    image: 'https://upload.wikimedia.org/wikipedia/commons/8/89/Tomato_je.jpg',
    info: 'Perfect for fresh produce. Needs consistent watering and warm conditions.',
    shape: PLANT_SHAPE.FLOWER,
    categories: [PLANT_CATEGORY.VEGETABLE],
    optimalAttributes: {
      SUN: [SUN_ATTRIBUTE.FULL_SUN],
      WATER_NEED: [WATER_NEED_ATTRIBUTE.MUCH],
      PLANT_DISTANCE: [PLANT_DISTANCE_ATTRIBUTE.MEDIUM],
      SOIL: [SOIL_ATTRIBUTE.NUTRIENT_RICH],
      MINDESTTEMPERATUR: [MINDESTTEMPERATUR_ATTRIBUTE.TEN],
      MAXTEMPERATUR: [MAXTEMPERATUR_ATTRIBUTE.THIRTYFIVE],
      WIND_SUSCEPTIBILITY: [WIND_SUSCEPTIBILITY_ATTRIBUTE.MEDIUM],
      POT_SIZE: [POT_SIZE_ATTRIBUTE.MEDIUM],
      BLOOMING_COLOR: [COLOR.YELLOW],
      LEAF_COLOR: [COLOR.GREEN],
      LOSING_LEAVES_COLOR: [COLOR.GREEN],
      GROWTH_SPEED: [GROWTH_SPEED_ATTRIBUTE.FAST],
      PLANT_MAX_SIZE_ATTRIBUTE: [PLANT_MAX_SIZE_ATTRIBUTE.MEDIUM],
      YEARS_UNTIL_MAX_SIZE_ATTRIBUTE: [YEARS_UNTIL_MAX_SIZE_ATTRIBUTE.ONE],
    },
    monthlyAttributes: {
      CUT_BACK_MONTHS: [MONTH.JULY, MONTH.AUGUST],
      BLOOMING_MONTHS: [MONTH.JUNE, MONTH.JULY, MONTH.AUGUST],
      GROWING_MONTHS: [MONTH.MAY, MONTH.JUNE, MONTH.JULY, MONTH.AUGUST, MONTH.SEPTEMBER],
      LOSING_LEAVES_MONTHS: [MONTH.SEPTEMBER],
      HARVEST_MONTHS: [MONTH.JULY, MONTH.AUGUST, MONTH.SEPTEMBER],
    },
  },
];
