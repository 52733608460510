import React, { useContext, useRef } from 'react';
import { useShallow } from 'zustand/react/shallow';
import * as THREE from 'three';
import { useThree } from '@react-three/fiber';
import dayjs from 'dayjs';
import {
  Bloom,
  DepthOfField, DotScreen, EffectComposer, Glitch, Noise, Outline, Select, Selection,
} from '@react-three/postprocessing';
import useScenarioStore from '../../stores/scenarioStore';
import useEntitiesStore, {
  ENTITY_PLACE_TYPE as PLACE_TYPE,
  ENTITY_TYPE,
} from '../../stores/entitiesStore';
import Plant from '../../components/3d/BasicPlant';
import Table from '../../components/3d/Table';
import useBalconyStore from '../../stores/balconyStore';
import PlantPotSingle from '../../components/3d/PlantPotSingle';
import usePlantsStore, { PLANT_TYPE } from '../../stores/plantsStore';
import { ThreeContext } from '../../context/ThreeContext';
import BambooPlant from '../../components/3d/BambooPlant';
import BasicPlant from '../../components/3d/BasicPlant';
import { degreesToRadians } from '../../utils/geoCalculationUtils';
import { PLANT_SPECIFICATION } from '../../config/PlantSpecification';
import { MONTH, PLANT_SHAPE } from '../../utils/plantAttributeUtils';
import useViewStore from '../../stores/viewStore';
import { getPlantPositions } from '../../utils/entityUtils';

export default function BalconyPlants() {
  const [entities] = useEntitiesStore(useShallow((state) => [state.entities]));
  const [plants, growingSimulationEnabled] = usePlantsStore(useShallow((state) => [state.plants, state.growingSimulationEnabled]));
  const { selectedDateTime } = useScenarioStore();
  const [focusOnId] = useViewStore(
    useShallow((state) => [
      state.focusOnId]),
  );

  const calculateDynamicFactors = (plant) => {
    const daysSincePlanting = dayjs(selectedDateTime).diff(new Date(), 'day');
    if (!growingSimulationEnabled) {
      return { age: plant.age };
    }
    const specification = PLANT_SPECIFICATION.find((spec) => spec.id === plant.specificationId);

    const simulatedMonth = dayjs(selectedDateTime).month() + 1;
    const simulatedYearDifferenceToToday = dayjs(selectedDateTime).year() - dayjs().year();

    const isLosingLeavesMonth = specification.monthlyAttributes.LOSING_LEAVES_MONTHS.includes(MONTH.ALWAYS) || specification.monthlyAttributes.LOSING_LEAVES_MONTHS.map((m) => m.month).includes(simulatedMonth);
    let leafColors = [];
    if (isLosingLeavesMonth) {
      leafColors = specification.optimalAttributes.LOSING_LEAVES_COLOR.map((c) => ({
        range: 1,
        color: c.color,
      }));
    } else {
      leafColors = specification.optimalAttributes.LEAF_COLOR.map((c) => ({
        range: 1,
        color: c.color,
      }));
    }

    // Per month where it grows + 0.4
    const passedGrowingMonths = specification.monthlyAttributes.GROWING_MONTHS.filter((m) => m.month <= simulatedMonth).length;
    let leafCount = 0;
    const latestLosingLeavesMonth = specification.monthlyAttributes.LOSING_LEAVES_MONTHS.reduce((acc, m) => (m.month > acc ? m.month : acc), 0) || 10;
    if (specification.monthlyAttributes.GROWING_MONTHS.includes(MONTH.ALWAYS)) {
      leafCount = 1;
    } else if (latestLosingLeavesMonth <= simulatedMonth) {
      leafCount = Math.max(0, 0.75 - ((simulatedMonth - latestLosingLeavesMonth) * 0.5));
    } else {
      leafCount = passedGrowingMonths * 0.4;
    }

    const bloomStrength = specification.monthlyAttributes.BLOOMING_MONTHS.find((m) => m.month === simulatedMonth);
    let bloomColor = specification.optimalAttributes.BLOOMING_COLOR.map((bc) => bc.color);
    if (plant?.colorAttributes?.BLOOM && plant.colorAttributes.BLOOM !== '#000') {
      bloomColor = [plant.colorAttributes.BLOOM];
    }
    const basicGrowth = specification.optimalAttributes.PLANT_MAX_SIZE_ATTRIBUTE[0].size;
    const yearsUntilMaxSize = specification.optimalAttributes.YEARS_UNTIL_MAX_SIZE_ATTRIBUTE[0].years;
    const growPerYear = basicGrowth / yearsUntilMaxSize;
    const growThisYear = passedGrowingMonths / specification.monthlyAttributes.GROWING_MONTHS.length;

    const growth = Math.min(growPerYear * ((plant.age - 1) + simulatedYearDifferenceToToday + growThisYear), basicGrowth);

    return {
      bloomStrength: bloomStrength ? 1 : 0,
      bloomColor,
      leafColors,
      leafCount,
      growth,
    };
  };

  const renderPlant = (plant) => {
    let EntityComponent = null;
    const plantShape = PLANT_SPECIFICATION.find((spec) => spec.id === plant.specificationId)?.shape || PLANT_SHAPE.FLOWER;
    if (plantShape === PLANT_SHAPE.FLOWER) {
      EntityComponent = <BasicPlant {...plant} {...calculateDynamicFactors(plant)} />;
    } else if (plantShape === PLANT_SHAPE.BAMBOO) {
      EntityComponent = <BambooPlant {...plant} {...calculateDynamicFactors(plant)} />;
    } else {
      console.error(`Unknown plant type: ${plant.type}`);
    }

    const getPlantPosition = () => {
      const entity = entities.find((e) => e.id === plant.attachedEntityId);
      const plantPositions = getPlantPositions(entity);
      const plantPosition = plantPositions.find((position) => position.index === plant.attachedIndexPosition);
      const plantPosVector = new THREE.Vector3(plantPosition.x, plantPosition.y, plantPosition.z);

      if (entity.rotationY) {
        const rotationMatrix = new THREE.Matrix4();
        rotationMatrix.makeRotationFromEuler(new THREE.Euler(0, degreesToRadians(entity.rotationY), 0));
        plantPosVector.applyMatrix4(rotationMatrix);
      }

      plantPosVector.add(new THREE.Vector3(entity.balconyPosition.x, entity.balconyPosition.y, entity.balconyPosition.z));

      return [plantPosVector.x, plantPosVector.y, plantPosVector.z];
    };
    return (
      <Select key={plant.id} enabled={focusOnId === `entity_${plant.id}`}>
        <group
          name={`entity_${plant.id}`}
          position={getPlantPosition()}
        // rotation={[0, plant.rotationY * (Math.PI / 180), 0]}
          userData={{ placedOnTop: true, category: 'plant', attachedEntityId: plant.attachedEntityId }}
        >
          {EntityComponent}
        </group>
      </Select>
    );
  };

  return (
    <>
      {plants.filter((plant) => plant.attachedEntityId).map((plant) => renderPlant(plant))}
    </>
  );
}
