import React, { useMemo, useState } from 'react';
import { SketchPicker } from 'react-color';
import { Box, Grid, Typography } from '@mui/material';
import KeyValueLabel from './KeyValueLabel';

export default function ReadyOnlyKeyValueWrapper({
  label, value, readOnly = true, children, asGridItem = false,
}) {
  if (value === undefined) {
    return null;
  }
  const Container = useMemo(() => (asGridItem ? ({ children: children2 }) => (<Grid item xs={readOnly ? 6 : 12}>{children2}</Grid>) : ({ children: children2 }) => children2), [asGridItem, readOnly]);

  if (readOnly) {
    return (
      <Container>
        <Box>
          <KeyValueLabel label={label} value={value} />
        </Box>
      </Container>
    );
  }
  return (
    <Container>
      <Box>
        {children}
      </Box>
    </Container>
  );
}
