import React from 'react';
import {
  Box, Button, ButtonGroup, Grid,
} from '@mui/material';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PanoramaHorizontalIcon from '@mui/icons-material/PanoramaHorizontal';
import ThirteenMpIcon from '@mui/icons-material/ThirteenMp';
import { Vector3 } from 'three';
import { useShallow } from 'zustand/react/shallow';
import BalconyPositionSlider from '../BalconyPositionSlider';
import BalconyRotationSlider from '../BalconyRotationSlider';
import useViewStore, { CAMERA_MODE } from '../../stores/viewStore';

export default function CameraModeBar({ size = 'small' }) {
  const [cameraMode, setCameraMode] = useViewStore(useShallow((state) => [state.cameraMode, state.setCameraMode]));

  const handleTop = () => {
    setCameraMode(CAMERA_MODE.TOP_VIEW);
  };

  const handleFirstPerson = () => {
    setCameraMode(CAMERA_MODE.TOP_VIEW);
    setTimeout(() => { // Todo: Hack to retrigger camera mode change
      setCameraMode(CAMERA_MODE.FIRST_PERSON);
    }, 3);
  };

  const handleThirdPerson = () => {
    setCameraMode(CAMERA_MODE.TOP_VIEW);
    setTimeout(() => { // Todo: Hack to retrigger camera mode change
      setCameraMode(CAMERA_MODE.THIRD_PERSON);
    }, 3);
  };

  return (
    <Box justifyContent="center" display="flex">
      <ButtonGroup variant="text" aria-label="camera view buttons" size={size}>
        <Button
          onClick={handleThirdPerson}
          startIcon={<ThirteenMpIcon />}
          variant={cameraMode === CAMERA_MODE.THIRD_PERSON ? 'contained' : 'text'}
          size={size}
          sx={{ backgroundColor: cameraMode === CAMERA_MODE.THIRD_PERSON ? undefined : 'rgba(255, 255, 255, 0.5)' }}
        >
          Third
        </Button>
        <Button
          onClick={handleTop}
          startIcon={<VerticalAlignTopIcon />}
          variant={cameraMode === CAMERA_MODE.TOP_VIEW ? 'contained' : 'text'}
          size={size}
          sx={{ backgroundColor: cameraMode === CAMERA_MODE.TOP_VIEW ? 'undefined' : 'rgba(255, 255, 255, 0.5)' }}
        >
          Top
        </Button>
        <Button
          onClick={handleFirstPerson}
          startIcon={<VisibilityIcon />}
          variant={cameraMode === CAMERA_MODE.FIRST_PERSON ? 'contained' : 'text'}
          size={size}
          sx={{ backgroundColor: cameraMode === CAMERA_MODE.FIRST_PERSON ? 'undefined' : 'rgba(255, 255, 255, 0.5)' }}
        >
          First
        </Button>
        {/* <Button onClick={handleFree} startIcon={<PanoramaHorizontalIcon />} variant={cameraMode === CAMERA_MODE.FREE ? 'contained' : 'text'}>Free</Button> */}
      </ButtonGroup>
    </Box>
  );
}
