import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useFrame, useLoader, useThree } from '@react-three/fiber';
import { TextureLoader } from 'three';
import * as THREE from 'three';
import { useShallow } from 'zustand/react/shallow';
import { BALCONY_WALL_TYPE } from '../../stores/balconyStore';
import useUiStore from '../../stores/uiStore';

export default function Wall({
  position, width, height, thick, color = '#c1c1c1', rotateY = 0, type = BALCONY_WALL_TYPE.SOLID.name, name = 'Wall',
  topBar = false, placedOnTop = false, opacity = 1, meshRef = null,
}) {
  const createWall = useCallback(() => {
    const getMaterial = () => (<meshStandardMaterial color={color} transparent opacity={opacity} />);

    if (!type || type === BALCONY_WALL_TYPE.SOLID.name) {
      const textureMap = useLoader(TextureLoader, 'https://images.unsplash.com/photo-1549396563-73701bbb8f20?q=80&w=2500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
      return (
        <mesh castShadow receiveShadow name={name}>
          <boxGeometry args={[width, height, thick]} />
          {getMaterial()}
          {/* <meshStandardMaterial map={textureMap} /> */}
        </mesh>
      );
    }

    let barWidth;
    let holeWidth;
    let round = false;
    if (type === BALCONY_WALL_TYPE.LARGE_BARS.name) {
      holeWidth = 0.07;
      barWidth = 0.1;
    } else if (type === BALCONY_WALL_TYPE.SMALL_BARS.name) {
      holeWidth = 0.05;
      barWidth = 0.05;
      round = true;
    }

    const totalBars = Math.floor((width - barWidth) / (barWidth + holeWidth)) + 1;
    const bars = [];

    for (let i = 0; i < totalBars; i++) {
      const barPosition = [
        -width / 2 + barWidth / 2 + i * (barWidth + holeWidth),
        0,
        0,
      ];
      bars.push(
        <mesh key={i} castShadow receiveShadow position={barPosition} name={`WallBar${i}`} rotation={[0, rotateY, 0]}>
          {round ? <cylinderGeometry args={[barWidth / 2, barWidth / 2, height, 16]} />
            : <boxGeometry args={[thick, height, barWidth]} />}
          {getMaterial()}
        </mesh>,
      );
    }
    return (<>{bars}</>);
  }, [width, height, thick, color, rotateY, type, opacity]);

  return (
    <group
      castShadow
      receiveShadow
      position={position}
      name="Wall"
      rotation={[0, rotateY, 0]}
      userData={{ placedOnTop }}
      ref={meshRef}
    >
      {createWall()}
      {topBar && type !== BALCONY_WALL_TYPE.SOLID.name && (
      <mesh
        position={[0, height / 2 - 0.05, 0]}
        name="TopOverlayBar"
        castShadow
        receiveShadow
      >
        <boxGeometry args={[width, 0.1, thick + 0.1]} />
        <meshStandardMaterial color={color} />
      </mesh>
      )}
    </group>
  );
}
