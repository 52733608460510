import React, {
  useCallback,
  useEffect, useMemo, useRef, useState,
} from 'react';
import * as THREE from 'three';
import { TransformControls } from '@react-three/drei';
import { useShallow } from 'zustand/react/shallow';
import { Vector3 } from 'three';
import { Quaternion } from 'three/src/math/Quaternion';
import useUiStore from '../../stores/uiStore';
import useViewStore from '../../stores/viewStore';
import useBalconyStore from '../../stores/balconyStore';
import useEntitiesStore, { ENTITY_PLACE_TYPE } from '../../stores/entitiesStore';
import usePositionAtBalcony from '../../hooks/usePositionAtBalcony';
import useIsMobile from '../../hooks/useIsMobile';

function EntityTransformControl({
  scene,
}) {
  const [setTransformControlActive] = useUiStore(useShallow((state) => [state.setTransformControlActive]));
  const [focusOnId] = useViewStore(
    useShallow((state) => [
      state.focusOnId]),
  );
  const [
    sizeX, sizeZ,
  ] = useBalconyStore(useShallow((state) => [state.sizeX, state.sizeZ]));

  const [
    entities, updateMultipleEntityFieldsWithChildren,
  ] = useEntitiesStore(useShallow((state) => [state.entities, state.updateMultipleEntityFieldsWithChildren]));

  const transformRef = useRef();
  const [focusEntity, setFocusEntity] = useState(null);
  const [focusEntityStoreData, setFocusEntityStoreData] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  const isMobile = useIsMobile();

  useEffect(() => {
    if (focusOnId) {
      const entity = scene.getObjectByName(focusOnId);
      if (entity) {
        setFocusEntity(entity);
        setFocusEntityStoreData(entities.find((e) => `${e.id}` === focusOnId.replace('entity_', '')));
      }
    } else {
      setFocusEntity(null);
      setFocusEntityStoreData(null);
    }
  }, [focusOnId, entities]);

  useEffect(() => {
    const controls = transformRef.current;

    if (!controls) return;
    controls.traverse((child) => {
      child.userData.ignoreForShade = true;
    });

    const onDraggingChanged = (event) => {
      const isDragging = event.value;
      setIsDragging(isDragging);
      setTransformControlActive(isDragging);

      if (!isDragging) { // After dragging has ended
        const { object } = controls;

        const { MIN, MAX } = getBounds();
        const relativePositionX = 100 * ((object.position.x - MAX[0]) / (MIN[0] - MAX[0]));
        const relativePositionZ = 100 * ((object.position.z - MAX[2]) / (MIN[2] - MAX[2]));

        updateMultipleEntityFieldsWithChildren(focusEntityStoreData.id, ['positionX', 'positionZ'], [relativePositionX, relativePositionZ]);
      }
    };
    controls.addEventListener('dragging-changed', onDraggingChanged);
    return () => {
      controls.removeEventListener('dragging-changed', onDraggingChanged);
    };
  }, [focusEntity, focusEntityStoreData]);

  const positionAtBalcony = usePositionAtBalcony();
  const getBounds = useCallback(() => {
    const MIN = positionAtBalcony.getEntityPosition(100, 100, focusEntityStoreData.placeType, focusEntityStoreData.width, focusEntityStoreData.length);
    const MAX = positionAtBalcony.getEntityPosition(0, 0, focusEntityStoreData.placeType, focusEntityStoreData.width, focusEntityStoreData.length);

    return { MIN, MAX };
  }, [focusEntityStoreData, sizeX, sizeZ]);

  useEffect(() => {
    const controls = transformRef.current;
    if (!controls) return;

    // Listen for transform changes
    const onChange = () => {
      const { object } = controls; // The object being transformed
      if (!object || !isDragging) return;
      const { MIN, MAX } = getBounds();
      object.position.x = Math.min(MIN[0], Math.max(MAX[0], object.position.x));
      object.position.z = Math.min(MAX[2], Math.max(MIN[2], object.position.z));
    };

    controls.addEventListener('change', onChange);
    return () => controls.removeEventListener('change', onChange);
  }, [focusEntity, isDragging]);

  return (
    <>
      {focusEntity && focusEntityStoreData && focusEntityStoreData.placeType === ENTITY_PLACE_TYPE.FLOOR.name && (
      <TransformControls
        ref={transformRef}
        mode="translate"
        enabled
        // position={gizmosPosition}
        object={focusEntity}
        space="local"
        userData={{ ignoreForShade: true }}
        showY={false}
        size={isMobile ? 2.1 : 1.3}
      />
      )}
    </>
  );
}

export default EntityTransformControl;
