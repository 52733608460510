import React from 'react';
import {
  Grid, Button, Checkbox, FormControlLabel, FormControl, InputLabel, MenuItem, Select, Typography,
} from '@mui/material';
import {
  BorderOuter, BorderLeft, BorderRight, BorderTop, BorderBottom, VerticalAlignTop,
} from '@mui/icons-material';
import useBalconyStore, { BALCONY_WALL_TYPE } from '../../stores/balconyStore';
import SliderWithButtons from '../../components/SliderWithButtons';
import EditableCardSection from '../EditableCardSection';
import KeyValueLabel from '../../components/KeyValueLabel';

export default function BalconyDetailsContent() {
  const {
    setSizeX, setSizeZ, sizeX, sizeZ, setSideWallLeft, setSideWallRight,
    setSideWallFront, sideWallLeft, sideWallRight, sideWallFront,
    sideWallTop, setSideWallTop, sideWallBack, setSideWallBack,
  } = useBalconyStore();

  const walls = [
    {
      label: 'Left', wall: sideWallLeft, setWall: setSideWallLeft, icon: BorderLeft,
    },
    {
      label: 'Right', wall: sideWallRight, setWall: setSideWallRight, icon: BorderRight,
    },
    {
      label: 'Front', wall: sideWallFront, setWall: setSideWallFront, icon: BorderTop,
    },
    {
      label: 'Back', wall: sideWallBack, setWall: setSideWallBack, icon: BorderBottom,
    },
  ];

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <EditableCardSection
          label="Balcony size"
          LabelIcon={BorderOuter}
          storeKey="BALCONY_SIZE"
          renderChildrenFunction={(editable) => (
            <>
              <Grid item xs={editable ? 12 : 6}>
                <SliderWithButtons
                  value={sizeX}
                  setValue={(value) => setSizeX(value)}
                  min={0.2}
                  max={15}
                  label="Width"
                  step={0.01}
                  readOnly={!editable}
                  unitLabel="m"
                />
              </Grid>
              <Grid item xs={editable ? 12 : 6}>
                <SliderWithButtons
                  value={sizeZ}
                  setValue={(value) => setSizeZ(value)}
                  min={0.2}
                  max={15}
                  label="Length"
                  step={0.01}
                  readOnly={!editable}
                  unitLabel="m"
                />
              </Grid>
            </>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <EditableCardSection
          label="Walls"
          LabelIcon={BorderLeft}
          renderChildrenFunction={(editable) => (
            <>
              {editable ? (
                <Grid container spacing={6}>
                  <Grid item xs={12} mt={1}>
                    {walls.map(({ label, wall, setWall }) => (
                      <EditableCardSection
                        key={label}
                        label={`${label} wall`}
                        secondHierarchy
                        renderChildrenFunction={(wallEditable) => (
                          <Grid container spacing={2}>
                            <Grid item xs={5}>
                              <FormControlLabel
                                control={(
                                  <Checkbox
                                    checked={wall.enabled || false}
                                    onChange={(event) => setWall({ ...wall, enabled: event.target.checked })}
                                  />
                                      )}
                                label="Enabled"
                              />
                            </Grid>
                            <Grid item xs={7}>
                              <FormControlLabel
                                control={(
                                  <Checkbox
                                    checked={wall.toCeil || false}
                                    onChange={(event) => setWall({ ...wall, toCeil: event.target.checked })}
                                    disabled={!wall.enabled}
                                  />
                                      )}
                                label="Ceil height"
                              />
                            </Grid>
                            <SliderWithButtons
                              value={wall.height}
                              setValue={(value) => setWall({ ...wall, height: value })}
                              min={0.1}
                              max={4}
                              label="Height"
                              step={0.01}
                              disabled={!wall.enabled || wall.toCeil}
                              unitLabel="m"
                              readOnly={!wallEditable}
                              asGridItem
                            />
                            <Grid item xs={6}>
                              {wallEditable ? (
                                <FormControl fullWidth>
                                  <InputLabel id={`${label}-type-label`}>Type</InputLabel>
                                  <Select
                                    labelId={`${label}-type-label`}
                                    value={wall.type}
                                    onChange={(value) => {
                                      setWall({ ...wall, type: value.target.value });
                                    }}
                                    disabled={!wall.enabled}
                                    size="small"
                                    sx={{ maxWidth: '127px' }}
                                  >
                                    {Object.keys(BALCONY_WALL_TYPE).map((key) => (
                                      <MenuItem key={key} value={BALCONY_WALL_TYPE[key].name}>
                                        {BALCONY_WALL_TYPE[key].label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              ) : (
                                <KeyValueLabel label="Type" value={BALCONY_WALL_TYPE[wall.type].label} />
                              )}
                            </Grid>
                          </Grid>
                        )}
                      />
                    ))}
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={2}>
                  {walls.map(({ label, wall }) => (
                    <Grid item xs={6} md={3} key={label}>
                      <KeyValueLabel label={`${label}`} value={wall.enabled ? `${wall.height} m` : 'Disabled'} />
                    </Grid>
                  ))}
                </Grid>
              )}
            </>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <EditableCardSection
          label="Ceil"
          LabelIcon={VerticalAlignTop}
          storeKey="CEIL"
          renderChildrenFunction={(editable) => (
            <Grid container rowSpacing={2}>
              {editable ? (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={sideWallTop.enabled || false}
                        onChange={(event) => setSideWallTop({ ...sideWallTop, enabled: event.target.checked })}
                      />
                              )}
                    label="Enabled"
                  />
                </Grid>
              ) : !sideWallTop.enabled && (
                <Grid item xs={6}>
                  <KeyValueLabel label="Enabled" value={sideWallTop.enabled ? 'Yes' : 'No'} />
                </Grid>
              )}
              {(sideWallTop.enabled || editable) && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      Ceil is sometimes not displayed in 3D view
                    </Typography>
                  </Grid>
                  {editable ? (
                    <Grid item xs={12}>
                      <SliderWithButtons
                        value={sideWallTop.offsetY}
                        setValue={(value) => setSideWallTop({ ...sideWallTop, offsetY: value })}
                        min={0.5}
                        max={4}
                        label="Offset height"
                        step={0.01}
                        disabled={!sideWallTop.enabled}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={6}>
                      <KeyValueLabel label="Offset height" value={`${sideWallTop.offsetY} m`} />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Grid container rowSpacing={1}>
                      {editable ? (
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={(
                              <Checkbox
                                disabled={!sideWallTop.enabled}
                                checked={sideWallTop.sameSizeAsFloor || false}
                                onChange={(event) => setSideWallTop({ ...sideWallTop, sameSizeAsFloor: event.target.checked })}
                              />
                                        )}
                            label="Same size as floor"
                          />
                        </Grid>
                      ) : (sideWallTop.sameSizeAsFloor && (
                      <Grid item xs={6}>
                        <KeyValueLabel label="Same size as floor" value="Yes" />
                      </Grid>
                      ))}
                      {(editable || !sideWallTop.sameSizeAsFloor) && (
                        <>
                          <Grid item xs={editable ? 12 : 6}>
                            <SliderWithButtons
                              value={sideWallTop.sizeX}
                              setValue={(value) => setSideWallTop({ ...sideWallTop, sizeX: value })}
                              min={0.1}
                              max={20}
                              label="Width"
                              step={0.01}
                              disabled={!sideWallTop.enabled || sideWallTop.sameSizeAsFloor}
                              readOnly={!editable}
                            />
                          </Grid>
                          <Grid item xs={editable ? 12 : 6}>
                            <SliderWithButtons
                              value={sideWallTop.sizeZ}
                              setValue={(value) => setSideWallTop({ ...sideWallTop, sizeZ: value })}
                              min={0.1}
                              max={20}
                              label="Length"
                              step={0.01}
                              disabled={!sideWallTop.enabled || sideWallTop.sameSizeAsFloor}
                              readOnly={!editable}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                  {editable ? (
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Type</InputLabel>
                        <Select
                          value={sideWallTop.type}
                          onChange={(value) => {
                            setSideWallTop({ ...sideWallTop, type: value.target.value });
                          }}
                          disabled={!sideWallTop.enabled}
                          labelId="demo-simple-select-label"
                        >
                          {Object.keys(BALCONY_WALL_TYPE).map((key) => (
                            <MenuItem key={key} value={BALCONY_WALL_TYPE[key].name}>
                              {BALCONY_WALL_TYPE[key].label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid item xs={6}>
                      <KeyValueLabel label="Type" value={BALCONY_WALL_TYPE[sideWallTop.type].label} />
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          )}
        />
      </Grid>
    </Grid>
  );
}
