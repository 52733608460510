import React, { useContext, useEffect, useState } from 'react';
import * as THREE from 'three';
import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import { useShallow } from 'zustand/react/shallow';
import { Fog } from 'three';
import { useThree } from '@react-three/fiber';
import useScenarioStore from '../../stores/scenarioStore';
import { getSunPosition } from '../../utils/geoCalculationUtils';
import { ThreeContext } from '../../context/ThreeContext';
import useViewStore, { CAMERA_MODE } from '../../stores/viewStore';

dayjs.extend(dayOfYear);

const SUN_RADIUS = 100_0;

export default function Sunlights({ color = 'white', latitude, longitude }) {
  const [setSunPosition, sunPosition, selectedDateTime, sunIntensity, setSunIntensity] = useScenarioStore(useShallow((state) => [state.setSunPosition, state.sunPosition, state.selectedDateTime, state.sunIntensity, state.setSunIntensity]));
  const { camera, scene } = useThree();
  const [cameraMode] = useViewStore(useShallow((state) => [state.cameraMode]));

  const topView = cameraMode === CAMERA_MODE.TOP_VIEW;

  useEffect(() => {
    if (!selectedDateTime) {
      return;
    }

    const date = Date.parse(selectedDateTime);
    const position = getSunPosition(date, latitude, longitude, SUN_RADIUS);

    if (position[1] < 0) {
      setSunIntensity(0);
    } else {
      setSunIntensity((position[1] / (SUN_RADIUS * 0.9)));
    }

    setSunPosition(position);
  }, [latitude, longitude, selectedDateTime]);

  useEffect(() => {
    const color1 = new THREE.Color('#17070f');
    const color2 = new THREE.Color('#dcf1fd');
    const mixedColor = color1.lerp(color2, sunIntensity);
    scene.fog = new Fog(mixedColor, camera.near, camera.far);
  }, [sunPosition, sunIntensity, color]);

  const bb = 54;
  return topView ? (
    <>
      <directionalLight
        position={new THREE.Vector3(0, 90, 0)}
        intensity={3}
        castShadow
        shadow-camera-left={-bb}
        shadow-camera-right={bb}
        shadow-camera-bottom={-bb}
        shadow-camera-top={bb}
        shadow-camera-near={1}
        shadow-camera-far={SUN_RADIUS * 1.1}
            // shadow-bias={-0.00001}
        shadow-mapSize-width={1024 * 8}
        shadow-mapSize-height={1024 * 8}
      />
      <ambientLight intensity={1.3} />
    </>
  ) : (
    <>
      <directionalLight
        position={sunPosition}
        intensity={sunIntensity * Math.PI * 2}
        castShadow
        shadow-camera-left={-bb}
        shadow-camera-right={bb}
        shadow-camera-bottom={-bb}
        shadow-camera-top={bb}
        shadow-camera-near={1}
        shadow-camera-far={SUN_RADIUS * 1.1}
            // shadow-bias={-0.00001}
        shadow-mapSize-width={1024 * 8}
        shadow-mapSize-height={1024 * 8}
      />
      <ambientLight intensity={0.18 + 0.05 * Math.PI / 2 * sunIntensity * Math.PI * 3} />
    </>
  );
}
