import React, { useState } from 'react';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useShallow } from 'zustand/react/shallow';
import AddNewEntitySelection from './AddNewEntitySelection';
import useEntitiesStore, { ENTITY_CATEGORY, ENTITY_TYPE } from '../../../stores/entitiesStore';
import AddNewPlantSection from './AddNewPlantSection';
import usePlantsStore, { PLANT_TYPE } from '../../../stores/plantsStore';
import AddNewPlantSelection from './AddNewPlantSelection';
import { PLANT_SPECIFICATION } from '../../../config/PlantSpecification';

export default function AddPlantButtonWithDialog({
  buttonLabel = 'New plant', addPlantCallback = () => {}, addModifiers = {}, allowAddingAsChild = false,
}) {
  const [addPlant, plants] = usePlantsStore(useShallow((state) => [state.addPlant, state.plants]));
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const handleAddPlant = (specificationId) => {
    const id = Math.round(Math.random() * 100000);
    const specification = PLANT_SPECIFICATION.find((spec) => spec.id === specificationId);
    const uniqueName = `${specification.name} ${plants.filter((p) => p.specificationId === specificationId).length + 1}`;

    addPlant({
      type: PLANT_TYPE.FLOWER.id, id, name: uniqueName, specificationId: specification.id, newlyCreated: true, ...addModifiers, // TODO Plant types komplett entfernen?!
    });

    addPlantCallback(id);
    setAddDialogOpen(false);
  };

  return (
    <>
      <Button onClick={() => setAddDialogOpen(true)} variant="contained" startIcon={<Add />}>
        {buttonLabel}
      </Button>
      <AddNewPlantSelection
        handleAddPlant={handleAddPlant}
        open={addDialogOpen}
        handleClose={() => setAddDialogOpen(false)}
        allowAddingAsChild={allowAddingAsChild}
        title="Add plant"
      />
    </>
  );
}
