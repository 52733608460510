import React, { useState } from 'react';
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Box,
  ListItemText,
  Avatar,
  ListItemAvatar,
  Dialog,
  ListItemButton,
  ListItem,
  DialogTitle,
  List,
  IconButton,
  useTheme, useMediaQuery, Typography, DialogContent, Collapse,
} from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
import { blue, green } from '@mui/material/colors';
import {
  Add, Close, ExpandLess, ExpandMore,
} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import useEntitiesStore, { ENTITY_TYPE } from '../../../stores/entitiesStore';
import { OVERLAY_CONFIG } from '../../../stores/uiStore';
import PlantDetailsPage from '../../overlay/PlantDetails';
import useIsMobile from '../../../hooks/useIsMobile';

export default function AddNewPlantSelection({
  open,
  handleClose,
  allowAddingAsChild = false,
  title,
  handleAddPlant,
}) {
  const theme = useTheme();
  const isMobile = useIsMobile();

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullScreen={isMobile}
      maxWidth="md"
      PaperProps={{
        elevation: 0,
        sx: { boxShadow: 'none' },
      }}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: 'white',
            boxShadow: 'none',
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          marginX: 0,
          marginY: 2,
          paddingX: 1,
          paddingY: 1,
          backgroundColor: '#f1f8e9',
          borderRadius: '10px',
        }}
      >
        <Box width="100%">
          <Grid container alignItems="center">
            <Grid item xs={2}>
              <IconButton edge="end" color="inherit" onClick={handleClose} mr={1}>
                <Close />
              </IconButton>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h5">{title}</Typography>
            </Grid>
          </Grid>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: '#f1f8e9' }}>
        <Box p={2}>
          <PlantDetailsPage plantId={null} onConfirmSelection={handleAddPlant} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
