import { useThree } from '@react-three/fiber';
import { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { useMediaQuery, useTheme } from '@mui/material';
import useUiStore, { SELECTION_MODE, TABS_CONFIG } from '../stores/uiStore';
import useScenarioStore from '../stores/scenarioStore';
import useViewStore, { CAMERA_MODE } from '../stores/viewStore';
import useIsMobile from './useIsMobile';

export default function useSelectionModeClick() {
  const { gl, camera, scene } = useThree();
  const { setFocusOnId, focusOnId, cameraMode } = useViewStore();
  const { selectionMode, selectedTab, setClickedOnCanvasEntity } = useUiStore();
  const { setTemporarySelectedBuilding } = useScenarioStore();

  const isMobile = useIsMobile();
  const lastClickTime = useRef(0);
  const mousedownTime = useRef(0);
  const mouseupTime = useRef(0);
  const raycastFrameRef = useRef(null);

  function getIntersects(event) {
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();
    const div = document.getElementById('threejs-div');
    const rect = div.getBoundingClientRect();
    mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    mouse.y = -(((event.clientY - (isMobile ? 0 : 0)) - rect.top) / (rect.height * 1)) * 2 + 1;
    raycaster.setFromCamera(mouse, camera);
    return raycaster.intersectObjects(scene.children, true);
  }

  function findObjectNameSuffixInParent(object, suffix) {
    if (object.name?.includes(suffix)) {
      return object;
    }
    if (object.parent) {
      return findObjectNameSuffixInParent(object.parent, suffix);
    }
    return null;
  }

  function isValidHoverObject(object) {
    if (object?.userData?.category === 'building') return true;
    const foundEntity = findObjectNameSuffixInParent(object, 'entity_');
    if (foundEntity) return true;
    return false;
  }

  function handleBuildingSelection(intersects) {
    if (intersects[0]?.object?.userData?.category === 'building') {
      setTemporarySelectedBuilding(intersects[0].object.userData);
    }
  }

  function handleClick(event) {
    const clickDuration = mouseupTime.current - mousedownTime.current;
    const timeSinceLastClick = Date.now() - lastClickTime.current;
    if (clickDuration > 200 || timeSinceLastClick < 300) {
      return;
    }
    lastClickTime.current = Date.now();
    const intersects = getIntersects(event);
    if (intersects.length === 0) {
      return;
    }
    if (selectionMode === SELECTION_MODE.BUILDING) {
      handleBuildingSelection(intersects);
    } else if (!selectionMode) {
      const meshIntersects = intersects.filter((i) => i.object.type === 'Mesh');
      if (meshIntersects.length > 0) {
        const clickedEntity = findObjectNameSuffixInParent(meshIntersects[0].object, 'entity_');
        let entityName = clickedEntity?.name || null;
        if (selectedTab === TABS_CONFIG.ENTITIES.id && clickedEntity?.userData?.attachedEntityId) {
          entityName = `entity_${clickedEntity.userData.attachedEntityId}`;
        }
        setFocusOnId(null);
        setClickedOnCanvasEntity({ name: entityName, time: Date.now() });
      }
    }
  }

  function handleMouseDown() {
    mousedownTime.current = Date.now();
  }

  function handleMouseUp() {
    mouseupTime.current = Date.now();
  }

  function handlePointerMove(event) {
    if (mousedownTime.current && mouseupTime.current && mousedownTime.current > mouseupTime.current) {
      return;
    }
    if (raycastFrameRef.current) return;
    raycastFrameRef.current = requestAnimationFrame(() => {
      raycastFrameRef.current = null;
      const intersects = getIntersects(event);
      if (intersects.length > 0 && isValidHoverObject(intersects[0].object)) {
        gl.domElement.style.cursor = 'pointer';
      } else {
        gl.domElement.style.cursor = 'default';
      }
    });
  }

  useEffect(() => {
    gl.domElement.addEventListener('click', handleClick);
    gl.domElement.addEventListener('mousedown', handleMouseDown);
    gl.domElement.addEventListener('mouseup', handleMouseUp);

    if (!isMobile && cameraMode === CAMERA_MODE.TOP_VIEW && selectedTab === TABS_CONFIG.ENTITIES.id) {
      gl.domElement.addEventListener('pointermove', handlePointerMove);
    } else {
      gl.domElement.style.cursor = 'default';
    }

    return () => {
      gl.domElement.removeEventListener('click', handleClick);
      gl.domElement.removeEventListener('mousedown', handleMouseDown);
      gl.domElement.removeEventListener('mouseup', handleMouseUp);
      gl.domElement.removeEventListener('pointermove', handlePointerMove);
      if (raycastFrameRef.current) {
        cancelAnimationFrame(raycastFrameRef.current);
      }
    };
  }, [gl, camera, scene, isMobile, focusOnId, selectionMode, selectedTab, cameraMode]);

  return { handleClick };
}
