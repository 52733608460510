import React, { useState } from 'react';
import {
  Box, Button, Divider, Grid,
} from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
import { Add } from '@mui/icons-material';
import AddNewEntitySection from './fragments/AddNewEntitySection';
import EntitiesList from './fragments/EntitiesList';
import useEntitiesStore, { ENTITY_CATEGORY, ENTITY_TYPE } from '../../stores/entitiesStore';
import AddNewEntitySelection from './fragments/AddNewEntitySelection';
import AddEntityButtonWithDialog from './fragments/AddEntityButtonWithDialog';
import PlantPotsContent from './fragments/PlantPotsContent';

export default function EntitiesContent() {
  const [entities, addEntity] = useEntitiesStore(useShallow((state) => [state.entities, state.addEntity]));
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [lastAddedEntityId, setLastAddedEntityId] = useState(null);

  const validCategories = Object.keys(ENTITY_CATEGORY)
    .filter((category) => ENTITY_CATEGORY[category].id !== ENTITY_CATEGORY.PLANT_CONTAINER.id)
    .map((category) => ENTITY_CATEGORY[category]);

  const addEntityCallback = (id) => {
    setLastAddedEntityId(id);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PlantPotsContent />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="center" my={0}>
        <AddEntityButtonWithDialog buttonLabel="Add Furniture" addEntityCallback={addEntityCallback} />
      </Grid>
      <Grid item xs={12} mt={1}>
        <EntitiesList
          openEditableEntityId={lastAddedEntityId}
          entities={entities.filter((entity) => validCategories.includes(ENTITY_TYPE[entity.type].category))}
        />
      </Grid>
    </Grid>
  );
}
