import { useEffect, useState } from 'react';
import * as THREE from 'three';
import { useThree } from '@react-three/fiber';
import { useShallow } from 'zustand/react/shallow';
import { ENTITY_PLACE_TYPE as PLACE_TYPE } from '../stores/entitiesStore';
import useBalconyStore from '../stores/balconyStore';
import usePositionAtBalcony from './usePositionAtBalcony';
import { DrawLine } from '../utils/debugUtils';
import useScenarioStore from '../stores/scenarioStore';
import useUiStore from '../stores/uiStore';

const useEntityPosition = (positionX, positionZ, placeType, width, length, entityId = null, placeOnTop = false) => {
  const [
    balconyCalculatedPosition,
    balconyCalculatedRotationY,
    loadedBuildings,
  ] = useScenarioStore(useShallow((state) => [state.balconyCalculatedPosition,
    state.balconyCalculatedRotationY, state.loadedBuildings]));
  const [selectionMode] = useUiStore(useShallow((state) => [state.selectionMode]));

  const [position, setPosition] = useState([0, 0, 0]);
  const [sizeX, sizeZ, sideWallLeft, sideWallRight, sideWallFront, sideWallBack] = useBalconyStore((state) => [
    state.sizeX,
    state.sizeZ,
    state.sideWallLeft,
    state.sideWallRight,
    state.sideWallFront,
    state.sideWallBack,
  ]);

  const positionAtBalcony = usePositionAtBalcony();
  const { scene } = useThree();

  const isEntity = (obj) => {
    const { name, parent } = obj;
    return name === `entity_${entityId}` || parent?.name === `entity_${entityId}` || parent?.parent?.name === `entity_${entityId}`;
  };

  const hasPlacedOnTop = (obj) => {
    const { userData, parent } = obj;
    return userData?.placedOnTop === true || parent?.userData?.placedOnTop === true || parent?.parent?.userData?.placedOnTop === true || parent?.parent?.parent?.userData?.placedOnTop === true;
  };

  const getTopDownPosition = (x, z) => {
    const rotationY = balconyCalculatedRotationY;

    const relativePosition = new THREE.Vector3(x, 60, z);
    relativePosition.applyAxisAngle(new THREE.Vector3(0, 1, 0), rotationY);
    relativePosition.add(balconyCalculatedPosition);

    const raycaster = new THREE.Raycaster();
    raycaster.set(relativePosition, new THREE.Vector3(0, -1, 0));
    const intersects = raycaster.intersectObjects(scene.children, true)
      .filter((obj) => {
        const { userData, name, parent } = obj.object;
        return userData.tag !== 'building'
            && userData?.ignoreForShade !== true
            && !isEntity(obj.object, entityId)
            && !hasPlacedOnTop(obj.object);
      });

    if (intersects.length > 0) {
      if (intersects[0].object.name === 'groundfloor') {
        return -1;
      }
      return intersects[0].point.y - balconyCalculatedPosition.y;
    }
    return 0;
  };

  useEffect(() => {
    if (placeOnTop && !loadedBuildings) {
      return;
    }
    const setPositionFunction = () => {
      const newPosition = positionAtBalcony.getEntityPosition(positionX, positionZ, placeType, width, length);
      const yPosition = placeOnTop ? getTopDownPosition(newPosition[0], newPosition[2]) : newPosition[1];
      setPosition([newPosition[0], yPosition, newPosition[2]]);
      if (yPosition === -1) { // Fix for raycaster not hitting groundfloor
        setTimeout(() => {
          setPositionFunction();
        }, 100);
      }
    };
    setPositionFunction();
  }, [positionX, positionZ, placeType, sideWallLeft, sideWallRight, sideWallFront, sideWallBack, sizeX, sizeZ, loadedBuildings, selectionMode]);

  return position;
};

export default useEntityPosition;
