import { useEffect, useState } from 'react';
import * as THREE from 'three';
import { useFrame, useThree } from '@react-three/fiber';
import useUiStore, { SELECTION_MODE } from '../stores/uiStore';
import useViewStore, { CAMERA_MODE } from '../stores/viewStore';

function useCalculateOpacityForTopView(ceilRef, alwaysShowWhenEditableKey = '') {
  const [ceilOpacity, setCeilOpacity] = useState(1);
  const [cameraMode] = useViewStore((state) => [state.cameraMode]);
  const [openedEditableSections] = useUiStore((state) => [state.openedEditableSections]);
  const { camera } = useThree();

  useFrame(() => {
    if (!ceilRef.current) {
      if (ceilOpacity !== 1) {
        setCeilOpacity(1);
      }
      return;
    }
    if (Math.random() > 0.2) {
      return;
    }
    if (openedEditableSections.includes(alwaysShowWhenEditableKey)) {
      setCeilOpacity(cameraMode === CAMERA_MODE.TOP_VIEW ? 0.6 : 1);
      return;
    }
    if (cameraMode === CAMERA_MODE.TOP_VIEW) {
      setCeilOpacity(0);
      return;
    }
    const groupWorldPosition = new THREE.Vector3();
    ceilRef.current.getWorldPosition(groupWorldPosition);

    const isCameraHigher = camera.position.y - 2 > groupWorldPosition.y;
    setCeilOpacity(isCameraHigher ? 0.2 : 1);
  }, 1);

  return ceilOpacity;
}

export default useCalculateOpacityForTopView;
