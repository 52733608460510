import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { Box, Typography } from '@mui/material';
import KeyValueLabel from './KeyValueLabel';

export default function ColorPicker({
  value, changeValue, label, editable,
}) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    if (!editable) {
      return;
    }
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  return (
    <KeyValueLabel
      label={editable ? '' : label}
      value={(
        <Box display="flex" alignItems="center">
          <Box
            style={{
              backgroundColor: value,
              width: editable ? '42px' : '24px',
              height: '19px',
              borderRadius: editable ? '8px' : '2px',
              cursor: editable ? 'pointer' : 'inherit',
              border: '1px solid #aaa',
              margin: '3px',
              marginLeft: '0px',
            }}
            onClick={handleClick}
          />
          {displayColorPicker ? (
            <Box style={{ position: 'absolute', zIndex: '2222' }}>
              <Box
                style={{
                  position: 'fixed',
                  top: '0px',
                  right: '0px',
                  bottom: '0px',
                  left: '0px',
                }}
                onClick={handleClose}
              />
              <SketchPicker color={value} onChangeComplete={changeValue} />
            </Box>
          ) : null}
          {editable && (
          <Typography
            variant="body1"
            style={{ marginLeft: '8px' }}
          >
            {label}
          </Typography>
          )}
        </Box>
)}
    />
  );
}
