import React, { useState } from 'react';
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Box,
  ListItemText,
  Avatar,
  ListItemAvatar,
  Dialog,
  ListItemButton,
  ListItem,
  DialogTitle,
  List,
  IconButton,
  useTheme, useMediaQuery, Typography, DialogContent, Collapse,
} from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
import { blue, green } from '@mui/material/colors';
import {
  Add, Close, ExpandLess, ExpandMore,
} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import useEntitiesStore, { ENTITY_TYPE } from '../../../stores/entitiesStore';
import { OVERLAY_CONFIG } from '../../../stores/uiStore';

export default function AddNewEntitySelection({
  entityCategories,
  open,
  handleClose,
  handleAddEntity,
  allowAddingAsChild = false,
  title,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [openEntityId, setOpenEntityId] = useState(null);

  const entityTypes = Object.keys(ENTITY_TYPE)
    .filter((type) => entityCategories.includes(ENTITY_TYPE[type].category))
    .filter((type) => (allowAddingAsChild ? ENTITY_TYPE[type].allowAddingAsChild : true))
    .map((type) => ENTITY_TYPE[type]);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullScreen={isMobile}
      maxWidth="lg"
      PaperProps={{
        elevation: 0,
        sx: { boxShadow: 'none' },
      }}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: 'white',
            boxShadow: 'none',
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          marginX: 0,
          marginY: 2,
          paddingX: 1,
          paddingY: 1,
          backgroundColor: '#f1f8e9',
          borderRadius: '10px',
        }}
      >
        <Box width="100%">
          <Grid container alignItems="center">
            <Grid item xs={2}>
              <IconButton edge="end" color="inherit" onClick={handleClose} mr={1}>
                <Close />
              </IconButton>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h5">{title}</Typography>
            </Grid>
          </Grid>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ backgroundColor: '#f1f8e9', padding: 0 }}>
        <List sx={{ pt: 0, minWidth: '300px' }}>
          {entityTypes.map((entityType) => {
            const hasVariants = entityType.variants && entityType.variants.length > 0;

            return (
              <React.Fragment key={entityType.id}>
                <ListItem disableGutters>
                  <ListItemButton
                    onClick={() => {
                      if (!hasVariants) {
                        handleAddEntity(entityType);
                        handleClose();
                      } else {
                        setOpenEntityId((prev) => (prev === entityType.id ? null : entityType.id));
                      }
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: green[100], color: green[600] }}>
                        <entityType.Icon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={entityType.label} />
                    {hasVariants && (
                    <>
                      {openEntityId === entityType.id ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </>
                    )}
                  </ListItemButton>
                </ListItem>

                {hasVariants && (
                <Collapse
                  in={openEntityId === entityType.id}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {entityType.variants.map((variant) => (
                      <ListItemButton
                        key={variant.id}
                        sx={{ pl: 4 }}
                        onClick={() => {
                          handleAddEntity(entityType, variant.id);
                          handleClose();
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar sx={{ bgcolor: green[50], color: green[800] }}>
                            <entityType.Icon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={variant.name} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
                )}
              </React.Fragment>
            );
          })}
        </List>
      </DialogContent>
    </Dialog>
  );
}
