/* eslint-disable no-param-reassign */
import create from 'zustand';
import { immer } from 'zustand/middleware/immer';
import dayjs from 'dayjs';
import { persist } from 'zustand/middleware';
import {
  Blind,
  Chair, ChairAlt, LocalFlorist, TableBar, Umbrella,
} from '@mui/icons-material';
import { Table } from '@mui/material';
import { GoContainer } from 'react-icons/go';
import Crop169Icon from '@mui/icons-material/Crop169';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import useBalconyStore from './balconyStore';
import { applyRotationFromParentToChild, placeEntityOnParent } from '../utils/geometry';
import { POT_VARIANTS } from '../components/3d/PlantPotSingle';
import { POT_CONTAINER_VARIANTS } from '../components/3d/PlantContainerTwo';

export const ENTITY_CATEGORY = {
  PLANT_CONTAINER: {
    id: 'PLANT_CONTAINER',
    name: 'PLANT_CONTAINER',
    label: 'Plant container',
    Icon: LocalFlorist,
  },
  FURNITURE: {
    id: 'FURNITURE',
    name: 'FURNITURE',
    label: 'Furniture',
    Icon: TableBar,
  },
  SUN_PROTECTION: {
    id: 'SUN_PROTECTION',
    name: 'SUN_PROTECTION',
    label: 'Sun protection',
    Icon: Umbrella,
  },
};
const { getState } = useBalconyStore;
const balconyState = getState();

export const ENTITY_TYPE = {
  PLANT_POT: {
    id: 'PLANT_POT',
    name: 'PLANT_POT',
    label: 'Pot round',
    placeOnTop: true,
    category: ENTITY_CATEGORY.PLANT_CONTAINER,
    Icon: CircleOutlinedIcon,
    calculateShade: true,
    variants: [
      {
        id: POT_VARIANTS.TINY.id,
        name: 'Tiny (10cm)',
        plantPositions: [{
          index: 0,
          name: 'Center',
          x: 0,
          y: 0.07,
          z: 0,
        }],
      },
      {
        id: POT_VARIANTS.SMALL.id,
        name: 'Small (15cm)',
        plantPositions: [{
          index: 0,
          name: 'Center',
          x: 0,
          y: 0.11,
          z: 0,
        }],
      },
      {
        id: POT_VARIANTS.MIDDLE.id,
        name: 'Middle (23cm)',
        plantPositions: [{
          index: 0,
          name: 'Center',
          x: 0,
          y: 0.16,
          z: 0,
        }],
      },
      {
        id: POT_VARIANTS.LARGE.id,
        name: 'Large (32cm)',
        plantPositions: [{
          index: 0,
          name: 'Center',
          x: 0,
          y: 0.23,
          z: 0,
        }],
      },
      {
        id: POT_VARIANTS.VERY_LARGE.id,
        name: 'Very large (45cm)',
        plantPositions: [{
          index: 0,
          name: 'Center',
          x: 0,
          y: 0.39,
          z: 0,
        }],
      },
    ],
    colorAttributes: [{
      id: 'POT', name: 'Pot', default: '#4a4400',
    }],
  },
  PLANT_CONTAINER_TWO: {
    id: 'PLANT_CONTAINER_TWO',
    name: 'PLANT_CONTAINER_TWO',
    label: 'Rect planter',
    placeOnTop: true,
    category: ENTITY_CATEGORY.PLANT_CONTAINER,
    Icon: Crop169Icon,
    calculateShade: true,
    colorAttributes: [{
      id: 'POT', name: 'Pot', default: '#4a4400',
    }],
    variants: [
      {
        id: POT_CONTAINER_VARIANTS.SHORT.id,
        name: 'Short (20 cm)',
        plantPositions: [{
          index: 0,
          name: 'Center',
          x: 0,
          y: 0.15,
          z: 0,
        }],
      },
      {
        id: POT_CONTAINER_VARIANTS.SMALL.id,
        name: 'Small (35 cm)',
        plantPositions: [{
          index: 0,
          name: 'Left',
          x: -0.1,
          y: 0.15,
          z: 0,
        }, {
          index: 1,
          name: 'Right',
          x: 0.1,
          y: 0.15,
          z: 0,
        }],
      },
      {
        id: POT_CONTAINER_VARIANTS.MIDDLE.id,
        name: 'Middle (70 cm)',
        plantPositions: [{
          index: 0,
          name: 'Left',
          x: -0.18,
          y: 0.15,
          z: 0,
        }, {
          index: 1,
          name: 'Center',
          x: 0.0,
          y: 0.15,
          z: 0,
        }, {
          index: 2,
          name: 'Right',
          x: 0.18,
          y: 0.15,
          z: 0,
        },
        ],
      },
      {
        id: POT_CONTAINER_VARIANTS.LARGE.id,
        name: 'Large (100 cm)',
        plantPositions: [{
          index: 0,
          name: 'Left out',
          x: -0.4,
          y: 0.15,
          z: 0,
        }, {
          index: 1,
          name: 'Left in',
          x: -0.13,
          y: 0.15,
          z: 0,
        }, {
          index: 2,
          name: 'Right in ',
          x: 0.13,
          y: 0.15,
          z: 0,
        }, {
          index: 3,
          name: 'Right out',
          x: 0.4,
          y: 0.15,
          z: 0,
        },
        ],
      },
    ],
  },
  UMBRELLA: {
    id: 'UMBRELLA',
    name: 'UMBRELLA',
    label: 'Umbrella',
    placeOnTop: true,
    category: ENTITY_CATEGORY.SUN_PROTECTION,
    Icon: Umbrella,
    variants: [{ id: 'ROUND', name: 'Round' }, { id: 'SQUARE', name: 'Square' }],
    nummericAttributes: [{
      id: 'HEIGHT', name: 'Height', min: 1, max: 3, step: 0.1, default: 2,
    }, {
      id: 'OPEN', name: 'Open', min: 0, max: 1, step: 0.1, default: 1,
    },
    ],
    colorAttributes: [{
      id: 'HANDLE', name: 'Handle', default: '#fdfdfd',
    }, {
      id: 'TOP', name: 'Top', default: '#ff8c00',
    },
    ],
  },
  SUNBLIND: {
    id: 'SUNBLIND',
    name: 'SUNBLIND',
    label: 'Sunblind',
    placeOnTop: false,
    category: ENTITY_CATEGORY.SUN_PROTECTION,
    Icon: Blind,
    width: 1,
    length: 0.001,
    disableAttachmentSelection: true,
    disableRotation: true,
    // disablePositionZ: true,
    variants: [],
    nummericAttributes: [{
      id: 'HEIGHT_POSITION',
      name: 'Height Position',
      min: 1,
      max: 5,
      step: 0.1,
      default: 3,
    }, {
      id: 'TILT_ANGLE', name: 'Tilt angle', min: 0.0, max: 1, step: 0.1, default: 1,
    }, {
      id: 'MAXIMAL_LENGTH',
      name: 'Maximal length',
      min: 0.5,
      max: 5,
      step: 0.1,
      default: 3,
      maxLimitByBalconySizes: true,
    }, {
      id: 'WIDTH',
      name: 'Width',
      min: 0.5,
      max: 15,
      step: 0.1,
      default: 3,
      maxLimitByBalconySizes: true,
    }, {
      id: 'OPEN', name: 'Open', min: 0.01, max: 1, step: 0.01, default: 1,
    },
    ],
    colorAttributes: [{
      id: 'POLE', name: 'Pole', default: '#939393',
    }, {
      id: 'TOP', name: 'Top', default: '#ff8c00',
    },
    ],
  },
  TABLE: {
    id: 'TABLE',
    name: 'TABLE',
    label: 'Table',
    category: ENTITY_CATEGORY.FURNITURE,
    Icon: TableBar,
    disableAttachmentSelection: true,
    nummericAttributes: [{
      id: 'WIDTH',
      name: 'Width',
      min: 0.2,
      max: 5,
      step: 0.1,
      default: 2,
      maxLimitByBalconySizes: true,
    }, {
      id: 'LENGTH',
      name: 'Length',
      min: 0.2,
      max: 5,
      step: 0.1,
      default: 1,
      maxLimitByBalconySizes: true,
    },
    ],
    colorAttributes: [{
      id: 'LEGS', name: 'Legs', default: '#000000',
    }, {
      id: 'TOP', name: 'Top', default: '#ff8c00',
    },
    ],
    canAddChildren: true,
  },
  CHAIR: {
    id: 'CHAIR',
    name: 'CHAIR',
    label: 'Chair',
    category: ENTITY_CATEGORY.FURNITURE,
    Icon: Chair,
    disableAttachmentSelection: true,
    nummericAttributes: [{
      id: 'WIDTH', name: 'Width', min: 0.2, max: 5, step: 0.1, default: 0.42,
    }, {
      id: 'LENGTH', name: 'Length', min: 0.2, max: 5, step: 0.1, default: 0.48,
    },
    ],
    colorAttributes: [{
      id: 'LEGS', name: 'Legs', default: '#bababa',
    }, {
      id: 'SEAT', name: 'Seat', default: '#ddb890',
    },
    ],
    allowAddingAsChild: true,
    afterwardsModificationFunction: (entity, parentEntity, entities) => {
      if (parentEntity) {
        placeEntityOnParent(balconyState, parentEntity, entity, entities);
      }
    },
    variants: [{
      id: 'WITHOUT_ARMRESTS',
      name: 'Without arm rests',
    }, { id: 'WITH_ARMRESTS', name: 'With arm rests' }],
  },
  BENCH: {
    id: 'BENCH',
    name: 'BENCH',
    label: 'Bench',
    category: ENTITY_CATEGORY.FURNITURE,
    Icon: ChairAlt,
    disableAttachmentSelection: true,
    nummericAttributes: [{
      id: 'WIDTH', name: 'Width', min: 0.2, max: 5, step: 0.1, default: 0.5,
    }, {
      id: 'LENGTH', name: 'Length', min: 0.2, max: 5, step: 0.1, default: 0.6,
    },
    ],
    colorAttributes: [{
      id: 'LEGS', name: 'Legs', default: '#bababa',
    }, {
      id: 'SEAT', name: 'Seat', default: '#ddb890',
    },
    ],
  },
};

export const ENTITY_PLACE_TYPE = {
  FLOOR: { name: 'FLOOR', label: 'Floor' },
  SIDEWALL: { name: 'SIDEWALL', label: 'Sidewall' },
};

const defaultEntity = {
  height: 0.7,
  type: ENTITY_TYPE.PLANT_POT.name,
  placeType: ENTITY_PLACE_TYPE.FLOOR.name,
  width: 0.5,
  length: 0.5,
  positionX: 50,
  positionZ: 50,
  rotationY: 0,
};

const useEntitiesStore = create(
  persist(
    immer((set) => ({
      entities: [],
      setEntities: (entities) => set((state) => {
        state.entities = entities;
      }),
      addEntity: (entity) => set((state) => {
        const sameTypeEntities = state.entities.filter((e) => e.type === entity.type);
        const enumType = ENTITY_TYPE[Object.keys(ENTITY_TYPE).find((key) => ENTITY_TYPE[key].name === entity.type)];
        const highestOrderEntity = sameTypeEntities.reduce((prev, current) => (prev.order > current.order ? prev : current), {});

        const newEntity = {
          ...defaultEntity,
          length: ENTITY_TYPE[entity.type].length || defaultEntity.length,
          width: ENTITY_TYPE[entity.type].width || defaultEntity.width,
          type: entity.type,
          name: `${enumType.label} ${sameTypeEntities.length + 1}`,
          id: entity.id || Math.round(Math.random() * 100000),
          parentId: entity.parentId,
          order: state.entities.length,
          variant: entity.variant,
        };

        if (entity.variant == null && ENTITY_TYPE[entity.type].variants?.length) {
          newEntity.variant = highestOrderEntity.variant || ENTITY_TYPE[entity.type].variants[0].id;
        }

        if (ENTITY_TYPE[entity.type].nummericAttributes) {
          newEntity.nummericAttributes = highestOrderEntity.nummericAttributes || Object.fromEntries(
            ENTITY_TYPE[entity.type].nummericAttributes.map((attr) => [attr.id, attr.default]),
          );
        }

        if (ENTITY_TYPE[entity.type].colorAttributes) {
          newEntity.colorAttributes = highestOrderEntity.colorAttributes || Object.fromEntries(
            ENTITY_TYPE[entity.type].colorAttributes.map((attr) => [attr.id, attr.default]),
          );
        }

        if (ENTITY_TYPE[entity.type].afterwardsModificationFunction) {
          ENTITY_TYPE[entity.type].afterwardsModificationFunction(newEntity, state.entities.find((e) => e.id === newEntity.parentId), state.entities);
        }

        state.entities.unshift(newEntity);
      }),
      updateEntityPartly: (id, entity) => set((state) => {
        const foundEntity = state.entities.find((e) => e.id === id);
        if (!foundEntity) {
          throw new Error(`Entity not found: ${id}`);
        }
        state.entities = state.entities.map((e) => (e.id === id ? { ...e, ...entity } : e));
      }),
      updateEntityFieldWithChildren: (id, fieldName, value, childValueModifierFunction = null) => set((state) => {
        const foundEntity = state.entities.find((e) => e.id === id);
        if (!foundEntity) {
          throw new Error(`Entity not found: ${id}`);
        }

        const diff = value - foundEntity[fieldName];
        foundEntity[fieldName] = value;
        if (fieldName === 'rotationY') {
          const childrenEntities = state.entities.filter((e) => e.parentId === id);
          childrenEntities.forEach((e) => {
            applyRotationFromParentToChild(balconyState, foundEntity, e, diff);
          });
        } else {
          const childrenEntities = state.entities.filter((e) => e.parentId === id);
          childrenEntities.forEach((e) => {
            const newValue = e[fieldName] + diff;
            e[fieldName] = childValueModifierFunction ? childValueModifierFunction(newValue) : newValue;
          });
        }
      }),
      updateMultipleEntityFieldsWithChildren: (id, fieldNames, values, childValueModifierFunction = null) => set((state) => {
        if (!Array.isArray(fieldNames) || !Array.isArray(values)) {
          throw new Error('fieldNames and values must be arrays');
        }
        if (fieldNames.length !== values.length) {
          throw new Error('fieldNames and values arrays must have the same length');
        }
        if (fieldNames.length === 0) {
          throw new Error('fieldNames and values arrays must not be empty');
        }

        const foundEntity = state.entities.find((e) => e.id === id);
        if (!foundEntity) {
          throw new Error(`Entity not found: ${id}`);
        }
        fieldNames.forEach((fieldName, index) => {
          const value = values[index];
          const diff = value - foundEntity[fieldName];
          foundEntity[fieldName] = value;

          if (fieldName === 'rotationY') {
            const childrenEntities = state.entities.filter((e) => e.parentId === id);
            childrenEntities.forEach((e) => {
              applyRotationFromParentToChild(balconyState, foundEntity, e, diff);
            });
          } else {
            const childrenEntities = state.entities.filter((e) => e.parentId === id);
            childrenEntities.forEach((e) => {
              const newValue = e[fieldName] + diff;
              e[fieldName] = childValueModifierFunction ? childValueModifierFunction(newValue) : newValue;
            });
          }
        });
      }),
      removeEntity: (id) => set((state) => {
        state.entities = state.entities
        //     .map((entity) => ({
        //   ...entity, parentId: entity.parentId === id ? undefined : entity.parentId,
        // }))
          .filter((entity) => entity.id !== id && entity.parentId !== id);
      }),
    })),
    {
      name: 'entities-store',
      getStorage: () => localStorage,
      blacklist: [''],
    },
  ),
);

export default useEntitiesStore;
