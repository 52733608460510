import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Box,
  Grid,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Divider,
  Card,
  CardContent, Stack, Container,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Air, Height, Opacity, WbSunny,
} from '@mui/icons-material';
import { PLANT_SPECIFICATION } from '../../config/PlantSpecification';
import useShadeCalculation from '../../hooks/useShadeCalculation';
import usePlantsStore from '../../stores/plantsStore';
import useUiStore from '../../stores/uiStore';
import PlantCard from './PlantCard';
import {
  getSunFactor,
  PLANT_DISTANCE_ATTRIBUTE, POT_SIZE_ATTRIBUTE,
  WATER_NEED_ATTRIBUTE, WIND_SUSCEPTIBILITY_ATTRIBUTE,
} from '../../utils/plantAttributeUtils';
import useIsMobile from '../../hooks/useIsMobile';

export default function PlantDetailsPage({ plantId, onConfirmSelection = () => {} }) {
  const { plants } = usePlantsStore((state) => ({ plants: state.plants }));
  const shadeCalculation = useShadeCalculation();
  const plant = useMemo(() => plants.find((p) => p.id === plantId), [plants, plantId]);
  const [closeOverlay] = useUiStore((state) => [state.closeOverlay]);
  const isMobile = useIsMobile();

  const [plantSpecificationId, setPlantSpecificationId] = useState(plant?.specificationId || -1);

  const [locationAttributes, setLocationAttributes] = useState(null);
  const [matchingPlants, setMatchingPlants] = useState([]);
  const [otherPlants, setOtherPlants] = useState([]);
  const [newHighlightTimestamp, setNewHighlightTimestamp] = useState(null);
  const contentRef = useRef(null);

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    let currentLocationAttributes = null;
    if (plant?.attachedEntityId) {
      currentLocationAttributes = {
        WATER: WATER_NEED_ATTRIBUTE.MUCH,
        DISTANCE: PLANT_DISTANCE_ATTRIBUTE.MEDIUM,
        POT_SIZE: POT_SIZE_ATTRIBUTE.MEDIUM,
        WIND_STRENGTH: WIND_SUSCEPTIBILITY_ATTRIBUTE.HIGH,
        SUN: getSunFactor(1.0 - shadeCalculation.calculateShade(plant).totalShade),
      };
    }
    setLocationAttributes(currentLocationAttributes);

    const filteredSpecified = PLANT_SPECIFICATION.filter((ps) => ps.id !== plantSpecificationId);

    const ratedSpec = filteredSpecified.map((ps) => {
      const { optimalAttributes } = ps;
      const sunRating = currentLocationAttributes?.SUN && optimalAttributes.SUN?.includes(currentLocationAttributes.SUN) ? 1 : 0;
      const waterRating = currentLocationAttributes?.WATER && optimalAttributes.WATER_NEED?.includes(currentLocationAttributes.WATER) ? 1 : 0;
      const spaceRating = currentLocationAttributes?.DISTANCE && optimalAttributes.PLANT_DISTANCE?.includes(currentLocationAttributes.DISTANCE) ? 1 : 0;

      return {
        ...ps,
        rating: sunRating + waterRating + spaceRating,
      };
    }).sort((a, b) => b.rating - a.rating);

    const newMatchingPlants = ratedSpec.filter((ps) => ps.rating >= 2).slice(0, 3);
    setMatchingPlants(newMatchingPlants);

    const newOtherPlants = filteredSpecified.filter((ps) => !newMatchingPlants.map((np) => np.id).includes(ps.id));
    setOtherPlants(newOtherPlants);
  }, [plant, shadeCalculation]);

  const filteredOtherPlants = useMemo(() => {
    if (!searchTerm.trim()) return otherPlants;
    return otherPlants.filter((ps) => {
      const nameMatch = ps.name?.toLowerCase().includes(searchTerm.toLowerCase());
      const infoMatch = ps.info?.toLowerCase().includes(searchTerm.toLowerCase());
      const categoriesMatch = Array.isArray(ps.categories)
        ? ps.categories.some((cat) => cat.toLowerCase != null && cat.toLowerCase().includes(searchTerm.toLowerCase()))
        : false;

      return nameMatch || infoMatch || categoriesMatch;
    });
  }, [searchTerm, otherPlants]);

  useEffect(() => {
    if (plantSpecificationId && contentRef.current) {
      contentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [plantSpecificationId]);

  const handlePickSpecification = (specification) => {
    setPlantSpecificationId(specification.id);
    setNewHighlightTimestamp(Date.now());
  };

  const handleConfirmSelection = () => {
    onConfirmSelection(plantSpecificationId);
    closeOverlay();
  };

  const renderLocationAttribute = (IconComp, label, attributeValue, rangeText = '') => {
    if (!attributeValue) return null;
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <IconComp fontSize="small" />
        <Typography variant="body2" color="text.secondary">
          <Box component="span" sx={{ minWidth: '290px' }}><strong>{label}</strong></Box>
          :
          {' '}
          {attributeValue.label}
        </Typography>
      </Stack>
    );
  };

  return (
    <Grid container ref={contentRef} spacing={2} sx={{ width: '100%' }}>
      {locationAttributes && (
      <Grid item xs={12} sm={12}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" gutterBottom component="div">
              Location
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Stack spacing={1}>
                  {renderLocationAttribute(WbSunny, 'Sun', locationAttributes.SUN, '(range 0-5)')}
                  {renderLocationAttribute(Opacity, 'Water', locationAttributes.WATER, '(range 0-3)')}
                  {renderLocationAttribute(Opacity, 'Pot Size', locationAttributes.POT_SIZE)}
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack spacing={1}>
                  {renderLocationAttribute(Height, 'Distance', locationAttributes.DISTANCE)}
                  {renderLocationAttribute(Air, 'Wind Strength', locationAttributes.WIND_STRENGTH)}
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      )}

      <Grid item xs={12} sm={12}>
        {plantSpecificationId && (
          <PlantCard
            fullWidth={!isMobile}
            specification={PLANT_SPECIFICATION.find((ps) => ps.id === plantSpecificationId)}
            plant={plant}
            locationAttributes={locationAttributes}
            onPickSpecification={handlePickSpecification}
            onConfirmSelection={handleConfirmSelection}
            isSelected
            highlightedTimestamp={newHighlightTimestamp}
          />
        )}
      </Grid>

      <Grid item xs={12}>
        <Divider style={{ margin: '20px 0' }} />
        {matchingPlants.length > 0 && (
          <Box mb={2}>
            <Typography variant="h6" gutterBottom component="div">
              Plants for this location
            </Typography>
            <Grid container spacing={2}>
              {matchingPlants.map((ps) => (
                <Grid item xs={12} sm={6} md={6} key={ps.id}>
                  <PlantCard
                    specification={ps}
                    plant={plant}
                    locationAttributes={locationAttributes}
                    onPickSpecification={handlePickSpecification}
                    onConfirmSelection={handleConfirmSelection}
                    isSelected={ps.id === plantSpecificationId}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between" mb={2} sx={{ width: '100%' }}>
          <Typography variant="h6" gutterBottom component="div" sx={{ marginRight: 5 }}>
            Other Plants
          </Typography>
          <TextField
            size="small"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ width: '50%' }}
            InputProps={{
              endAdornment: searchTerm ? (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={() => setSearchTerm('')}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
          />
        </Box>
        <Grid container spacing={2}>
          {filteredOtherPlants.map((ps) => (
            <Grid item xs={12} sm={6} md={6} key={ps.id}>
              <PlantCard
                specification={ps}
                plant={plant}
                locationAttributes={locationAttributes}
                onPickSpecification={handlePickSpecification}
                onConfirmSelection={handleConfirmSelection}
                isSelected={ps.id === plantSpecificationId}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
