import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Collapse,
  Container,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import {
  AcUnit,
  Air,
  CalendarMonth,
  Category,
  ColorLens,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Height,
  Opacity,
  SendAndArchive,
  Speed,
  Thermostat,
  WbSunny,
  Yard,
} from '@mui/icons-material';
import { renderHighlightedAttributes } from './AttributeUtils';
import useIsMobile from '../../hooks/useIsMobile';

export default function PlantCard({
  specification, isSelected, locationAttributes, onPickSpecification, onConfirmSelection, fullWidth,
  highlightedTimestamp = null,
}) {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (highlightedTimestamp) {
      setExpanded(true);
    }
  }, [highlightedTimestamp]);

  if (!specification) {
    return null;
  }

  const { categories } = specification;

  const {
    SUN,
    WATER_NEED,
    PLANT_DISTANCE,
    SOIL,
    MINDESTTEMPERATUR,
    MAXTEMPERATUR,
    LEAVES_GREEN_TIME,
    WIND_SUSCEPTIBILITY,
    POT_SIZE,
    BLOOMING_TIME,
    BLOOMING_COLOR,
    GROWTH_SPEED,
    GROWTH_MONTHS,
    PLANT_MAX_SIZE_ATTRIBUTE,
    FRUIT_HARVEST_TIME,
  } = specification.optimalAttributes;

  return (
    <Card sx={{ maxWidth: '100%' }}>
      <Box display={fullWidth ? 'flex' : 'block'}>
        <CardMedia
          component="img"
          height="140"
          image={specification.image}
          alt={specification.name}
          onClick={handleExpandClick}
          sx={{ cursor: 'pointer', width: fullWidth ? '50%' : '100%' }}
        />
        <CardContent
          onClick={handleExpandClick}
          sx={{
            cursor: 'pointer',
            pl: 0,
            pr: 1,
            py: 1,
            paddingBottom: '8px !important',
            width: fullWidth ? '50%' : '100%',
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <IconButton onClick={handleExpandClick} aria-expanded={expanded}>
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            <Box display="flex" justifyContent="space-between" width="100%">
              <Typography variant="h6" component="div">
                {specification.name}
              </Typography>
            </Box>
            {!isSelected && (
              <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                <Button variant="outlined" size="small" onClick={() => onPickSpecification(specification)}>
                  Choose
                </Button>
              </Box>
            )}
          </Box>
          {isSelected && (
          <Box height="60%" display="flex" justifyContent="flex-end" alignItems="flex-end">
            <Button variant="contained" color="success" size="medium" onClick={() => onConfirmSelection(specification)} startIcon={<SendAndArchive />}>
              Confirm!
            </Button>
          </Box>
          ) }
        </CardContent>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph variant="body2" color="text.secondary">
            {specification.info}
          </Typography>
          <Box display={fullWidth ? 'flex' : 'block'}>
            <Stack spacing={1} sx={{ width: fullWidth ? '50%' : '100%' }}>
              {renderHighlightedAttributes({
                IconComp: Category,
                label: 'Category',
                specAttributes: categories,
                locationAttribute: locationAttributes?.SUN,
                attributeType: 'CATEGORY',
              })}
              {renderHighlightedAttributes({
                IconComp: WbSunny,
                label: 'Sun',
                specAttributes: SUN,
                locationAttribute: locationAttributes?.SUN,
                attributeType: 'SUN',
              })}
              {renderHighlightedAttributes({
                IconComp: Opacity,
                label: 'Water Need',
                specAttributes: WATER_NEED,
                locationAttribute: locationAttributes?.WATER,
                attributeType: 'WATER_NEED',
              })}
              {renderHighlightedAttributes({
                IconComp: Opacity,
                label: 'Pot Size',
                specAttributes: POT_SIZE,
                locationAttribute: locationAttributes?.POT_SIZE,
                attributeType: 'POT_SIZE',
              })}
              {renderHighlightedAttributes({
                IconComp: Height,
                label: 'Distance',
                specAttributes: PLANT_DISTANCE,
                locationAttribute: locationAttributes?.DISTANCE,
                attributeType: 'PLANT_DISTANCE',
              })}
              {renderHighlightedAttributes({
                IconComp: Air,
                label: 'Wind',
                specAttributes: WIND_SUSCEPTIBILITY,
                locationAttribute: locationAttributes?.WIND_STRENGTH,
                attributeType: 'WIND_SUSCEPTIBILITY',
              })}
            </Stack>
            <Stack spacing={1} sx={{ width: fullWidth ? '50%' : '100%' }}>
              {renderHighlightedAttributes({
                IconComp: Yard,
                label: 'Soil',
                specAttributes: SOIL,
                locationAttribute: null,
                attributeType: 'SOIL',
              })}
              {renderHighlightedAttributes({
                IconComp: Speed,
                label: 'Growth Speed',
                specAttributes: GROWTH_SPEED,
                locationAttribute: null,
                attributeType: 'GROWTH_SPEED',
              })}
              {renderHighlightedAttributes({
                IconComp: AcUnit,
                label: 'Min Temp',
                specAttributes: MINDESTTEMPERATUR,
                locationAttribute: null,
                attributeType: 'MINDESTTEMPERATUR',
              })}
              {renderHighlightedAttributes({
                IconComp: Thermostat,
                label: 'Max Temp',
                specAttributes: MAXTEMPERATUR,
                locationAttribute: null,
                attributeType: 'MAXTEMPERATUR',
              })}
              {renderHighlightedAttributes({
                IconComp: CalendarMonth,
                label: 'Blooming Time',
                specAttributes: BLOOMING_TIME,
                locationAttribute: null,
                attributeType: 'BLOOMING_TIME',
              })}
              {renderHighlightedAttributes({
                IconComp: ColorLens,
                label: 'Blooming Color',
                specAttributes: BLOOMING_COLOR,
                locationAttribute: null,
                attributeType: 'BLOOMING_COLOR',
              })}
              {renderHighlightedAttributes({
                IconComp: CalendarMonth,
                label: 'Growth Months',
                specAttributes: GROWTH_MONTHS,
                locationAttribute: null,
                attributeType: 'GROWTH_MONTHS',
              })}
              {renderHighlightedAttributes({
                IconComp: Height,
                label: 'Max Size',
                specAttributes: PLANT_MAX_SIZE_ATTRIBUTE,
                locationAttribute: null,
                attributeType: 'PLANT_MAX_SIZE_ATTRIBUTE',
              })}
              {renderHighlightedAttributes({
                IconComp: CalendarMonth,
                label: 'Leaves Green Time',
                specAttributes: LEAVES_GREEN_TIME,
                locationAttribute: null,
                attributeType: 'LEAVES_GREEN_TIME',
              })}
              {renderHighlightedAttributes({
                IconComp: CalendarMonth,
                label: 'Harvest Time',
                specAttributes: FRUIT_HARVEST_TIME,
                locationAttribute: null,
                attributeType: 'FRUIT_HARVEST_TIME',
              })}
            </Stack>
          </Box>
        </CardContent>
      </Collapse>
    </Card>
  );
}
