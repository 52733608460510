import React, {
  useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  FirstPersonControls,
  OrbitControls,
  OrthographicCamera,
  PerspectiveCamera, TrackballControls,
} from '@react-three/drei';
import { Box3, MathUtils, Vector3 } from 'three';
import { useShallow } from 'zustand/react/shallow';
import { useFrame, useThree } from '@react-three/fiber';
import { useMediaQuery, useTheme } from '@mui/material';
import { gsap } from 'gsap';
import useSelectionModeClick from '../hooks/useSelectionModeClick';
import useViewStore, { CAMERA_MODE } from '../stores/viewStore';
import useScenarioStore from '../stores/scenarioStore';
import { ThreeContext } from '../context/ThreeContext';
import useUiStore, { SELECTION_MODE, VIEW_EVENTS } from '../stores/uiStore';
import useBalconyStore from '../stores/balconyStore';
import useIsMobile from '../hooks/useIsMobile';

const FOCAL_LENGTH_BY_CAMERA_MODE = {
  [CAMERA_MODE.FIRST_PERSON]: 14,
  [CAMERA_MODE.TOP_VIEW]: 180,
  [CAMERA_MODE.THIRD_PERSON]: 55,
};

const FOCAL_LENGTH_BY_CAMERA_MODE_MOBILE = {
  [CAMERA_MODE.FIRST_PERSON]: 16,
  [CAMERA_MODE.TOP_VIEW]: 120,
  [CAMERA_MODE.THIRD_PERSON]: 55,
};

const getFocalLengthByCameraMode = (cameraMode, isMobile) => (isMobile ? FOCAL_LENGTH_BY_CAMERA_MODE_MOBILE[cameraMode] : FOCAL_LENGTH_BY_CAMERA_MODE[cameraMode]);

function calculateFOV(focalLength, filmGauge = 35) {
  const fovRadians = 2 * Math.atan(filmGauge / (2 * focalLength));
  return MathUtils.radToDeg(fovRadians);
}

function SmoothFocalLength({
  scene, cameraMode, focusOnId, isMobile,
}) {
  const camera = useThree((state) => state.camera);
  const targetFocalLength = useRef(camera.getFocalLength());

  useEffect(() => {
    if (focusOnId) {
      let object = scene.getObjectById(focusOnId);
      if (!object) {
        object = scene.getObjectByName(focusOnId);
      }
      if (!object) {
        return;
      }

      const objectPosition = new Vector3();
      object.getWorldPosition(objectPosition);
      const cameraPosition = camera.position;
      const distance = cameraPosition.distanceTo(objectPosition);

      let largestDimension = 1.5;
      if (object) {
        const bbox = new Box3().setFromObject(object);
        const size = bbox.getSize(new Vector3());
        largestDimension = Math.max(size.x, size.y, size.z);
      }

      if (cameraMode === CAMERA_MODE.FIRST_PERSON) {
        targetFocalLength.current = (isMobile ? 20 : 14) - largestDimension * 1.5 + distance * 2.5;
      } else {
        targetFocalLength.current = (isMobile ? 72 : 55) - largestDimension * 1.5 + distance * 1.5;
      }
    } else {
      targetFocalLength.current = getFocalLengthByCameraMode(cameraMode, isMobile);
    }
  }, [focusOnId, isMobile, cameraMode]);

  useFrame(() => {
    // On each frame, smoothly move from current to target focal length
    const current = camera.getFocalLength();
    const target = targetFocalLength.current;
    const next = MathUtils.lerp(current, target, 0.08); // 0.1 = lerp speed
    camera.setFocalLength(next);
  });

  return null; // This component doesn’t render anything visually
}

export default function CameraControl({ camera, scene }) {
  const [setCameraMode, cameraMode, focusOnId] = useViewStore(
    useShallow((state) => [state.setCameraMode, state.cameraMode,
      state.focusOnId]),
  );
  const [
    balconyCalculatedPosition, balconyCalculatedRotationY, loadedBuildings, selectedBuilding, temporarySelectedBuilding,
  ] = useScenarioStore(useShallow((state) => [state.balconyCalculatedPosition,
    state.balconyCalculatedRotationY, state.loadedBuildings, state.selectedBuilding, state.temporarySelectedBuilding]));
  const [selectionMode, transformControlActive, openedEditableSections] = useUiStore(useShallow((state) => [state.selectionMode, state.transformControlActive, state.openedEditableSections]));
  const [sizeX, sizeZ] = useBalconyStore(useShallow((state) => [state.sizeX, state.sizeZ]));
  const [isFullscreen] = useUiStore(useShallow((state) => [state.isFullscreen]));

  const getBalconyCenter = useCallback((offsetZ = 0) => {
    const localCenterOffset = new Vector3(0, 0.5 + (cameraMode === CAMERA_MODE.FIRST_PERSON ? 1.2 : 0), -sizeZ / 2);
    localCenterOffset.applyAxisAngle(new Vector3(0, 1, offsetZ), balconyCalculatedRotationY);
    return new Vector3().addVectors(balconyCalculatedPosition, localCenterOffset);
  }, [balconyCalculatedPosition, balconyCalculatedRotationY, sizeZ, cameraMode]);

  const [balconySizeEditOpen, setBalconySizeEditOpen] = useState(false);
  const [focusPosition, setFocusPosition] = useState(getBalconyCenter());
  const focusPositionRef = useRef(focusPosition);
  const [triggerRepositionCamera, setTriggerRepositionCamera] = useState(null);
  const [firstSetup, setFirstSetup] = useState(true);
  const isMobile = useIsMobile();
  const [selectModeForFocusPositionCalculation, setselectModeForFocusPositionCalculation] = useState(null);

  const controlRef = useRef();

  if (!balconyCalculatedPosition) {
    return null;
  }

  useEffect(() => {
    setBalconySizeEditOpen(openedEditableSections.includes('BALCONY_SIZE'));
  }, [openedEditableSections]);

  const getOffsetCameraPosition = useCallback((offsetY, offsetZ) => {
    const showFromSide = balconySizeEditOpen ? false : (isMobile ? sizeX > sizeZ * 2.1 : sizeX > sizeZ * 3.8);
    const modificator = isFullscreen ? 1.2 : 1;

    const offsetVec = new Vector3(showFromSide ? offsetZ * modificator : 0, offsetY * modificator, showFromSide ? 0 : offsetZ * modificator);
    offsetVec.applyAxisAngle(new Vector3(0, 1, 0), balconyCalculatedRotationY);
    return offsetVec;
  }, [balconyCalculatedRotationY, isMobile, sizeX, sizeZ, balconySizeEditOpen]);

  useEffect(() => {
    if (!controlRef.current) return;
    controlRef.current.enablePan = !transformControlActive;
    controlRef.current.enableRotate = !transformControlActive;
    controlRef.current.enable = !transformControlActive;
  }, [transformControlActive]);

  useEffect(() => {
    focusPositionRef.current = focusPosition;
  }, [focusPosition]);

  useEffect(() => {
    const updateFocusPosition = () => {
      const getFocusPosition = () => {
        if (!focusOnId || !loadedBuildings || (cameraMode === CAMERA_MODE.TOP_VIEW && selectionMode === null)) {
          return getBalconyCenter();
        }
        let object = scene.getObjectById(focusOnId);
        if (!object) {
          object = scene.getObjectByName(focusOnId);
        }
        if (!object) {
          console.error('Object not found:', focusOnId);
          return getBalconyCenter();
        }
        const newPosition = new Vector3();
        object.getWorldPosition(newPosition);

        return (newPosition);
      };

      const newFocusPosition = getFocusPosition();
      if (newFocusPosition.equals(focusPositionRef.current)) {
        return;
      }
      if (Math.abs(newFocusPosition.y - focusPositionRef.current.y) > 3) {
        setTriggerRepositionCamera(Math.random());
      }
      // console.log('Calculated focus position with focus id', focusOnId, newFocusPosition);
      if (selectionMode !== selectModeForFocusPositionCalculation) {
        setTriggerRepositionCamera(Math.random());
      }
      setselectModeForFocusPositionCalculation(selectionMode);
      setFocusPosition(newFocusPosition);
    };

    const intervalId = setInterval(updateFocusPosition, isMobile ? 70 : 20);
    return () => clearInterval(intervalId);
  }, [focusOnId, loadedBuildings, scene, selectionMode, cameraMode]);

  useEffect(() => {
    if (selectionMode === SELECTION_MODE.BALCONY_POSITION || selectionMode === SELECTION_MODE.BUILDING) {
      setTriggerRepositionCamera(Math.random());
    }
  }, [focusPosition, selectionMode, cameraMode]);

  const getCalculatedPosition = useCallback((additionalX, additionalY, additionalZ) => {
    const additionalPosition = new Vector3(additionalX, additionalY, additionalZ);
    additionalPosition.applyAxisAngle(new Vector3(0, isMobile ? 0 : 1, 0), balconyCalculatedRotationY);
    return new Vector3().addVectors(new Vector3(focusPosition.x, focusPosition.y + 1, focusPosition.z), additionalPosition);
  }, [focusPosition, balconyCalculatedPosition]);

  const animateCameraToPosition = (controls, newPosition, duration = 1) => {
    if (controls && controls.target) {
      gsap.to(controls.target, {
        x: newPosition.x,
        y: newPosition.y,
        z: newPosition.z,
        duration,
        onUpdate: () => controls.update(),
      });
    }
  };

  useEffect(() => {
    if (controlRef.current && focusPosition && (cameraMode !== CAMERA_MODE.TOP_VIEW || selectionMode !== null)) {
      animateCameraToPosition(controlRef.current, focusPosition, 0.4);
    }
  }, [focusPosition, cameraMode]);

  const renderMe = useMemo(() => {
    if (!loadedBuildings) {
      return null;
    }

    if (selectionMode === SELECTION_MODE.BUILDING || selectionMode === SELECTION_MODE.BALCONY_POSITION) {
      camera.position.set(focusPosition.x - 0.1, focusPosition.y + (isMobile ? 161 : 142), focusPosition.z);
      camera.rotation.set(-Math.PI / 2, 0, 0);
      // Todo: Adjust height depending on selected building
      return (
        <group key="selection-mode-camera-building">
          <OrbitControls
            ref={controlRef}
            key={`top-view-orbit-controls-selection${selectionMode}`}
            target={focusPosition}
            name="orbitControls"
            enableRotate={false}
            enableZoom
            maxDistance={isMobile ? 350 : 400}
            mouseButtons={{
              LEFT: 2,
              MIDDLE: 1,
              RIGHT: 0,
            }}
            touches={{
              ONE: 1,
              TWO: 0,
            }}
          />
        </group>
      );
    }

    if (cameraMode === CAMERA_MODE.TOP_VIEW) {
      const mobileFactor = isMobile ? 1.3 : 1;
      const balconyDiagonal = Math.sqrt(sizeX ** 2 + sizeZ ** 2);
      const balconyRadius = balconyDiagonal / 2;
      const fovRadians = calculateFOV(getFocalLengthByCameraMode(cameraMode, isMobile)) * (Math.PI / 180);
      const distanceNeeded = balconyRadius / Math.sin(fovRadians / 2) * 0.99; // + padding

      const centerPos = getBalconyCenter();
      const offsetY = distanceNeeded * mobileFactor;
      const offsetZ = -1 * mobileFactor;

      // Make an offset vector, rotate by balconyCalculatedRotationY
      const offsetVec = getOffsetCameraPosition(offsetY, offsetZ);

      // Final camera position = centerPos + offsetVec
      const cameraPos = new Vector3().addVectors(centerPos, offsetVec);
      camera.position.set(cameraPos.x, cameraPos.y, cameraPos.z);
      return (
        <group key="top-view-camera">
          <OrbitControls
            name="orbitControls"
            ref={controlRef}
            key="top-view-orbit-controls"
            target={centerPos}
            enableRotate={false}
            enableZoom
            mouseButtons={{
              LEFT: 2,
              MIDDLE: 1,
              RIGHT: 0,
            }}
            touches={{
              ONE: 1,
              TWO: 0,
            }}
          />
        </group>
      );
    }

    if (cameraMode === CAMERA_MODE.FIRST_PERSON) {
      const centerPos = getBalconyCenter();
      const offsetVec = getOffsetCameraPosition(0.05, 0.1);

      // Final camera position = centerPos + offsetVec
      const cameraPos = new Vector3().addVectors(centerPos, offsetVec);
      const v3 = centerPos;
      camera.position.set(cameraPos.x, cameraPos.y, cameraPos.z);
      return (
        <group key="first-person-camera">
          <OrbitControls
            ref={controlRef}
            name="orbitControls"
            key="first-view-orbit-controls"
            target={getBalconyCenter(0)}
          // target={new Vector3(balconyCalculatedPosition.x, balconyCalculatedPosition.y + 1, balconyCalculatedPosition.z)}
            enablePan
            // enableZoom
            maxDistance={6.2}
            minDistance={0.1}
          />
        </group>
      );
    }
    if (cameraMode === CAMERA_MODE.THIRD_PERSON) {
      const mobileFactor = isMobile ? 1.1 : 1;

      const balconyDiagonal = Math.sqrt(sizeX ** 2 + sizeZ ** 2);
      const balconyRadius = balconyDiagonal / 2;
      const fovRadians = calculateFOV(getFocalLengthByCameraMode(cameraMode, isMobile)) * (Math.PI / 180);
      const distanceNeeded = balconyRadius / Math.sin(fovRadians / 2) * 0.7; // + padding

      const centerPos = getCalculatedPosition(0, 0, 0);
      const offsetY = distanceNeeded * mobileFactor;
      const offsetZ = -distanceNeeded * mobileFactor;

      // Make an offset vector, rotate by balconyCalculatedRotationY
      const offsetVec = getOffsetCameraPosition(offsetY, offsetZ, balconyCalculatedRotationY);

      // Final camera position = centerPos + offsetVec
      const cameraPos = new Vector3().addVectors(centerPos, offsetVec);
      camera.position.set(cameraPos.x, cameraPos.y, cameraPos.z);
      return (
        <group key="third-camera">
          <OrbitControls
            ref={controlRef}
            name="orbitControls"
            target={centerPos}
            enablePan
            enableZoom
            maxDistance={distanceNeeded * 3}
            minDistance={1}
          />
        </group>
      );
    }
    console.error('Unknown camera mode');

    return null;
  }, [cameraMode,
    balconyCalculatedPosition, selectionMode, selectedBuilding, triggerRepositionCamera,
    sizeX, sizeZ, loadedBuildings, isMobile, balconySizeEditOpen, firstSetup]);

  return (
    <>
      <SmoothFocalLength scene={scene} cameraMode={cameraMode} focusOnId={selectionMode === null && cameraMode !== CAMERA_MODE.TOP_VIEW ? focusOnId : null} isMobile={isMobile} />
      {renderMe}
    </>
  );
}
