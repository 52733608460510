import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import * as THREE from 'three';
import {
  Box3, Matrix4, Raycaster, Vector2, Vector3,
} from 'three';
import { useShallow } from 'zustand/react/shallow';
import { useFrame, useThree } from '@react-three/fiber';
import { degToRad, radToDeg } from 'three/src/math/MathUtils';
import { TransformControls } from '@react-three/drei';
import {
  Select,
} from '@react-three/postprocessing';
import Draggable from '../../components/3d/Draggable';
import Plant from '../../components/3d/BasicPlant';
import Wall from '../../components/3d/Wall';
import { ThreeContext } from '../../context/ThreeContext';
import Table from '../../components/3d/Table';
import useScenarioStore from '../../stores/scenarioStore';
import useBalconyStore from '../../stores/balconyStore';
import BalconyEntities from './BalconyEntities';
import BalconyPlants from './BalconyPlants';
import { getAllWithTag } from '../../utils/sceneUtils';
import useUiStore, { SELECTION_MODE } from '../../stores/uiStore';
import ShadowVisualizer from '../../components/3d/ShadowVisualizer';
import useViewStore, { CAMERA_MODE } from '../../stores/viewStore';
import useCalculateOpacityForTopView from '../../hooks/useCalculateOpacityForTopView';

export default function Balcony({ }) {
  const [
    selectedBuilding, balconyPositionWidthRelative, balconyPositionHeightRelative,
    setBalconyCalculatedPosition,
    setBalconyCalculatedRotationY,
    setBalconyPositionCollision,
  ] = useScenarioStore(useShallow((state) => [state.selectedBuilding,
    state.balconyPositionWidthRelative, state.balconyPositionHeightRelative,
    state.setBalconyCalculatedPosition, state.setBalconyCalculatedRotationY,
    state.setBalconyPositionCollision]));
  const [showShadowLayer, selectionMode, openedEditableSections] = useUiStore(useShallow((state) => [state.showShadowLayer, state.selectionMode, state.openedEditableSections]));
  const { scene } = useContext(ThreeContext);
  const [cameraMode] = useViewStore(
    useShallow((state) => [state.cameraMode]),
  );
  const balconyRef = useRef();
  const [sizeX, sizeZ, sideWallLeft, sideWallRight, sideWallFront, sideWallBack, sideWallTop] = useBalconyStore(useShallow((state) => [state.sizeX,
    state.sizeZ, state.sideWallLeft, state.sideWallRight,
    state.sideWallFront, state.sideWallBack, state.sideWallTop]));
  const [balconyDirection, setBalconyDirection] = useState(null);
  const [balconyPosition, setBalconyPosition] = useState(null);
  const [ceilOpacity, setCeilOpacity] = useState(false);
  const ceilRef = useRef();
  const { camera } = useThree();
  const opacityForTopView = useCalculateOpacityForTopView(ceilRef, 'CEIL');

  useEffect(() => {
    setCeilOpacity(opacityForTopView);
  }, [opacityForTopView]);

  useEffect(() => {
    if (selectedBuilding) {
      const positionSlideValueRelativeToBuilding = balconyPositionWidthRelative / 100.0; // adjust these values as needed
      const positionSlideValueRelativeToBuildingHeight = balconyPositionHeightRelative / 100.0; // adjust these values as needed

      // Calculate the position of the balcony based on the x and y variables
      let totalLengthOfCoordinates = 0;
      selectedBuilding.coordinates.forEach((coord, index) => {
        if (index === selectedBuilding.coordinates.length - 1) return;
        totalLengthOfCoordinates += Math.sqrt((coord.x - selectedBuilding.coordinates[index + 1].x) ** 2 + (coord.y - selectedBuilding.coordinates[index + 1].y) ** 2);
      });

      const positionSlideValue = totalLengthOfCoordinates * positionSlideValueRelativeToBuilding;

      let lengthCounter = 0;
      for (let i = 0; i < selectedBuilding.coordinates.length - 1; i++) {
        const start = selectedBuilding.coordinates[i];
        const end = selectedBuilding.coordinates[i + 1];
        const segmentLength = Math.sqrt((end.x - start.x) ** 2 + (end.y - start.y) ** 2);
        lengthCounter += segmentLength;
        if (lengthCounter >= positionSlideValue) {
          const relative = ((positionSlideValue - (lengthCounter - segmentLength)) / segmentLength);
          const relativeCenter = {
            x: start.x + (end.x - start.x) * relative,
            y: start.y + (end.y - start.y) * relative,
          };

          const segmentDirection = new Vector3(end.x - start.x, 0, end.y - start.y).normalize();

          const newBalconyPosition = new Vector3(
            relativeCenter.x,
            positionSlideValueRelativeToBuildingHeight * selectedBuilding.height, // assuming the balcony is at ground level
            relativeCenter.y,
          );
          setBalconyPosition(newBalconyPosition);
          setBalconyCalculatedPosition(newBalconyPosition);
          const newBalconyRotationY = Math.atan2(segmentDirection.x, segmentDirection.z) + Math.PI / 2;
          setBalconyDirection(newBalconyRotationY);
          setBalconyCalculatedRotationY(newBalconyRotationY);
          break;
        }
      }
    }
  }, [selectedBuilding, balconyPositionWidthRelative, balconyPositionHeightRelative]);

  useEffect(() => {
    if (!balconyPosition || !balconyDirection || !balconyRef?.current) return;

    const corners = [
      new THREE.Vector3(-sizeX / 2, 0, 0),
      new THREE.Vector3(sizeX / 2, 0, 0),
      new THREE.Vector3(0, 0, -sizeZ),
      new THREE.Vector3(sizeX / 2, 0, -sizeZ),
      new THREE.Vector3(-sizeX / 2, 0, -sizeZ),
    ];

    const matrix = new THREE.Matrix4().makeRotationY(balconyDirection);
    corners.forEach((corner) => corner.applyMatrix4(matrix));
    corners.forEach((corner) => corner.add(balconyPosition));

    const allObjects = getAllWithTag(scene, 'building');
    if (!allObjects) return;
    const buildingObjects = allObjects.filter((object) => object.userData.id !== selectedBuilding?.id);

    let collisionFound = false;

    // eslint-disable-next-line no-restricted-syntax
    for (const corner of corners) {
      const { x } = corner;
      const { z } = corner;

      const raycaster = new Raycaster();
      raycaster.set(new Vector3(x, -10, z), new Vector3(0, 1, 0));
      const intersects = raycaster.intersectObjects(buildingObjects, true);
      if (intersects.length > 0) {
        collisionFound = true;
        break;
      }
    }

    setBalconyPositionCollision(collisionFound);
  }, [balconyRef.current, balconyPosition, balconyDirection, sizeX, sizeZ]);

  return balconyPosition
    ? selectionMode === SELECTION_MODE.BALCONY_POSITION ? (
      <Select enabled>
        <group
          rotation={[0, balconyDirection, 0]}
          position={balconyPosition}
          ref={balconyRef}
          name="balcony"
        >
          <mesh>
            <boxGeometry args={[sizeX, 2, sizeZ * 2]} />
            <meshBasicMaterial color={0xff0000} />
          </mesh>
        </group>
      </Select>
    ) : (
      <group
        rotation={[0, balconyDirection, 0]}
        position={balconyPosition}
        ref={balconyRef}
        name="balcony"
      >
        {showShadowLayer && (<ShadowVisualizer />)}
        <BalconyEntities />
        <BalconyPlants />
        {/* <Table position={[1, 0, 0]} width={0.5} /> */}
        {/* <Table position={[-1, 0, 0]} length={0.3} /> */}

        <Wall
          position={[0, 0, -sizeZ / 2]}
          height={0.1}
          width={sizeX}
          thick={sizeZ}
          name="Ground wall"
          color="#c1c1c1"
        />

        {sideWallTop.enabled && (
        <Wall
          meshRef={ceilRef}
          opacity={ceilOpacity}
          position={[0, sideWallTop.offsetY, -(sideWallTop.sameSizeAsFloor ? sizeZ : sideWallTop.sizeZ) / 2]}
          height={sideWallTop.sizeY}
          width={sideWallTop.sameSizeAsFloor ? sizeX : sideWallTop.sizeX}
          thick={sideWallTop.sameSizeAsFloor ? sizeZ : sideWallTop.sizeZ}
          name="Top wall"
          placedOnTop
          adjustTransparentWhenCameraHigher={!openedEditableSections.includes('CEIL') || cameraMode === CAMERA_MODE.TOP_VIEW}
        />
        )}

        {sideWallLeft.enabled && (
        <Wall
          topBar
          barWidth={0.1}
          holeWidth={0.1}
          rotateY={Math.PI / 2}
          position={[sizeX / 2, sideWallLeft.height / 2, -sizeZ / 2]}
          width={sizeZ}
          height={sideWallLeft.height}
          thick={0.1}
          type={sideWallLeft.type}
          name="Left wall"
        />
        )}

        {sideWallRight.enabled && (
        <Wall
          topBar
          rotateY={Math.PI / 2}
          position={[-sizeX / 2, sideWallRight.height / 2, -sizeZ / 2]}
          width={sizeZ}
          height={sideWallRight.height}
          thick={0.1}
          type={sideWallRight.type}
          name="Right wall"
        />
        )}
        {sideWallFront.enabled && (
        <Wall
          topBar
          position={[0, sideWallFront.height / 2, -sizeZ]}
          width={sizeX + 0.1}
          thick={0.1}
          height={sideWallFront.height}
          type={sideWallFront.type}
          name="Front wall"
        />
        )}
        {sideWallBack.enabled && (
        <Wall
          topBar
          position={[0, sideWallBack.height / 2, 0]}
          width={sizeX + 0.1}
          thick={0.1}
          height={sideWallBack.height}
          type={sideWallBack.type}
          name="Back wall"
        />
        )}
        { cameraMode === CAMERA_MODE.TOP_VIEW && (
        <>
          <gridHelper args={[20, 80, '#5e5e5e', '#5e5e5e']} position={[0, 0.15, 0]} />
          <mesh position={[0, -0.1, 0]} rotation={[-Math.PI / 2, 0, 0]}>
            <planeGeometry args={[20, 20]} />
            <meshStandardMaterial color="white" />
          </mesh>
        </>
        )}
      </group>
    ) : null;
}
