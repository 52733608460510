import React, { useCallback, useState } from 'react';
import {
  Box,
  Button, Divider, Grid, Typography,
} from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
import { Add } from '@mui/icons-material';
import AddNewEntitySection from './fragments/AddNewEntitySection';
import PlantsList from './fragments/PlantsList';
import AddNewPlantSection from './fragments/AddNewPlantSection';
import EntitiesList from './fragments/EntitiesList';
import useEntitiesStore, { ENTITY_CATEGORY, ENTITY_TYPE } from '../../stores/entitiesStore';
import AddNewEntitySelection from './fragments/AddNewEntitySelection';
import usePlantsStore from '../../stores/plantsStore';
import GrowingSimulationSection from './fragments/GrowingSimulationSection';
import { getPlantPositions } from '../../utils/entityUtils';
import TimeSimulationSection from './fragments/TimeSimulationSection';
import ShadowVisualizer from '../../components/3d/ShadowVisualizer';
import ShadeSimulationSection from './fragments/ShadeSimulationSection';

export default function AnalyzeContent() {
  const [lastAddedEntityId, setLastAddedEntityId] = useState(null);

  return (
    <Grid container rowSpacing={3}>
      <Grid item xs={12}>
        <TimeSimulationSection />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <GrowingSimulationSection />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <ShadeSimulationSection />
      </Grid>
    </Grid>
  );
}
