import React from 'react';
import {
  Grid, Button,
  Box, Typography, FormControlLabel, Checkbox,
  FormHelperText,
} from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers';
import usePlantsStore from '../../../stores/plantsStore';
import CustomDateTimePicker from '../../DateTimePicker';
import useScenarioStore from '../../../stores/scenarioStore';
import useUiStore from '../../../stores/uiStore';

export default function ShadeSimulationSection() {
  const [showShadowLayer, setShowShadowLayer] = useUiStore(useShallow((state) => [state.showShadowLayer, state.setShowShadowLayer]));

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FormControlLabel
          control={(
            <Checkbox
              checked={showShadowLayer}
              onChange={(event) => setShowShadowLayer(event.target.checked)}
            />
    )}
          label={(
            <Typography variant="body1" sx={{ fontWeight: showShadowLayer ? 'bold' : 'normal' }}>
              Enable shade simulation
            </Typography>
    )}
        />
        <FormHelperText>This enables the visualization of shade</FormHelperText>
      </Grid>
      {showShadowLayer && (
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
            <Box sx={{
              width: 20,
              height: 20,
              backgroundColor: 'hsl(0,75%,72%)',
              mr: 1,
              borderRadius: 10,
            }}
            />
            <Typography variant="body2">Shade</Typography>
            <Box sx={{
              width: 20,
              height: 20,
              backgroundColor: 'hsl(48,79%,74%)',
              mx: 1,
              borderRadius: 10,
            }}
            />
            <Typography variant="body2">Half shade</Typography>
            <Box sx={{
              width: 20,
              height: 20,
              backgroundColor: 'hsl(100,75%,72%)',
              mx: 1,
              borderRadius: 10,
            }}
            />
            <Typography variant="body2">Sun</Typography>
          </Box>
        </Grid>
      )}
    </Grid>
  );
}
