import React, { useEffect, useMemo, useState } from 'react';
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Accordion,
  AccordionSummary,
  Typography, AccordionDetails, Box,
} from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
import { Add, ExpandMore, Delete } from '@mui/icons-material';
import { SketchPicker } from 'react-color';
import useEntitiesStore, {
  ENTITY_CATEGORY,
  ENTITY_PLACE_TYPE,
  ENTITY_TYPE,
} from '../../../stores/entitiesStore';
import SliderWithButtons from '../../../components/SliderWithButtons';
import usePlantsStore from '../../../stores/plantsStore';
import ColorPicker from '../../../components/ColorPicker';
import useBalconyStore from '../../../stores/balconyStore';
import EditableCardSection from '../../EditableCardSection';
import KeyValueLabel from '../../../components/KeyValueLabel';
import useViewStore from '../../../stores/viewStore';
import ShadeCard from '../../ShadeGrade';
import AddNewEntitySelection from './AddNewEntitySelection';
import AddEntityButtonWithDialog from './AddEntityButtonWithDialog';
import { getPlantPositions } from '../../../utils/entityUtils';
import useIsMobile from '../../../hooks/useIsMobile';
import ReadyOnlyKeyValueWrapper from '../../../components/ReadyOnlyKeyValueWrapper';

function Entity({
  entity = null, editable, setEditable, renderAdditionalContentFunc = null, childrenEntities = [], attachedPlants = [],
}) {
  const [removeEntity, updateEntityPartly, updateEntityFieldWithChildren] = useEntitiesStore(useShallow((state) => [state.removeEntity, state.updateEntityPartly, state.updateEntityFieldWithChildren]));
  const [handleEntityWillBeRemoved, updatePlantPartly] = usePlantsStore(useShallow((state) => [state.handleEntityWillBeRemoved, state.updatePlantPartly]));
  const {
    sizeX, sizeZ,
  } = useBalconyStore();
  const [setFocusOnId, focusOnId] = useViewStore(useShallow((state) => [state.setFocusOnId, state.focusOnId]));
  const [plants] = usePlantsStore(useShallow((state) => [state.plants]));

  const additionalContent = renderAdditionalContentFunc ? useMemo(() => renderAdditionalContentFunc(entity, plants), [entity, plants]) : null;
  const containingEntities = [...attachedPlants];

  const processNewVariantHasLessPlantPositions = (oldEntity, newVariantId) => {
    const oldPlantPositions = getPlantPositions(oldEntity);
    if (oldPlantPositions.length === 0) {
      return;
    }
    const newPlantPositions = getPlantPositions(oldEntity, newVariantId);
    if (newPlantPositions.length >= oldPlantPositions.length) {
      return;
    }
    attachedPlants.forEach((plant) => {
      const plantPosition = plant.attachedIndexPosition;
      if (plantPosition >= newPlantPositions.length) {
        updatePlantPartly(plant.id, { attachedEntityId: undefined, attachedIndexPosition: undefined });
      }
    });
  };

  const handleEntityVariantChange = (event) => {
    processNewVariantHasLessPlantPositions(entity, event.target.value);
    updateEntityPartly(entity.id, { variant: event.target.value });
  };

  const onChangeEditable = (newEditable) => {
    setEditable(newEditable);
    if (newEditable) {
      setFocusOnId(`entity_${entity.id}`);
    } else {
      setFocusOnId(null);
    }
  };

  const deleteEntity = () => {
    if (focusOnId === `entity_${entity.id}`) {
      setFocusOnId(null);
    }
    if (entity.parentId) {
      setFocusOnId(`entity_${entity.parentId}`);
    }
    handleEntityWillBeRemoved(entity.id);
    removeEntity(entity.id);
  };

  const label = (
    entity.name
    // <>
    //   {entity.name}
    //   {' '}
    //   {(containingEntities?.length
    //     ? (
    //       <Typography variant="caption">
    //         {'   '}
    //         (
    //         {containingEntities.slice(0, 2).map((e) => e.name).join(', ')}
    //         {containingEntities.length > 2 ? ', ...' : ''}
    //         )
    //       </Typography>
    //     )
    //     : '')}
    // </>
  );

  const containLabel = (containingEntities?.length
    ? (
      <Typography variant="caption">
        {'   '}
        (
        {containingEntities.slice(0, 2).map((e) => e.name).join(', ')}
        {containingEntities.length > 2 ? ', ...' : ''}
        )
      </Typography>
    )
    : '');

  const checkClickedOnCanvasEntity = (clickedId) => {
    if (clickedId === `entity_${entity.id}`) {
      return true;
    }
    return false;
  };

  return (
    <EditableCardSection
      label={label}
      LabelIcon={ENTITY_TYPE[entity.type].Icon ?? null}
      middleLabel={containLabel}
      onChangeEditable={onChangeEditable}
      editable={editable}
      storeKey={`ENTITY_LIST_ITEM_${entity.id}`}
      checkClickedOnCanvasEntity={checkClickedOnCanvasEntity}
      renderChildrenFunction={(childEditable) => {
        if (!childEditable && !childrenEntities?.length) {
          return null;
        }

        return (
          <>
            {(childEditable ? (
              <Grid container rowSpacing={2}>
                {ENTITY_TYPE[entity.type].calculateShade && (
                <Grid item xs={12}>
                  <ShadeCard selectedObject={entity} />
                </Grid>
                )}
                <Grid item xs={12}>
                  <EditableCardSection
                    label="Position"
                    secondHierarchy
                    renderChildrenFunction={(positionEditable) => (
                      <>
                        {!ENTITY_TYPE[entity.type].disableAttachmentSelection && (
                          <ReadyOnlyKeyValueWrapper asGridItem readOnly={!positionEditable} label="Attached" value={ENTITY_PLACE_TYPE[entity.placeType].label}>
                            <FormControl fullWidth>
                              <InputLabel id="entity-attach-type">Attached</InputLabel>
                              <Select
                                label="Attached"
                                labelId="entity-attach-type"
                                id="entity-type-select"
                                value={entity.placeType}
                                onChange={(event) => updateEntityPartly(entity.id, { placeType: event.target.value })}
                                size="small"
                              >
                                {Object.values(ENTITY_PLACE_TYPE).map((type) => (
                                  <MenuItem
                                    key={type.name}
                                    value={type.name}
                                  >
                                    {type.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </ReadyOnlyKeyValueWrapper>
                        )}
                        {entity.placeType === ENTITY_PLACE_TYPE.FLOOR.name && (
                        <>
                          <SliderWithButtons
                            asGridItem
                            value={entity.positionX}
                            setValue={(value) => updateEntityFieldWithChildren(entity.id, 'positionX', value)} // , (childValue) => Math.max(0, Math.min(100, childValue))
                            min={0}
                            max={100}
                            label="Position X"
                            step={0.1}
                            transformValueToInputValue={(val) => parseFloat(sizeX * (val / 100)).toFixed(2)}
                            transformInputValueToValue={(val) => (val / sizeX) * 100}
                            readOnly={!positionEditable}
                            unitLabel="m"
                          />
                          {!ENTITY_TYPE[entity.type].disablePositionZ && (
                            <SliderWithButtons
                              asGridItem
                              value={entity.positionZ}
                              setValue={(value) => updateEntityFieldWithChildren(entity.id, 'positionZ', value)} // , (childValue) => Math.max(0, Math.min(100, childValue))
                              min={0}
                              max={100}
                              label="Position Z"
                              step={0.1}
                              transformValueToInputValue={(val) => parseFloat(sizeZ * (val / 100)).toFixed(2)}
                              transformInputValueToValue={(val) => (val / sizeZ) * 100}
                              readOnly={!positionEditable}
                              unitLabel="m"
                            />
                          )}
                        </>
                        )}
                        {entity.placeType === ENTITY_PLACE_TYPE.SIDEWALL.name && (
                          <SliderWithButtons
                            asGridItem
                            value={entity.positionX}
                            setValue={(value) => updateEntityPartly(entity.id, { positionX: value })}
                            min={0}
                            max={100}
                            label="Position"
                            step={0.1}
                            readOnly={!childEditable}
                            unitLabel="m"
                            hideInput
                          />
                        )}
                        {!ENTITY_TYPE[entity.type].disableRotation && (
                          <SliderWithButtons
                            asGridItem
                            value={entity.rotationY}
                            setValue={(value) => updateEntityFieldWithChildren(entity.id, 'rotationY', value, (childValue) => Math.abs(childValue % 360))}
                            min={0}
                            max={360}
                            label="Rotation"
                            step={10}
                            readOnly={!positionEditable}
                            marks={[
                              {
                                value: 90,
                                label: '90',
                              },
                              {
                                value: 180,
                                label: '180',
                              },
                              {
                                value: 270,
                                label: '270',
                              },

                            ]}
                          />
                        )}
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <EditableCardSection
                    label="Customization"
                    secondHierarchy
                    renderChildrenFunction={(customizationEditable) => (
                      <>
                        {ENTITY_TYPE[entity.type].variants?.length && (
                        <ReadyOnlyKeyValueWrapper asGridItem readOnly={!customizationEditable} label="Entity Type" value={ENTITY_TYPE[entity.type].variants.find((variant) => variant.id === entity.variant).name}>
                          <FormControl fullWidth>
                            <InputLabel id="entity-type-label">
                              Entity
                              Type
                            </InputLabel>
                            <Select
                              label="Entity Type"
                              labelId="entity-type-label"
                              id="entity-type-select"
                              value={ENTITY_TYPE[entity.type].variants.find((variant) => variant.id === entity.variant)?.id || ''}
                              onChange={handleEntityVariantChange}
                              size="small"
                            >
                              {ENTITY_TYPE[entity.type].variants.map((variant) => (
                                <MenuItem
                                  key={variant.id}
                                  value={variant.id}
                                >
                                  {variant.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </ReadyOnlyKeyValueWrapper>
                        )}

                        {ENTITY_TYPE[entity.type].nummericAttributes && ENTITY_TYPE[entity.type].nummericAttributes.map((nummericAttribute) => (
                          <SliderWithButtons
                            key={nummericAttribute?.id}
                            asGridItem
                            value={entity.nummericAttributes?.[nummericAttribute?.id] || ENTITY_TYPE[entity.type].nummericAttributes.find((attr) => attr.id === nummericAttribute.id).default}
                            setValue={(value) => updateEntityPartly(entity.id, {
                              nummericAttributes: {
                                ...entity.nummericAttributes,
                                [nummericAttribute.id]: value,
                              },
                            })}
                            min={nummericAttribute.min}
                            max={nummericAttribute.maxLimitByBalconySizes ? Math.min(Math.max(sizeX, sizeZ), nummericAttribute.max) : nummericAttribute.max}
                            label={nummericAttribute.name}
                            step={nummericAttribute.step}
                            readOnly={!customizationEditable}
                          />
                        ))}
                        {childEditable && ENTITY_TYPE[entity.type].colorAttributes && ENTITY_TYPE[entity.type].colorAttributes.map((colorAttributes) => (
                          <Grid item xs={6} key={colorAttributes?.id}>
                            <ColorPicker
                              value={entity.colorAttributes?.[colorAttributes?.id] || ENTITY_TYPE[entity.type].colorAttributes.find((attr) => attr.id === colorAttributes.id).default}
                              changeValue={(value) => updateEntityPartly(entity.id, {
                                colorAttributes: {
                                  ...entity.colorAttributes,
                                  [colorAttributes.id]: value.hex,
                                },
                              })}
                              label={colorAttributes.name}
                              editable={customizationEditable}
                            />
                          </Grid>
                        ))}
                      </>
                    )}
                  />
                </Grid>
                {childEditable && (
                <>
                  <Grid item xs={6} container justifyContent="flex-start">
                    <Button
                      onClick={deleteEntity}
                      color="error"
                      startIcon={<Delete />}
                    >
                      Delete
                    </Button>
                  </Grid>
                  {ENTITY_TYPE[entity.type].canAddChildren && (
                  <Grid item xs={6} container justifyContent="flex-end">
                    <AddEntityButtonWithDialog
                      buttonLabel="Add child"
                      addModifiers={{ parentId: entity.id }}
                      allowAddingAsChild
                    />
                  </Grid>
                  )}
                </>
                )}
                {additionalContent
                  ? <Grid item xs={12}>{additionalContent}</Grid> : null}
              </Grid>
            ) : null)}
            {childrenEntities?.length ? (
              <Grid container onClick={(e) => e.stopPropagation()}>
                <Grid item xs={12}>
                  <Box marginLeft={1} marginTop={1}>
                    <EntitiesList
                      allowedParentId={entity.id}
                      entities={childrenEntities}
                    />
                  </Box>
                </Grid>
              </Grid>
            ) : null}
          </>
        );
      }}
    />
  );
}

export default function EntitiesList({
  entities, openEditableEntityId, renderAdditionalContentFunc = null, allowedParentId = undefined, plants = [],
}) {
  const [editableEntityId, setEditableEntityId] = React.useState(null);
  const [setFocusOnId] = useViewStore(useShallow((state) => [state.setFocusOnId]));
  const isMobile = useIsMobile();

  useEffect(() => {
    setEditableEntityId(openEditableEntityId);
    if (openEditableEntityId) {
      setFocusOnId(`entity_${openEditableEntityId}`);
    }
  }, [openEditableEntityId]);

  return (
    <Grid container rowSpacing={isMobile ? 1 : 2}>
      {entities.filter((e) => e.parentId === allowedParentId).map((entity) => (
        <Grid item xs={12} key={entity.id}>
          <Entity
            entity={entity}
            editable={entity.id === editableEntityId}
            setEditable={() => setEditableEntityId(entity.id)}
            renderAdditionalContentFunc={renderAdditionalContentFunc}
            childrenEntities={entities.filter((e) => e.parentId === entity.id)}
            attachedPlants={plants.filter((p) => p.attachedEntityId === entity.id)}
          />
        </Grid>
      ))}
    </Grid>
  );
}
