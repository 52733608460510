import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import useCalculateOpacityForTopView from '../../hooks/useCalculateOpacityForTopView';

function Sunblind({
  width = 3,
  heightPosition = 3,
  maximalLength = 3,
  open = 1,
  tiltAngle = 0,
  colorTop,
  colorPole = 'black',
  positionZModified = false,
}) {
  const calculatedWidth = width;
  const calculatedLength = maximalLength * open;
  const tiltAngleRadians = Math.min(tiltAngle * 30, 30) * (Math.PI / 180);

  const topRef = useRef();
  const opacityForTopView = useCalculateOpacityForTopView(topRef);
  const [ceilOpacity, setCeilOpacity] = useState(false);
  useEffect(() => {
    setCeilOpacity(Math.max(0.05, opacityForTopView));
  }, [opacityForTopView]);

  const offsetZ = -calculatedLength / 2; // Rückseite bleibt an Z = 0

  return (
    <group
      position={[0, heightPosition, offsetZ]}
      userData={{ placedOnTop: true }}
    >
      <group rotation={[-tiltAngleRadians, 0, 0]} position={[0, 0, calculatedLength / 2]}>
        <mesh
          position={[0, 0, -calculatedLength / 2]}
          castShadow
          receiveShadow
          ref={topRef}
        >
          <boxGeometry args={[calculatedWidth, 0.02, calculatedLength]} />
          <meshStandardMaterial color={colorTop} transparent opacity={ceilOpacity} />
        </mesh>
        <mesh
          position={[0, -0.05, -calculatedLength]}
          castShadow
          receiveShadow
        >
          <boxGeometry args={[calculatedWidth, 0.1, 0.02]} />
          <meshStandardMaterial color={colorTop} transparent opacity={ceilOpacity} />
        </mesh>

      </group>
      {positionZModified && (
      <>
        <mesh
          position={[-calculatedWidth / 2, -heightPosition / 2, calculatedLength / 2]} // Left side
          castShadow
          receiveShadow
        >
          <boxGeometry args={[0.05, heightPosition, 0.05]} />
          <meshStandardMaterial color={colorPole} />
        </mesh>
        {/* Right pole */}
        <mesh
          position={[calculatedWidth / 2, -heightPosition / 2, calculatedLength / 2]} // Right side
          castShadow
          receiveShadow
        >
          <boxGeometry args={[0.05, heightPosition, 0.05]} />
          <meshStandardMaterial color={colorPole} />
        </mesh>
      </>
      )}
    </group>
  );
}

export default Sunblind;
