import React from 'react';
import { Box, CircularProgress, Fade } from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
import useScenarioStore from '../../stores/scenarioStore';

function LoadingOverlay() {
  const [loadedBuildings] = useScenarioStore(useShallow((state) => [state.loadedBuildings]));

  return (
    <Fade in={!loadedBuildings} timeout={300}>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1300, // Ensure it is above other elements
        }}
      >
        <CircularProgress />
      </Box>
    </Fade>
  );
}

export default LoadingOverlay;
