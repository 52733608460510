import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Accordion,
  AccordionSummary,
  Typography, AccordionDetails, ButtonGroup, Box, CardMedia,
} from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
import {
  Delete,
  DoubleArrow,
  ExpandMore,
  KeyboardDoubleArrowDown,
  Search,
} from '@mui/icons-material';
import dayjs from 'dayjs';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import useEntitiesStore, {
  ENTITY_TYPE,
} from '../../../stores/entitiesStore';
import SliderWithButtons from '../../../components/SliderWithButtons';
import ShadeCard from '../../ShadeGrade';
import usePlantsStore, { PLANT_TYPE } from '../../../stores/plantsStore';
import useUiStore from '../../../stores/uiStore';
import { PLANT_SPECIFICATION } from '../../../config/PlantSpecification';
import useShadeCalculation from '../../../hooks/useShadeCalculation';
import EditableCardSection from '../../EditableCardSection';
import KeyValueLabel from '../../../components/KeyValueLabel';
import useViewStore from '../../../stores/viewStore';
import { getPlantPositions } from '../../../utils/entityUtils';
import ColorPicker from '../../../components/ColorPicker';
import useIsMobile from '../../../hooks/useIsMobile';

function Plant({
  plant, entities, editable, setEditable,
}) {
  const [shade, setShade] = useState(null);
  const [removePlant, updatePlantPartly] = usePlantsStore(useShallow((state) => [state.removePlant, state.updatePlantPartly]));
  const [openPlantDetails, openedEditableSections] = useUiStore(useShallow((state) => [state.openPlantDetails, state.openedEditableSections]));
  const [setFocusOnId, focusOnId] = useViewStore(useShallow((state) => [state.setFocusOnId, state.focusOnId]));
  const shadeCalculation = useShadeCalculation();

  useEffect(() => {
    setShade(shadeCalculation.calculateShade(plant, [{
      date: dayjs('2023-04-29T12:00:00').toDate(),
    }, {
      date: dayjs('2023-06-15T12:00:00').toDate(),
    }, {
      date: dayjs('2023-09-01T12:00:00').toDate(),
    },
    ])?.totalShade);
  }, [plant, entities]);

  const handleFindPlant = () => {
    openPlantDetails(plant.id, 'FIND_PLANT');
  };

  const attachedEntity = entities.find((entity) => entity.id === plant.attachedEntityId);

  const changeIndexPosition = (position) => {
    updatePlantPartly(plant.id, { attachedIndexPosition: position.index });
    if (position.blockedByPlantId) {
      updatePlantPartly(position.blockedByPlantId, { attachedIndexPosition: plant.attachedIndexPosition });
    }
  };

  const deletePlant = () => {
    if (focusOnId === `entity_${plant.id}`) {
      setFocusOnId(null);
    }
    if (plant.attachedEntityId && openedEditableSections.includes(`ENTITY_LIST_ITEM_${plant.attachedEntityId}`)) {
      setFocusOnId(`entity_${plant.attachedEntityId}`);
    }
    removePlant(plant.id);
  };

  const onChangeEditable = (editable) => {
    setEditable(editable);
    if (editable && plant.attachedEntityId) {
      setFocusOnId(`entity_${plant.id}`);
    } else {
      setFocusOnId(null);
    }
  };

  useEffect(() => {
    if (editable && plant.attachedEntityId) {
      setFocusOnId(`entity_${plant.id}`);
    }
  }, [plant.attachedEntityId]);

  useEffect(() => {
    if (plant.specificationId !== null && PLANT_SPECIFICATION.find((ps) => ps.id === plant.specificationId) === null) {
      updatePlantPartly(plant.id, { specificationId: null });
    }
  }, [plant.specificationId]);

  const onChangeAttachedTo = (event) => {
    if (event.target.value !== 'free') {
      updatePlantPartly(plant.id, {
        attachedEntityId: event.target.value,
        attachedIndexPosition: entities.find((entity) => entity.id === event.target.value).nextAvailableAttachmentIndex,
      });
    } else {
      updatePlantPartly(plant.id, {
        attachedEntityId: null,
        attachedIndexPosition: null,
      });
      setFocusOnId(null);
    }
  };

  const specification = PLANT_SPECIFICATION.find((ps) => ps.id === plant.specificationId);

  if (plant.specificationId && specification === null) {
    return null;
  }

  const checkClickedOnCanvasEntity = (clickedId) => {
    if (clickedId === `entity_${plant.id}`) {
      return true;
    }
    return false;
  };

  function ImageComponent() {
    return (
      <Box
        component="img"
        src={specification.image}
        alt={specification.name}
        sx={{
          width: 28, // Fixed size for circle
          height: 28, // Match width for perfect circle
          borderRadius: '50%', // Makes it circular
          objectFit: 'cover', // Ensures image fills circle
          border: '0px solid',
          borderColor: 'primary.main',
          boxShadow: 2,
          mr: 2, // Add right margin for spacing
        }}
      />
    );
  }

  const attachedToName = attachedEntity?.name || '-';

  return (
    <EditableCardSection
      key={plant.name}
      label={plant.name}
      editable={editable}
      onChangeEditable={onChangeEditable}
      middleLabel={attachedToName}
      checkClickedOnCanvasEntity={checkClickedOnCanvasEntity}
      LabelIcon={ImageComponent}
      renderChildrenFunction={(editable) => (!editable ? null : (
        <Grid container rowSpacing={2} columnSpacing={1}>
          <Grid item xs={12}>
            <EditableCardSection
              label="Position"
              showLabelOnlyEditable
              secondHierarchy
              renderChildrenFunction={(positionEditable) => (
                <>
                  {positionEditable || !plant.attachedEntityId ? (
                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth>
                        <InputLabel id="plant-attach-type">
                          Attached
                          to
                        </InputLabel>
                        <Select
                          label="Attached to"
                          labelId="plant-attach-type"
                          id="plant-type-select"
                          value={plant.attachedEntityId || 'free'}
                          onChange={onChangeAttachedTo}
                          size="small"
                        >
                          <MenuItem key="free" value="free">
                            -
                            None -
                          </MenuItem>
                          {entities.map((freeEntity) => (
                            <MenuItem
                              key={freeEntity.name}
                              value={freeEntity.id}
                            >
                              {freeEntity.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={6}>
                      <KeyValueLabel
                        label="Attached to"
                        value={attachedEntity?.name || '-'}
                      />
                    </Grid>
                  )}
                  {positionEditable ? (
                    <>
                      {attachedEntity && attachedEntity.positions.length > 1 && (
                      <Grid
                        item
                        xs={12}
                        sm={attachedEntity.positions.length > 2 ? 12 : 6}
                        container
                        justifyContent="flex-end"
                      >
                        <ButtonGroup variant="text">
                          {attachedEntity.positions.map((position) => (
                            <Button
                              onClick={() => changeIndexPosition(position)}
                              variant={plant.attachedIndexPosition === position.index ? 'contained' : undefined}
                            >
                              {position.name}
                            </Button>
                          ))}
                        </ButtonGroup>
                      </Grid>
                      )}
                    </>
                  ) : (
                    <>
                      {attachedEntity?.positions?.length > 1 && (
                      <Grid item xs={6}>
                        <KeyValueLabel
                          label="Position"
                          value={attachedEntity.positions.find((position) => position.index === plant.attachedIndexPosition).name}
                        />
                      </Grid>
                      )}
                    </>
                  )}
                  {editable && attachedEntity && (
                  <Grid item xs={12}>
                    <ShadeCard selectedObject={plant} />
                  </Grid>
                  )}
                </>
              )}
            />
          </Grid>

          {/* {plant.specificationId && ( */}
          {/* <Grid item xs={12}> */}
          {/*  <Typography variant="body2"> */}
          {/*    {PLANT_SPECIFICATION.find((ps) => ps.id === plant.specificationId).name} */}
          {/*  </Typography> */}
          {/* </Grid> */}
          {/* )} */}
          <Grid item xs={12}>
            <EditableCardSection
              label="Customize"
              showLabelOnlyEditable
              secondHierarchy
              renderChildrenFunction={(customizeEditable) => (
                <>
                  <SliderWithButtons
                    readOnly={!customizeEditable}
                    value={plant.age}
                    setValue={(value) => updatePlantPartly(plant.id, { age: value })}
                    step={1}
                    min={0}
                    max={10}
                    label="Age"
                    asGridItem
                  />
                  {editable && PLANT_TYPE[plant.type].colorAttributes && PLANT_TYPE[plant.type].colorAttributes.map((colorAttributes) => (
                    <Box pt={3} key={colorAttributes?.id}>
                      <ColorPicker
                        value={plant.colorAttributes?.[colorAttributes?.id] || PLANT_TYPE[plant.type].colorAttributes.find((attr) => attr.id === colorAttributes.id).default}
                        changeValue={(value) => updatePlantPartly(plant.id, {
                          colorAttributes: {
                            ...plant.colorAttributes,
                            [colorAttributes.id]: value.hex,
                          },
                        })}
                        label={colorAttributes.name}
                        editable={editable}
                        readOnly={!customizeEditable}
                      />
                    </Box>
                  ))}
                </>
              )}
            />
          </Grid>
          <Grid item xs={8} container justifyContent="flex-start">
            <Button
              variant={plant.specificationId ? 'text' : 'contained'}
              onClick={handleFindPlant}
              startIcon={<Search />}
            >
              {plant.specificationId ? 'Change type' : 'Select type'}
            </Button>
          </Grid>
          <Grid item xs={4} container justifyContent="flex-end">
            <Button
              onClick={deletePlant}
              color="error"
              startIcon={<Delete />}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
      ))}
    />
  );
}

export default function PlantsList({ attachedToFilter = null }) {
  const [plants, updatePlantPartly] = usePlantsStore(useShallow((state) => [state.plants, state.updatePlantPartly]));
  const [entities] = useEntitiesStore(useShallow((state) => [state.entities]));
  const [setFocusOnId] = useViewStore(useShallow((state) => [state.setFocusOnId]));
  const isMobile = useIsMobile();

  const filteredPlants = useMemo(() => plants.filter((plant) => !attachedToFilter || plant.attachedEntityId === attachedToFilter.id), [plants, attachedToFilter]);
  const [editablePlantId, setEditablePlantId] = React.useState(null);

  useEffect(() => {
    const newlyCreatedPlant = plants.find((plant) => plant.newlyCreated);
    if (newlyCreatedPlant) {
      setEditablePlantId(newlyCreatedPlant.id);
      updatePlantPartly(newlyCreatedPlant.id, { newlyCreated: false });
      setFocusOnId(`entity_${newlyCreatedPlant.id}`);
    }
  }, [plants]);

  const getAttachedAndFreeEntities = useCallback(
    (plant) => entities
      .map((entity) => {
        const plantPositions = getPlantPositions(entity);
        if (!plantPositions?.length) {
          return null;
        }
        const attachedPlants = plants.filter((p) => p.attachedEntityId === entity.id);
        const usedIndices = attachedPlants.map((p) => p.attachedIndexPosition);

        const positions = [];
        let nextAvailableAttachmentIndex = -1;
        for (let i = 0; i < plantPositions.length; i++) {
          if (!usedIndices.includes(i)) {
            nextAvailableAttachmentIndex = i;
            positions.push(plantPositions[i]);
          } else {
            positions.push({
              ...plantPositions[i],
              blockedByPlantId: attachedPlants.find((p) => p.attachedIndexPosition === i).id,
            });
          }
        }

        const canAttach = nextAvailableAttachmentIndex > -1 || entity.id === plant.attachedEntityId;
        return canAttach ? {
          ...entity, nextAvailableAttachmentIndex, positions,
        } : null;
      })
      .filter(Boolean),
    [filteredPlants, entities],
  );

  const [showAll, setShowAll] = useState(false);

  // Determine whether to show "Show more" button
  const hasMoreThanTenEntries = filteredPlants.length > 10;
  const displayedPlants = showAll ? filteredPlants : filteredPlants.slice(0, 10);

  return (
    <Box position="relative">
      <Grid container rowSpacing={isMobile ? 1 : 1}>
        {displayedPlants.map((plant, index) => (
          <Grid item xs={12} key={plant.id}>
            <Plant
              plant={plant}
              entities={getAttachedAndFreeEntities(plant)}
              editable={plant.id === editablePlantId}
              setEditable={() => setEditablePlantId(plant.id)}
            />
            {!showAll && index === 9 && hasMoreThanTenEntries && (
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                height: '90px',
                background: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(249,251,231,1) 40%)',
                transition: 'opacity 0.3s ease',
              }}
            />
            )}
          </Grid>
        ))}
      </Grid>
      {hasMoreThanTenEntries && !showAll && (
        <Box display="flex" justifyContent="center" alignItems="center" mt={1}>
          <Button
            onClick={() => setShowAll(true)}
            variant="outlined"
            startIcon={<KeyboardDoubleArrowDown fontSize="large" size="large" />}
            sx={{
              backgroundColor: 'transparent',
            }}
          >
            <Typography variant="body1" fontSize="1.1rem">
              Show all plants
            </Typography>
          </Button>
        </Box>
      )}
    </Box>
  );
}
