import React, { useMemo } from 'react';
import {
  Dialog,
  IconButton,
  Box,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DialogContext from '@mui/material/Dialog/DialogContext';
import useUiStore, { OVERLAY_CONFIG } from '../../stores/uiStore';

export default function Overlay() {
  const [selectedOverlayConfig, closeOverlay, overlayData] = useUiStore((state) => [state.selectedOverlayConfig, state.closeOverlay, state.overlayData]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const ContentComponent = useMemo(() => (selectedOverlayConfig != null ? OVERLAY_CONFIG[selectedOverlayConfig].Component : null), [selectedOverlayConfig]);

  return (
    <Dialog
      open={!!selectedOverlayConfig}
      onClose={closeOverlay}
      fullScreen={isMobile}
      maxWidth={OVERLAY_CONFIG[selectedOverlayConfig]?.maxWidth || 'lg'}
      PaperProps={{
        elevation: 0,
        sx: {
          boxShadow: 'none',
        },
      }}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: 'white',
            boxShadow: 'none',
          },
        },
      }}
    >
      <DialogTitle sx={{
        marginX: 0, marginY: 2, paddingX: 1, paddingY: 1, backgroundColor: '#f1f8e9', borderRadius: '10px',
      }}
      >
        <Box width="100%">
          <Grid container alignItems="center">
            <Grid item xs={2} sm={1}>
              <IconButton edge="end" color="inherit" onClick={closeOverlay} aria-label="close">
                <CloseIcon sx={{ marginRight: 1 }} />
              </IconButton>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h5">
                {OVERLAY_CONFIG[selectedOverlayConfig] && OVERLAY_CONFIG[selectedOverlayConfig].name}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ paddingX: 1 }}>
        {/* <Box */}
        {/*  display="flex" */}
        {/*  alignItems="center" */}
        {/*  justifyContent="center" */}
        {/*  height="100%" */}
        {/* > */}
        <Box maxWidth="700px" width="100%" margin="auto">
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {ContentComponent ? <ContentComponent {...overlayData} /> : null}
        </Box>
        {/* </Box> */}
      </DialogContent>
    </Dialog>
  );
}
