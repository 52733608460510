import { useShallow } from 'zustand/react/shallow';
import React, { useState } from 'react';
import {
  Button, Divider, Grid, Typography,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import useEntitiesStore, {
  ENTITY_CATEGORY,
  ENTITY_TYPE,
} from '../../../stores/entitiesStore';
import AddNewEntitySelection from './AddNewEntitySelection';
import EntitiesList from './EntitiesList';
import usePlantsStore from '../../../stores/plantsStore';
import { getPlantPositions } from '../../../utils/entityUtils';
import AddNewPlantSection from './AddNewPlantSection';
import PlantsList from './PlantsList';

export default function PlantPotsContent() {
  const [entities, addEntity] = useEntitiesStore(useShallow((state) => [state.entities, state.addEntity]));
  const [plants] = usePlantsStore(useShallow((state) => [state.plants]));
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [lastAddedEntityId, setLastAddedEntityId] = useState(null);

  const validEntityCategories = [ENTITY_CATEGORY.PLANT_CONTAINER];

  const handleAddEntity = (entityType, variant) => {
    const id = Math.round(Math.random() * 100000);
    addEntity({ type: entityType.id, id, variant });
    setLastAddedEntityId(id);
  };

  const renderPlantList = (entity, plants) => {
    const getNextAttachIndexPosition = () => {
      const plantPositions = getPlantPositions(entity);
      if (!plantPositions.length) {
        return null;
      }
      const attachedPlants = plants.filter((p) => p.attachedEntityId === entity.id);
      const usedIndices = attachedPlants.map((p) => p.attachedIndexPosition);

      for (let i = 0; i < plantPositions.length; i++) {
        if (!usedIndices.includes(i)) {
          return i;
        }
      }
      return -1;
    };

    return (
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <Divider>
            <Typography variant="body2">
              Plants
            </Typography>
          </Divider>
        </Grid>
        {getNextAttachIndexPosition() > -1 && (
        <Grid item xs={12}>
          <AddNewPlantSection attachToEntity={entity} attachedIndexPosition={getNextAttachIndexPosition()} showAvailablePlantsSelection />
        </Grid>
        )}
        <Grid item xs={12}>
          <PlantsList attachedToFilter={entity} />
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} display="flex" justifyContent="center" mb={2}>
        <Button onClick={() => setAddDialogOpen(true)} variant="contained" startIcon={<Add />}>
          Add plant pot
        </Button>
        <AddNewEntitySelection
          handleAddEntity={handleAddEntity}
          entityCategories={validEntityCategories}
          open={addDialogOpen}
          handleClose={() => setAddDialogOpen(false)}
          title="Add plant pot"
        />
      </Grid>
      <Grid item xs={12}>
        <EntitiesList
          openEditableEntityId={lastAddedEntityId}
          entities={entities.filter((entity) => ENTITY_TYPE[entity.type].category === ENTITY_CATEGORY.PLANT_CONTAINER)}
          renderAdditionalContentFunc={renderPlantList}
          plants={plants}
        />
      </Grid>
    </Grid>
  );
}
