import React, { useCallback, useState } from 'react';
import {
  Box,
  Button, Divider, Grid, Typography,
} from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
import { Add } from '@mui/icons-material';
import AddNewEntitySection from './fragments/AddNewEntitySection';
import PlantsList from './fragments/PlantsList';
import AddNewPlantSection from './fragments/AddNewPlantSection';
import EntitiesList from './fragments/EntitiesList';
import useEntitiesStore, { ENTITY_CATEGORY, ENTITY_TYPE } from '../../stores/entitiesStore';
import AddNewEntitySelection from './fragments/AddNewEntitySelection';
import usePlantsStore from '../../stores/plantsStore';
import GrowingSimulationSection from './fragments/GrowingSimulationSection';
import { getPlantPositions } from '../../utils/entityUtils';

export default function PlantsContent() {
  return (
    <Grid container rowSpacing={1}>
      <Grid item xs={12} pt={1}>
        <AddNewPlantSection />
      </Grid>
      <Grid item xs={12} mt={1}>
        <PlantsList />
      </Grid>
    </Grid>
  );
}
