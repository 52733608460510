import * as THREE from 'three';

export const createShape = (building, forcedHeight = null) => {
  const shape = new THREE.Shape();
  const [firstCoord, ...coords] = building.coordinatesNormalized;
  shape.moveTo(firstCoord.x, -firstCoord.y);
  coords.forEach((coord) => shape.lineTo(coord.x, -coord.y));
  shape.lineTo(firstCoord.x, -firstCoord.y);

  const extrudeSettings = {
    steps: 1,
    depth: forcedHeight ?? building.height,
    bevelEnabled: false,
  };

  const newGeometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
  newGeometry.rotateX(-Math.PI / 2);
  newGeometry.normalizeNormals();
  newGeometry.computeVertexNormals();
  return newGeometry;
};

export const placeEntityOnParent = (balconyState, parentEntity, entity, entities) => {
  const balconyWidth = balconyState.sizeX; // absolute balcony width
  const balconyLength = balconyState.sizeZ; // absolute balcony length

  // Convert table position from relative to absolute
  const tableXAbs = (parentEntity.positionX / 100) * balconyWidth;
  const tableZAbs = (parentEntity.positionZ / 100) * balconyLength;

  // Table and chair dimensions are absolute
  const tableWidthAbs = parentEntity.nummericAttributes.WIDTH;
  const tableLengthAbs = parentEntity.nummericAttributes.LENGTH;
  const chairWidthAbs = entity.nummericAttributes.WIDTH;
  const chairLengthAbs = entity.nummericAttributes.LENGTH;

  // Convert parent's rotation to radians
  const parentRotationYRad = (parentEntity.rotationY * Math.PI) / 180;

  const canPlace = (xAbs, zAbs) => {
    const chairLeft = xAbs - chairWidthAbs / 2;
    const chairRight = xAbs + chairWidthAbs / 2;
    const chairTop = zAbs - chairLengthAbs / 2;
    const chairBottom = zAbs + chairLengthAbs / 2;
    return !(chairLeft < 0 || chairRight > balconyWidth || chairTop < 0 || chairBottom > balconyLength);
  };

  const computeFreeness = (xAbs, zAbs) => {
    let minDistance = Infinity;
    for (const e of entities) {
      if (e.id === parentEntity.id || e.id === entity.id) continue;
      const eXAbs = (e.positionX / 100) * balconyWidth;
      const eZAbs = (e.positionZ / 100) * balconyLength;
      const dist = Math.sqrt((xAbs - eXAbs) ** 2 + (zAbs - eZAbs) ** 2);
      if (dist < minDistance) minDistance = dist;
    }
    return minDistance === Infinity ? 999999 : minDistance;
  };

  // Candidate offsets relative to the table center before rotation
  const candidates = [
    {
      side: 'top',
      dx: 0,
      dz: -(tableLengthAbs / 2 + chairLengthAbs / 2),
      rotationY: 180,
    },
    {
      side: 'bottom',
      dx: 0,
      dz: tableLengthAbs / 2 + chairLengthAbs / 2,
      rotationY: 0,
    },
    {
      side: 'left',
      dx: -(tableWidthAbs / 2 + chairWidthAbs / 2),
      dz: 0,
      rotationY: 90,
    },
    {
      side: 'right',
      dx: tableWidthAbs / 2 + chairWidthAbs / 2,
      dz: 0,
      rotationY: 270,
    },
  ];

  // Apply parent rotation to candidates
  for (const c of candidates) {
    const rotatedX = c.dx * Math.cos(parentRotationYRad) - c.dz * Math.sin(parentRotationYRad);
    const rotatedZ = c.dx * Math.sin(parentRotationYRad) + c.dz * Math.cos(parentRotationYRad);
    c.xAbs = tableXAbs + rotatedX;
    c.zAbs = tableZAbs + rotatedZ;
  }

  let bestCandidate = null;
  let bestDistance = -Infinity;

  for (const c of candidates) {
    if (canPlace(c.xAbs, c.zAbs)) {
      const freeness = computeFreeness(c.xAbs, c.zAbs);
      if (freeness > bestDistance) {
        bestDistance = freeness;
        bestCandidate = c;
      }
    }
  }

  if (bestCandidate) {
    console.log('Best candidate:', bestCandidate);

    entity.positionX = (bestCandidate.xAbs / balconyWidth) * 100;
    entity.positionZ = (bestCandidate.zAbs / balconyLength) * 100;
    // Combine parent's rotation with candidate rotation
    entity.rotationY = (parentEntity.rotationY + bestCandidate.rotationY) % 360;
  } else {
    console.log('No suitable position found');
  }
};

export const applyRotationFromParentToChild = (balconyState, parentEntity, childEntity, diff) => {
  const balconyWidth = balconyState.sizeX;
  const balconyLength = balconyState.sizeZ;
  const parentXAbs = (parentEntity.positionX / 100) * balconyWidth;
  const parentZAbs = (parentEntity.positionZ / 100) * balconyLength;
  const diffRad = (diff * Math.PI) / 180;

  const newValue = childEntity.rotationY + diff;
  childEntity.rotationY = Math.abs(newValue % 360);

  // Rotate child's position around the parent's center
  const childXAbs = (childEntity.positionX / 100) * balconyWidth;
  const childZAbs = (childEntity.positionZ / 100) * balconyLength;

  const offsetX = childXAbs - parentXAbs;
  const offsetZ = childZAbs - parentZAbs;

  const rotatedX = offsetX * Math.cos(diffRad) - offsetZ * Math.sin(diffRad);
  const rotatedZ = offsetX * Math.sin(diffRad) + offsetZ * Math.cos(diffRad);

  const newChildXAbs = parentXAbs + rotatedX;
  const newChildZAbs = parentZAbs + rotatedZ;

  childEntity.positionX = (newChildXAbs / balconyWidth) * 100;
  childEntity.positionZ = (newChildZAbs / balconyLength) * 100;
};
