import React from 'react';
import {
  Grid, Button,
  Box, Typography, FormControlLabel, Checkbox,
  FormHelperText,
} from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers';
import usePlantsStore from '../../../stores/plantsStore';
import CustomDateTimePicker from '../../DateTimePicker';
import useScenarioStore from '../../../stores/scenarioStore';

export default function GrowingSimulationSection() {
  const [
    growingSimulationEnabled,
    setGrowingSimulationEnabled,
  ] = usePlantsStore(
    useShallow((state) => [
      state.growingSimulationEnabled,
      state.setGrowingSimulationEnabled,
    ]),
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <FormControlLabel
          control={(
            <Checkbox
              checked={growingSimulationEnabled}
              onChange={(event) => setGrowingSimulationEnabled(event.target.checked)}
            />
    )}
          label={(
            <Typography variant="body1" sx={{ fontWeight: growingSimulationEnabled ? 'bold' : 'normal' }}>
              Enabled Growing Simulation
            </Typography>
    )}
        />
        <FormHelperText>This enables the blooming of flowers and simulates plant growth</FormHelperText>
      </Grid>
    </Grid>
  );
}
