import React, { useState } from 'react';
import { Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useShallow } from 'zustand/react/shallow';
import AddNewEntitySelection from './AddNewEntitySelection';
import useEntitiesStore, { ENTITY_CATEGORY, ENTITY_TYPE } from '../../../stores/entitiesStore';

export default function AddEntityButtonWithDialog({
  buttonLabel = 'Add Furniture', addEntityCallback = () => {}, addModifiers = {}, allowAddingAsChild = false,
}) {
  const [entities, addEntity] = useEntitiesStore(useShallow((state) => [state.entities, state.addEntity]));
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const validCategories = Object.keys(ENTITY_CATEGORY)
    .filter((category) => ENTITY_CATEGORY[category].id !== ENTITY_CATEGORY.PLANT_CONTAINER.id)
    .map((category) => ENTITY_CATEGORY[category]);

  const handleAddEntity = (entityType, variant) => {
    const id = Math.round(Math.random() * 100000);
    addEntity({
      type: entityType.id, id, variant, ...addModifiers,
    });
    addEntityCallback(id);
    setAddDialogOpen(false);
  };

  return (
    <>
      <Button onClick={() => setAddDialogOpen(true)} variant="contained" startIcon={<Add />}>
        {buttonLabel}
      </Button>
      <AddNewEntitySelection
        handleAddEntity={handleAddEntity}
        entityCategories={validCategories}
        open={addDialogOpen}
        handleClose={() => setAddDialogOpen(false)}
        allowAddingAsChild={allowAddingAsChild}
        title="Add furniture"
      />
    </>
  );
}
