import React, { useEffect, useState } from 'react';
import {
  Box, Button, Card, CardContent, Divider, Grid, Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeIcon from '@mui/icons-material/Home';
import { ArrowForward, Send } from '@mui/icons-material';
import { useShallow } from 'zustand/react/shallow';
import CustomDateTimePicker from '../DateTimePicker';
import AddressAutocomplete from '../AddressAutocomplete';
import useUiStore, { SELECTION_MODE } from '../../stores/uiStore';
import useScenarioStore from '../../stores/scenarioStore';
import SourroundingHeightChanger from './fragments/SourroundingHeightChanger';
import useViewStore from '../../stores/viewStore';
import { getBoundingBox } from '../../utils/geoCalculationUtils';

export default function BuildingSelectionModeContent() {
  const {
    selectedBuilding, temporarySelectedBuilding, setSelectedBuilding, setTemporarySelectedBuilding, setLoadedBuildings,
  } = useScenarioStore();
  const {
    selectedPlace, setSelectedPlace, selectionMode, setSelectionMode,
  } = useUiStore();
  const [setFocusOnId] = useViewStore(useShallow((state) => [state.setFocusOnId]));
  const [autocompleteValue, setAutoCompleteValue] = useState(selectedPlace);

  useEffect(() => {
    if (!temporarySelectedBuilding) {
      setTemporarySelectedBuilding(selectedBuilding);
    }
  }, []);

  const onSelectedPlaceChange = async () => {
    if (!autocompleteValue) return;

    const service = new window.google.maps.places.PlacesService(document.createElement('div'));
    service.getDetails({
      placeId: autocompleteValue.place_id,
      fields: ['geometry'],
    }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const lat = place.geometry.location.lat();
        const lon = place.geometry.location.lng();
        const boundingBox = getBoundingBox(lat, lon, 0.4);
        setSelectedBuilding(null);
        setTemporarySelectedBuilding(null);
        setLoadedBuildings(false);
        setFocusOnId(null);
        setSelectedPlace({
          ...autocompleteValue,
          boundingBox,
          lat,
          lon,
        });
      }
    });
  };

  return (
    <Grid container spacing={4} paddingX={1}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">1. Choose address of balcony</Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <AddressAutocomplete value={autocompleteValue} onSetValue={setAutoCompleteValue} />
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="contained" onClick={onSelectedPlaceChange} disabled={!autocompleteValue}>
                      Update address
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">2. Select building</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption">
                  To select the building on which your balcony is attached, click on the wanted building at the 3d view.
                  <br />
                  Light yellow building visualizes your selection.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {temporarySelectedBuilding && (
                <>
                  <Typography variant="caption" component="div">
                    Selection
                  </Typography>
                  <Typography variant="body2" fontWeight="bold">
                    {(`${temporarySelectedBuilding.properties.name || ''}`).trim()}
                  </Typography>
                  <Typography variant="body2" fontWeight="bold">
                    {(`${temporarySelectedBuilding.properties['addr:street'] || ''} ${temporarySelectedBuilding.properties['addr:housenumber'] || ''}`).trim()}
                  </Typography>
                  <Typography variant="body2">
                    {(`${temporarySelectedBuilding.properties['addr:city'] || ''} ${temporarySelectedBuilding.properties['addr:postcode'] || ''}`).trim()}
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <SourroundingHeightChanger osmElement={temporarySelectedBuilding} />
                </>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between" my={3}>
              <Button
                startIcon={<ArrowBackIcon />}
                variant="outlined"
                color="error"
                onClick={() => { setSelectionMode(null); setFocusOnId(null); setTemporarySelectedBuilding(null); }}
              >
                Cancel
              </Button>
              <Button
                startIcon={<ArrowForward />}
                variant="contained"
                color="primary"
                onClick={() => {
                  setSelectedBuilding(temporarySelectedBuilding);
                  setSelectionMode(SELECTION_MODE.BALCONY_POSITION);
                  setFocusOnId(temporarySelectedBuilding.id);
                  setTemporarySelectedBuilding(null);
                }}
              >
                Final step
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
