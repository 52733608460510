import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Button,
  Divider,
  Collapse, IconButton, Box,
} from '@mui/material';
import { CalendarToday, ExpandMore } from '@mui/icons-material';
import { useShallow } from 'zustand/react/shallow';
import dayjs from 'dayjs';
import {
  Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import usePlantsStore from '../../stores/plantsStore';
import { PLANT_SPECIFICATION } from '../../config/PlantSpecification';
import useShadeCalculation, {
  SHADE_CALCULATION_RANGES,
} from '../../hooks/useShadeCalculation';
import {
  getSunFactor,
  SUN_ATTRIBUTE,
  WATER_NEED_ATTRIBUTE,
} from '../../utils/plantAttributeUtils';
import useScenarioStore from '../../stores/scenarioStore';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

function ShadeChart({ measures }) {
  // Data transformation for the chart
  const timeLabels = measures.map((measure) => `${measure.hour} h`);
  const intensityData = measures.map((measure) => measure.intensity);
  const backgroundColors = measures.map((measure) => (measure.shade < 0.5 ? 'yellow' : 'green'));

  // Chart data
  const data = {
    labels: timeLabels,
    datasets: [
      {
        label: 'Intensity',
        data: intensityData,
        backgroundColor: backgroundColors,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Time (h)',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Intensity',
        },
        suggestedMin: 0,
        suggestedMax: 1, // Intensity values are between 0 and 1
      },
    },
  };

  return (
    <Box sx={{ width: '100%', height: '150px' }}>
      <Bar data={data} options={options} />
    </Box>
  );
}
export default function ShadeDetailsPage({ entityId }) {
  const shadeCalculation = useShadeCalculation();
  const { selectedDateTime, setSelectedDateTime } = useScenarioStore();

  const [currentDate, setCurrentDate] = useState(dayjs(selectedDateTime));
  const currentShade = useMemo(
    () => shadeCalculation.calculateShade({ id: entityId }, [{ date: currentDate }]),
    [entityId, currentDate],
  );

  const mapShade = useCallback((shade) => {
    if (shade < 0.3) {
      return 'Sunny';
    }
    if (shade < 0.75) {
      return 'Partly sunny';
    }
    return 'Shady';
  }, [entityId]);

  const mergeTimeLabels = (measures) => {
    if (measures.length === 0) return [];

    const mergedMeasures = [];
    let currentMeasure = { start: measures[0].hour, end: measures[0].hour, shade: (measures[0].shade > 0.5) };

    for (let i = 1; i < measures.length; i++) {
      const currentHour = measures[i].hour;

      if ((measures[i].shade > 0.5) === currentMeasure.shade && currentHour === currentMeasure.end + 1) {
        currentMeasure.end = currentHour;
      } else {
        mergedMeasures.push(currentMeasure);
        currentMeasure = { start: currentHour, end: currentHour, shade: (measures[i].shade > 0.5) };
      }
    }

    mergedMeasures.push(currentMeasure);
    return mergedMeasures;
  };

  return (
    <Box sx={{
      backgroundColor: '#f1f8e9', paddingX: 1, paddingY: 1, borderRadius: 1,
    }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            <Box display="flex" alignItems="center">
              <CalendarToday fontSize="small" />
              <Typography variant="body1" component="span" marginLeft={1}>
                Month:
              </Typography>
              <Typography variant="body1" component="span" marginLeft={1} fontWeight="bold">
                {dayjs(currentDate).format('MMMM')}
              </Typography>
            </Box>
          </Typography>
          <Box display="flex" justifyContent="start" alignItems="start">
            <Button size="small" onClick={() => setCurrentDate(dayjs('2023-02-15T12:00:00').toDate())}>
              February
            </Button>
            <Button size="small" onClick={() => setCurrentDate(dayjs('2023-04-15T12:00:00').toDate())}>
              April
            </Button>
            <Button size="small" onClick={() => setCurrentDate(dayjs('2023-06-15T12:00:00').toDate())}>
              June
            </Button>
            <Button size="small" onClick={() => setCurrentDate(dayjs('2023-09-15T12:00:00').toDate())}>
              September
            </Button>
            <Button size="small" onClick={() => setCurrentDate(dayjs('2023-11-15T12:00:00').toDate())}>
              November
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom component="div">
            Total sun strength:
            {' '}
            <b>
              {currentShade?.totalShade != null ? ((1.0 - currentShade.totalShade) * 100).toFixed(0) : 0}
              {' %'}
            </b>
          </Typography>
          <Typography variant="caption" component="div">
            Considering the sun intensity and the shade, the total sun strength is calculated as the average of the sun strength at each hour.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom component="div">
            Sun:
            {' '}
            <b>
              {mergeTimeLabels(currentShade?.measures || []).reduce((acc, measure) => acc + (measure.shade < 0.3 ? (measure.end + 1 - measure.start) : 0), 0)}
              {' hours '}
            </b>
            <Typography variant="caption" component="span">
              {' / '}
              {currentShade?.measures?.length}
              {' hours'}
            </Typography>
          </Typography>
          <Typography variant="subtitle1" component="div">
            <Grid container>
              <Grid item xs={12} mb={3}>
                <ShadeChart measures={currentShade?.measures || []} />
              </Grid>
              {mergeTimeLabels(currentShade?.measures || []).map((measure) => (
                <>
                  <Grid item xs={4}>
                    {measure.start === measure.end
                      ? `${measure.start.toFixed(0)} h: `
                      : `${measure.start.toFixed(0)} h - ${measure.end.toFixed(0)} h: `}
                  </Grid>
                  <Grid item xs={8}>
                    {mapShade(measure.shade)}
                  </Grid>
                </>
              ))}
            </Grid>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
