import React, { memo, useState, useEffect } from 'react';
import {
  AppBar,
  Box,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  useMediaQuery,
  useTheme,
  Fab, ThemeProvider, CssBaseline, createTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ZoomIn, ZoomOut } from '@mui/icons-material';
import { useShallow } from 'zustand/react/shallow';
import Scenario from './fragments/Scenario';
import Sidebar from './fragments/sidebar/Sidebar';
import { ThreeProvider } from './context/ThreeProvider';
import Overlay from './fragments/overlay/Overlay';
import DateTimeSlider from './fragments/DateTimeSlider';
import useUiStore, { VIEW_EVENTS } from './stores/uiStore';
import useScenarioStore from './stores/scenarioStore';
import LoadingOverlay from './fragments/overlay/LoadingOverlay';
import CameraModeBar from './fragments/sidebar/CameraModeBar';

const SidebarComponent = memo(() => <Sidebar />);
const ScenarioComponent = memo(() => <Scenario />);

const theme = createTheme({
  typography: {
    fontFamily: [
      'Montserrat',
      'Roboto',
      'Arial',
      'sans-serif',
    ].join(','),
    // Customize specific text variants
    h1: {
      fontSize: '2.5rem',
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 500,
      lineHeight: 1.3,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    button: {
      fontWeight: 400,
      fontSize: '0.93rem',
      textTransform: 'none', // To remove uppercase transformation
    },
  },
  palette: {
    primary: {
      main: '#8CAA62',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#FFC107',
      contrastText: '#FFFFFF',
    },
    background: {
      default: '#F9FBE7',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#2E7D32',
      secondary: '#6D4C41',
    },
    error: {
      main: '#757d2e',
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          backgroundImage: 'none',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          overflow: 'hidden',
          background: '#F1F8E9',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: 'linear-gradient(145deg, #81C784, #4CAF50)',
          color: 'white',
          boxShadow: 'none',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          background: '#F1F8E9',
          boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)',
          border: '1px solid #A5D6A7',
          '&:hover': {
            borderColor: '#66BB6A',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          background: 'linear-gradient(145deg, #A5D6A7, #66BB6A)',
          borderRadius: '8px',
          color: '#FFFFFF',
          padding: '4px 8px',
          fontWeight: 600,
          '& .MuiChip-deleteIcon': {
            color: '#FFFFFF',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        text: {
          color: '#2b331f',
        },
        outlined: {
          color: '#2b331f',
        },
      },
    },
  },
});

export default function App() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isFullscreen, setIsFullscreen] = useUiStore(useShallow((state) => [state.isFullscreen, state.setIsFullscreen]));

  const handleFullscreenToggle = () => {
    setIsFullscreen(!isFullscreen);
  };

  useEffect(() => {
    function setVhVariable() {
      // 1vh = 1% of window.innerHeight
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    // Set on load
    setVhVariable();

    // Update whenever the window resizes
    window.addEventListener('resize', setVhVariable);
    return () => window.removeEventListener('resize', setVhVariable);
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box className="FullHeightContainer ">
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                p: 0,
                marginLeft: drawerOpen ? '250px' : 0,
                pointerEvents: drawerOpen ? 'none' : 'auto',
              }}
              onClick={(e) => {
                if (drawerOpen) {
                  setDrawerOpen(false);
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
            >
              <Grid container>
                <Grid item xs={12} sm={12} md={8} xl={9}>
                  <Box height={isMobile ? (isFullscreen ? '60vh' : '35vh') : '100vh'} id="threejs-div">
                    {/* {(!isMobile || isFullscreen) && ( */}
                    {/*  <Box height="50px"> */}
                    {/*    <DateTimeSlider /> */}
                    {/*  </Box> */}
                    {/* )} */}
                    <ScenarioComponent />

                    {isMobile && (
                    <Fab
                      color="primary"
                      size={isFullscreen ? 'large' : 'small'}
                      style={{
                        position: 'absolute',
                        // bottom: isFullscreen ? '45vh' : undefined,
                        top: isFullscreen ? '0.5vh' : '0.5vh',
                        left: '3vw',
                      }}
                      onClick={handleFullscreenToggle}
                    >
                      {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                    </Fab>
                    )}
                    <Box
                      style={{
                        position: 'absolute',
                        // bottom: isFullscreen ? '1vh' : undefined,
                        top: isFullscreen ? '1vh' : '1vh',
                        right: isMobile ? '3vw' : undefined,
                        left: isMobile ? undefined : '1vw',
                        backgroundColor: 'rgba(255, 255, 255, 0.5)',
                        borderRadius: '8px',
                      }}
                    >
                      <CameraModeBar size={isMobile ? (isFullscreen ? 'medium' : 'small') : 'large'} />
                    </Box>

                    {/* <Fab TODO: DOESNT WORK :( RERENDERS CAMERA CONTROL */}
                    {/*  color="primary" */}
                    {/*  size="small" */}
                    {/*  style={{ position: 'absolute', bottom: isFullscreen ? '22vh' : '76vh', right: '5vw' }} */}
                    {/*  onClick={() => addNewEvent(VIEW_EVENTS.ZOOM_IN)} */}
                    {/* > */}
                    {/*  <ZoomIn /> */}
                    {/* </Fab> */}
                    {/* <Fab */}
                    {/*  color="primary" */}
                    {/*  size="small" */}
                    {/*  style={{ position: 'absolute', bottom: isFullscreen ? '15vh' : '69vh', right: '5vw' }} */}
                    {/*  onClick={() => addNewEvent(VIEW_EVENTS.ZOOM_OUT)} */}
                    {/* > */}
                    {/*  <ZoomOut /> */}
                    {/* </Fab> */}

                    {/* {isMobile && isFullscreen && ( */}
                    {/* <Box height="50px"> */}
                    {/*  <DateTimeSlider /> */}
                    {/* </Box> */}
                    {/* )} */}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} xl={3}>
                  <Box height={isMobile ? (isFullscreen ? '40vh' : 'calc(65vh)') : 'calc(100vh)'} overflow="auto">
                    <SidebarComponent />
                  </Box>
                </Grid>
                <Overlay />
                <LoadingOverlay />
              </Grid>
            </Box>
          </Box>
        </LocalizationProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}
