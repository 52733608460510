import React, {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import {
  Box, Grid, Slider, Typography, Button, TextField, InputAdornment,
} from '@mui/material';
import { debounce } from 'lodash';
import KeyValueLabel from './KeyValueLabel';

export default function SliderWithButtons({
  value, setValue, min, max, step, label, disabled = false,
  hideSlider = false, hideInput = false, transformInputValueToValue = null, transformValueToInputValue = null,
  readOnly = false, unitLabel = null, marks = null,
  asGridItem = false,
}) {
  const debouncedSetValue = useCallback(debounce(setValue, 100), []);

  const [internValue, setInternValue] = React.useState(value);
  const [editing, setEditing] = React.useState(true);

  useEffect(() => {
    debouncedSetValue.current = debounce(setValue, 100);
  }, [setValue]);

  useEffect(() => {
    debouncedSetValue.current(internValue);
  }, [internValue]);

  useEffect(() => {
    if (internValue !== value) {
      setInternValue(value);
    }
  }, [value]);

  const roundToStep = (val) => {
    const rounded = Math.round(val / step) * step;
    return parseFloat(rounded.toFixed(3));
  };

  const handleSliderChange = (event, newValue) => {
    setInternValue(roundToStep(newValue));
  };

  const increaseValue = () => {
    if (value < max) {
      setInternValue(roundToStep(value + step));
    }
  };

  const decreaseValue = () => {
    if (value > min) {
      setInternValue(roundToStep(value - step));
    }
  };

  const handleInputChange = (event) => {
    setInternValue(event.target.value === '' ? '' : roundToStep(Number(transformInputValueToValue ? transformInputValueToValue(event.target.value) : event.target.value)));
  };

  const Container = useMemo(() => (asGridItem ? ({ children }) => (<Grid item xs={readOnly || !editing ? 6 : 12}>{children}</Grid>) : ({ children }) => children), [asGridItem, readOnly, editing]);
  return (
    <Container>
      <Grid container spacing={2} alignItems="center" sx={{ height: '62px' }}>
        {readOnly || !editing
          ? (
            <Grid item xs={12} paddingBottom={0}>
              <KeyValueLabel
                label={label}
                value={(transformValueToInputValue ? transformValueToInputValue(internValue) : internValue) + (unitLabel ? `${unitLabel}` : '')}
              />
            </Grid>
          )
          : (
            <>
              {!hideInput && (
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    value={transformValueToInputValue ? transformValueToInputValue(internValue) : internValue}
                    onChange={handleInputChange}
                    size="small"
                    variant="outlined"
                    label={label}
                    inputProps={{
                      step,
                      min,
                      max,
                      type: 'number',
                      'aria-labelledby': `${label}-input`,
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{unitLabel}</InputAdornment>,
                    }}
                    disabled={disabled}
                  />
                </Grid>
              )}
              <Grid item xs={hideInput ? 12 : 8}>
                {hideInput && (
                  <Typography variant="caption" display="block" gutterBottom sx={{ marginBottom: '-3px' }}>
                    {label}
                  </Typography>
                )}
                {!hideSlider && (
                  <Slider
                    value={internValue}
                    onChange={handleSliderChange}
                    aria-labelledby={`${label}-slider`}
                    min={min}
                    max={max}
                    valueLabelDisplay="off"
                    step={step}
                    disabled={disabled}
                    marks={marks}
                  />
                )}
              </Grid>
            </>
          )}
      </Grid>
    </Container>
  );
}
