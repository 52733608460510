import { CameraControls, OrbitControls, TransformControls } from '@react-three/drei';
import { Canvas, useThree } from '@react-three/fiber';
import React, {
  memo,
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { Color, Vector3 } from 'three';
import { useShallow } from 'zustand/react/shallow';
import { Box } from '@mui/material';
import {
  Bloom, ChromaticAberration,
  DepthOfField,
  DotScreen,
  EffectComposer,
  Glitch,
  LensFlare,
  Noise,
  Outline,
  Select,
  Selection, SSAO, ToneMapping, Vignette,
} from '@react-three/postprocessing';
import {
  BlendFunction, Resizer, KernelSize, GlitchMode,
} from 'postprocessing';
import { Quaternion } from 'three/src/math/Quaternion';
import Wall from '../components/3d/Wall';
import Table from '../components/3d/Table';
import { ThreeContext } from '../context/ThreeContext';
import Draggable from '../components/3d/Draggable';
import useScenarioStore from '../stores/scenarioStore';
import Sunlights from '../components/3d/Sunlights';
import Sky from '../components/3d/Sky';
import Sourrounding from './3d/Sourrounding';
import Balcony from './3d/Balcony';
import useUiStore from '../stores/uiStore';
import useSelectionModeClick from '../hooks/useSelectionModeClick';
import UiHelper from './UiHelper';
import CameraControl from './CameraControl';
import useViewStore, { CAMERA_MODE } from '../stores/viewStore';
import ExternSky from '../components/3d/ExternSky';
import Sidebar from './sidebar/Sidebar';
import { ThreeProvider } from '../context/ThreeProvider';
import ShadowVisualizer from '../components/3d/ShadowVisualizer';
import EntityTransformControl from './3d/EntityTransformControl';

export default function Scenario() {
  const { camera, scene } = useContext(ThreeContext);

  const [selectedPlace, selectionMode] = useUiStore(useShallow((state) => [state.selectedPlace, state.selectionMode]));
  const [focusOnId, cameraMode] = useViewStore(
    useShallow((state) => [
      state.focusOnId, state.cameraMode]),
  );

  const [boundingBox, setBoundingBox] = useState(
    selectedPlace ? selectedPlace.boundingBox : {
      min: { lon: 13.40279740093464, lat: 52.52075191523308 },
      max: { lon: 13.412137037513347, lat: 52.52754505600548 },
    },
  );

  const center = useMemo(() => (boundingBox ? {
    lat: (boundingBox.min.lat + boundingBox.max.lat) / 2,
    lon: (boundingBox.min.lon + boundingBox.max.lon) / 2,
  } : { lat: 0, lon: 0 }), [boundingBox]);

  useEffect(() => {
    if (selectedPlace) {
      setBoundingBox(selectedPlace.boundingBox);
    }
  }, [selectedPlace]);

  const [focusEntity, setFocusEntity] = useState(null);

  useEffect(() => {
    if (focusOnId) {
      const entity = scene.getObjectByName(focusOnId);
      if (entity) {
        setFocusEntity(entity);
      } else {
        setFocusEntity(scene.getObjectByName(`entity_${focusOnId}`));
      }
    } else {
      setFocusEntity(null);
    }
  }, [focusOnId]);

  return (
    <Canvas shadows key="canvas" camera={camera} scene={scene}>
      <UiHelper />
      <CameraControl camera={camera} scene={scene} />
      <ExternSky latitude={center.lat} longitude={center.lon} />
      <Sunlights latitude={center.lat} longitude={center.lon} />
      {boundingBox && (<Sourrounding boundingBox={boundingBox} />)}
      {/* <Selection> */}
      {/*  <EffectComposer autoClear={false}> */}
      {/*    <Outline blur edgeStrength={100} pulseSpeed={100} visibleEdgeColor="red" /> */}
      {/*  </EffectComposer> */}
      {/*  <Select enabled> */}
      <EntityTransformControl scene={scene} />
      <Selection>
        <EffectComposer autoClear={false}>
          {!focusEntity && (
          <Outline
            blur
            kernelSize={KernelSize.VERY_SMALL}
            edgeStrength={3}
            visibleEdgeColor="black"
            hiddenEdgeColor="black"
            blendFunction={BlendFunction.ALPHA}
            xRay
          />
          )}
          {focusEntity && (
          <Outline
            blur
            kernelSize={KernelSize.VERY_SMALL}
            edgeStrength={44}
            visibleEdgeColor="red"
            hiddenEdgeColor="red"
            pulseSpeed={0.15}
            blendFunction={BlendFunction.ALPHA}
            xRay
            selection={focusEntity}
          />
          )}
          <Select enabled={false}>
            <Balcony />
          </Select>
          {!selectionMode && cameraMode !== CAMERA_MODE.TOP_VIEW && (
            <DepthOfField
                    // blendFunction={BlendFunction.LIGHTEN}
              worldFocusDistance={15}
              worldFocusRange={190}
                    // focusLength={0.01}
              bokehScale={6}
                    // resolutionScale={2.3}
              width={256}
              height={256}
            />
          )}
        </EffectComposer>
      </Selection>

      {/*  </Select> */}
      {/* </Selection> */}
      {/* <EffectComposer> */}
      {/*  <Glitch */}
      {/*    delay={[0.5, 0.5]} // min and max glitch delay */}
      {/*    duration={[0.6, 1.0]} // min and max glitch duration */}
      {/*    strength={[0.04, 0.05]} // min and max glitch strength */}
      {/*    mode={GlitchMode.CONSTANT_WILD} // glitch mode */}
      {/*    active // turn on/off the effect (switches between "mode" prop and GlitchMode.DISABLED) */}
      {/*    ratio={0.85} */}
      {/*  /> */}
      {/* </EffectComposer> */}
      {/* <DepthOfField focusDistance={1} focalLength={1.09} bokehScale={2} height={320} /> */}

      {/* <DepthOfField */}
      {/*  focusDistance={1} // where to focus */}
      {/*  focalLength={0.02} // focal length */}
      {/*  bokehScale={1.5} */}
      {/*  height={768} */}
      {/* /> */}

      {/* {focusEntity && ( */}
      {/* <> */}
      {/*  <Outline blur edgeStrength={100} pulseSpeed={100} visibleEdgeColor="red" selection={focusEntity} /> */}
      {/*  /!* <DepthOfField *!/ */}
      {/*  /!*  blendFunction={BlendFunction.NORMAL} *!/ */}
      {/*  /!*  worldFocusDistance={8} *!/ */}
      {/*  /!*  worldFocusRange={25} *!/ */}
      {/*  /!*  bokehScale={1.3} *!/ */}
      {/*  /!*  resolutionScale={1.0} *!/ */}
      {/*  /!*  height={256} *!/ */}
      {/*  /!* /> *!/ */}
      {/* </> */}
      {/* )} */}
      {/* <DotScreen */}
      {/*  blendFunction={BlendFunction.NORMAL} // blend mode */}
      {/*  angle={Math.PI * 0.5} // angle of the dot pattern */}
      {/*  scale={0.2} */}
      {/* /> */}
      {/* <Bloom luminanceThreshold={0} luminanceSmoothing={0.9} height={300} /> */}

    </Canvas>
  );
}
