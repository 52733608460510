import React, { useMemo, useState } from 'react';
import {
  Grid, Select, MenuItem, FormControl, InputLabel, Button,
} from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
import { Add } from '@mui/icons-material';
import usePlantsStore, { PLANT_TYPE } from '../../../stores/plantsStore';
import AddPlantButtonWithDialog from './AddPlantButtonWithDialog';

export default function AddNewPlantSection({ attachToEntity, attachedIndexPosition, showAvailablePlantsSelection }) {
  const [selectedPlantId, setSelectedPlantId] = useState(-1);
  const [addPlant, plants, updatePlantPartly] = usePlantsStore(useShallow((state) => [state.addPlant, state.plants, state.updatePlantPartly]));

  const availablePlants = useMemo(() => plants.filter((plant) => !plant.attachedEntityId), [plants]);
  const attachedInformation = {
    attachedEntityId: attachedIndexPosition !== undefined ? attachToEntity?.id : attachToEntity,
    attachedIndexPosition,
  };

  const handleSelectPlant = (event) => {
    setSelectedPlantId(event.target.value);
  };

  const handleAddNewPlant = () => {
    setSelectedPlantId(-1);
  };

  const handleAddAttachedPlant = () => {
    if (selectedPlantId && selectedPlantId !== -1) {
      updatePlantPartly(selectedPlantId, { ...attachedInformation, newlyCreated: true });
    }
    setSelectedPlantId(-1);
  };

  const showPlantsSelection = showAvailablePlantsSelection && availablePlants.length > 0;

  return (
    <Grid container spacing={2} alignItems="center">
      {showPlantsSelection && (
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="plant-type-label">Select plant</InputLabel>
            <Select
              label="Select plant"
              labelId="plant-type-label"
              id="plant-type-select"
              value={selectedPlantId}
              onChange={handleSelectPlant}
              size="medium"
            >
              <MenuItem key={-1} value={-1}>
                {' - '}
              </MenuItem>
              {availablePlants.map((plant) => (
                <MenuItem key={plant.id} value={plant.id}>{plant.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={showPlantsSelection ? 6 : 12} container justifyContent="center">
        {!showPlantsSelection || selectedPlantId <= 0 ? (
          <AddPlantButtonWithDialog addPlantCallback={handleAddNewPlant} addModifiers={attachedInformation} />
        ) : (
          <Button onClick={handleAddAttachedPlant} variant="contained" startIcon={<Add />}>
            Attach
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
