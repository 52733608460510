export const PLANT_CATEGORY = {
  FRUIT: { label: 'Fruit' },
  VEGETABLE: { label: 'Vegetable' },
  HERB: { label: 'Herb' },
  FLOWER: { label: 'Flower' },
  TREE: { label: 'Tree' },
  SHRUB: { label: 'Shrub' },
  CACTUS: { label: 'Cactus' },
  SUCCULENT: { label: 'Succulent' },
  GRASS: { label: 'Grass' },
  GROUND_COVER: { label: 'Ground cover' },
  CLIMBER: { label: 'Climber' },
};

export const PLANT_SHAPE = {
  FLOWER: { label: 'Flower' },
  BAMBOO: { label: 'Bamboo' },
};

export const SUN_ATTRIBUTE = {
  FULL_SUN: { label: 'Full sun' },
  MAINLY_SUN: { label: 'Mainly sun' },
  PARTIAL_SUN: { label: 'Partial sun' },
  MAINLY_SHADE: { label: 'Mainly shade' },
  SHADE: { label: 'Shade' },
};

export const getSunFactor = (shadeValue) => {
  if (shadeValue > 0.80) return SUN_ATTRIBUTE.FULL_SUN;
  if (shadeValue > 0.60) return SUN_ATTRIBUTE.MAINLY_SUN;
  if (shadeValue > 0.40) return SUN_ATTRIBUTE.PARTIAL_SUN;
  if (shadeValue > 0.20) return SUN_ATTRIBUTE.MAINLY_SHADE;
  return SUN_ATTRIBUTE.SHADE;
};

// Space to other plants
export const PLANT_DISTANCE_ATTRIBUTE = {
  BIG: { label: 'Big' },
  MEDIUM: { label: 'Medium' },
  SMALL: { label: 'Small' },
};

export const PLANT_MAX_SIZE_ATTRIBUTE = {
  VERY_BIG: { label: 'Very Big', size: 2 },
  BIG: { label: 'Big', size: 1.3 },
  MEDIUM: { label: 'Medium', size: 0.8 },
  SMALL: { label: 'Small', size: 0.4 },
  TINY: { label: 'Tiny', size: 0.25 },
};

export const YEARS_UNTIL_MAX_SIZE_ATTRIBUTE = {
  ONE: { label: '1 year', years: 1 },
  TWO: { label: '2 years', years: 2 },
  THREE: { label: '3 years', years: 3 },
  FOUR: { label: '4 years', years: 4 },
  FIVE: { label: '5 years', years: 5 },
  SIX: { label: '6 years', years: 6 },
  SEVEN: { label: '7 years', years: 7 },
  EIGHT: { label: '8 years', years: 8 },
  NINE: { label: '9 years', years: 9 },
  TEN: { label: '10 years', years: 10 },
};

// Recommended pot size
export const POT_SIZE_ATTRIBUTE = {
  BIG: { label: 'Big' },
  MEDIUM: { label: 'Medium' },
  SMALL: { label: 'Small' },
};

// Growth speed
export const GROWTH_SPEED_ATTRIBUTE = {
  FAST: { label: 'Fast' },
  MEDIUM: { label: 'Medium' },
  SLOW: { label: 'Slow' },
};

export const MONTH = {
  JANUARY: { label: 'January', month: 1 },
  FEBRUARY: { label: 'February', month: 2 },
  MARCH: { label: 'March', month: 3 },
  APRIL: { label: 'April', month: 4 },
  MAY: { label: 'May', month: 5 },
  JUNE: { label: 'June', month: 6 },
  JULY: { label: 'July', month: 7 },
  AUGUST: { label: 'August', month: 8 },
  SEPTEMBER: { label: 'September', month: 9 },
  OCTOBER: { label: 'October', month: 10 },
  NOVEMBER: { label: 'November', month: 11 },
  DECEMBER: { label: 'December', month: 12 },
  ALWAYS: { label: 'Always', month: 0 },
};

export const WATER_NEED_ATTRIBUTE = {
  MUCH: { label: 'Much' },
  MEDIUM: { label: 'Medium' },
  FEW: { label: 'Few' },
};

export const COLOR = {
  RED: { label: 'Red', color: 'red' },
  ORANGE: { label: 'Orange', color: 'orange' },
  YELLOW: { label: 'Yellow', color: 'yellow' },
  GREEN: { label: 'Green', color: 'green' },
  DARK_GREEN: { label: 'Dark green', color: 'darkgreen' },
  BLUE: { label: 'Blue', color: 'blue' },
  PURPLE: { label: 'Purple', color: 'purple' },
  WHITE: { label: 'White', color: 'white' },
  PINK: { label: 'Pink', color: 'pink' },
  MULTIPLE_COLORS: { label: 'Multiple colors', color: 'multiple' },
};

export const SOIL_ATTRIBUTE = {
  UNIVERSAL: { label: 'Universal soil' },
  NUTRIENT_RICH: { label: 'Nutrient-rich soil' },
  DRAINING: { label: 'Draining soil' },
  MOISTURE_RETENTIVE: { label: 'Moisture-retentive soil' },
  ACIDIC: { label: 'Acidic soil' },
  ALKALINE: { label: 'Alkaline soil' },
};

export const MINDESTTEMPERATUR_ATTRIBUTE = {
  MINUS_FIFTEEN: { label: '-15°C' },
  MINUS_TEN: { label: '-10°C' },
  MINUS_FIVE: { label: '-5°C' },
  ZERO: { label: '0°C' },
  FIVE: { label: '5°C' },
  TEN: { label: '10°C' },
  FIFTEEN: { label: '15°C' },
};

export const MAXTEMPERATUR_ATTRIBUTE = {
  TWENTYFIVE: { label: '25°C' },
  THIRTY: { label: '30°C' },
  THIRTYFIVE: { label: '35°C' },
  FORTY: { label: '40°C' },
};

export const WIND_SUSCEPTIBILITY_ATTRIBUTE = {
  HIGH: { label: 'High' },
  MEDIUM: { label: 'Medium' },
  LOW: { label: 'Low' },
};
