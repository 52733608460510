import React from 'react';
import { Shape } from 'three';

export const POT_VARIANTS = {
  TINY: {
    id: 'TINY',
    outerRadius: 0.1 / 2,
    innerRadius: 0.08 / 2,
    height: 0.08,
  },
  SMALL: {
    id: 'SMALL',
    outerRadius: 0.15 / 2,
    innerRadius: 0.12 / 2,
    height: 0.12,
  },
  MIDDLE: {
    id: 'MIDDLE',
    outerRadius: 0.23 / 2,
    innerRadius: 0.18 / 2,
    height: 0.17,
  },
  LARGE: {
    id: 'LARGE',
    outerRadius: 0.32 / 2,
    innerRadius: 0.256 / 2,
    height: 0.24,
  },
  VERY_LARGE: {
    id: 'VERY_LARGE',
    outerRadius: 0.45 / 2,
    innerRadius: 0.36 / 2,
    height: 0.3,
  },
};

function PlantPotSingle({
  variant = null,
  potColor = '#78866B',
}) {
  const {
    outerRadius, innerRadius, height,
  } = POT_VARIANTS[variant] || POT_VARIANTS.MIDDLE;
  const baseHeight = height * 0.8;
  const soilHeight = height * 0.6;

  const shape = new Shape();

  shape.moveTo(outerRadius, 0);
  shape.absarc(0, 0, outerRadius, 0, Math.PI * 2, false);

  const holePath = new Shape();
  holePath.moveTo(innerRadius, 0);
  holePath.absarc(0, 0, innerRadius, 0, Math.PI * 2, true);
  shape.holes.push(holePath);

  const extrudeSettings = {
    depth: height,
    bevelEnabled: false,
    curveSegments: 64,
  };

  return (
    <group>
      <mesh
        position={[0, height / 2, 0]}
        rotation={[-Math.PI / 2, 0, 0]}
        castShadow
        receiveShadow
        name="Plant pot outside"
      >
        <extrudeGeometry args={[shape, extrudeSettings]} />
        <meshStandardMaterial color={potColor} />
      </mesh>
      <mesh
        position={[0, baseHeight / 2, 0]}
        castShadow
        receiveShadow
        name="Plant pot bottom"
      >
        <cylinderGeometry args={[outerRadius, outerRadius, baseHeight, 64]} />
        <meshStandardMaterial color={potColor} />
      </mesh>

      <mesh
        position={[0, baseHeight + soilHeight / 2, 0]}
        castShadow
        receiveShadow
        name="Plant pot earth"
      >
        <cylinderGeometry args={[innerRadius, innerRadius, soilHeight, 64]} />
        <meshStandardMaterial color="#66410b" />
      </mesh>
    </group>
  );
}

export default PlantPotSingle;
