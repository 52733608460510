import { ENTITY_TYPE } from '../stores/entitiesStore';

export const getPlantPositions = (entity, forcedVariant = null) => {
  const entityType = ENTITY_TYPE[entity.type];
  const entityVariants = entityType.variants;
  let plantPositions;
  if (entityVariants?.length) {
    const selectedVariant = entityVariants.find((v) => v.id === (forcedVariant != null ? forcedVariant : entity.variant)) || entityVariants[0];
    plantPositions = selectedVariant.plantPositions;
  } else {
    plantPositions = entityType.plantPositions;
  }
  return plantPositions;
};
