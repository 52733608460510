import React, { useRef, useMemo } from 'react';
import {
  CatmullRomCurve3,
  Vector3,
  TubeGeometry,
  MeshStandardMaterial,
  DoubleSide,
  Shape,
  ShapeGeometry,
} from 'three';
import * as THREE from 'three';

let seedModifier = 0;
function seededRandom(seed) {
  const x = Math.sin(seed + ++seedModifier) * 10000;
  return x - Math.floor(x);
}

function PlantStem({
  id, age, heightFactor, curveFactor,
}) {
  const stemRef = useRef();
  const curve = useMemo(() => {
    const points = [
      new Vector3(0, 0, 0),
      new Vector3(0.1 * curveFactor, age * 0.2, 0),
      new Vector3(-0.1 * curveFactor, age * 0.4, seededRandom(id) * 0.2),
      new Vector3(0.5 * curveFactor, age * 0.6, seededRandom(id) * 0.3),
      new Vector3(-0.2 * curveFactor, age * 0.8, seededRandom(id) * 0.4),
      new Vector3(0, age * heightFactor, seededRandom(id) * 0.5),
    ];
    return new CatmullRomCurve3(points);
  }, [age, heightFactor, curveFactor]);

  const geometry = useMemo(
    () => new TubeGeometry(curve, 55, 0.02 + age * 0.01, 8, false),
    [curve, age],
  );

  const material = new MeshStandardMaterial({
    color: '#4c5115',
    side: DoubleSide,
  });

  return (
    <mesh ref={stemRef} geometry={geometry} material={material} receiveShadow castShadow />
  );
}

function PlantLeaf({
  position, scale, rotation, color,
}) {
  const leafRef = useRef();
  const geometry = useMemo(() => {
    const shape = new THREE.Shape();
    shape.absellipse(0, 0, 0.4 * scale, 0.2 * scale, 0, Math.PI * 2, false, 0);
    return new THREE.ShapeGeometry(shape);
  }, [scale]);

  const material = new MeshStandardMaterial({ color, side: DoubleSide });

  return (
    <mesh
      receiveShadow
      castShadow
      ref={leafRef}
      geometry={geometry}
      material={material}
      position={position}
      rotation={rotation}
    />
  );
}

function PlantPetal({
  id, bloomStrength, rotation, color = ['pink'],
}) {
  const petalRef = useRef();

  const randomColor = useMemo(() => {
    const rnd = Math.floor(seededRandom(id) * color.length);
    return color[rnd];
  }, [color]);

  const geometry = useMemo(() => {
    const shape = new Shape();
    shape.moveTo(0, 0);
    shape.quadraticCurveTo(0.2, 0.5, 0, 1);
    shape.quadraticCurveTo(-0.2, 0.5, 0, 0);
    return new ShapeGeometry(shape);
  }, []);

  const material = useMemo(
    () => new MeshStandardMaterial({ color: randomColor, side: DoubleSide }),
    [color],
  );

  return (
    <mesh
      ref={petalRef}
      geometry={geometry}
      material={material}
      rotation={rotation}
      scale={[0.5 * bloomStrength, 0.5 * bloomStrength, 0.5 * bloomStrength]}
      castShadow
      receiveShadow
    />
  );
}

function PlantFlower({ bloomStrength, color }) {
  if (bloomStrength <= 0.01) return null;
  const petalCount = 5;
  const petals = [];

  for (let i = 0; i < petalCount; i++) {
    const angle = (i / petalCount) * 2 * Math.PI;
    petals.push(
      <PlantPetal
        key={`petal-${i}`}
        id={i}
        bloomStrength={bloomStrength}
        rotation={[0, 0, angle]}
        color={color}
      />,
    );
  }

  // Slight offset above the pivot
  return <group position={[0, 0.1, 0]}>{petals}</group>;
}

function BasicPlant({
  id,
  growth,
  bloomStrength,
  bloomColor = ['pink'],
  foliageCovering,
  leafCount,
  leafColors = [{ range: 0.8, color: 'green' }, { range: 0.1, color: 'red' }, { range: 0.1, color: 'yellow' }],
}) {
  seedModifier = 0;

  const ageFactor = 2.4 + growth;

  const getRandomLeafColor = () => {
    const rnd = seededRandom(id) * leafColors.reduce((acc, curr) => acc + curr.range, 0);
    let color = 'green';
    for (let i = 0; i < leafColors.length; i++) {
      if (rnd < leafColors[i].range) {
        color = leafColors[i].color;
        break;
      }
    }
    return color;
  };

  const branches = useMemo(() => {
    const numBranches = Math.ceil(ageFactor / 2);
    const branchGroups = [];

    for (let b = 0; b < numBranches; b++) {
      const branchRotation = (Math.PI * 2 * b) / numBranches;
      const branchHeightFactor = 1 - b * 0.1;
      const curveFactor = 0.5 + b * 0.1;
      const leaves = [];
      const totalLeaves = 5 + b * 3;

      for (let i = 2; i < totalLeaves; i++) {
        if (seededRandom(id) > leafCount) continue;
        const angle = (Math.PI * 2 * i) / totalLeaves;
        const x = 0.9 * Math.sin(angle);
        const y = (i / (totalLeaves - 1)) * ageFactor * branchHeightFactor;
        const z = 0.5 * Math.cos(angle);
        const leafSize = (1 + i / totalLeaves) * (0.5 + (0.5 * ageFactor) / 10);

        const leafColor = getRandomLeafColor();

        leaves.push(
          <PlantLeaf
            key={`leaf-${b}-${i}`}
            position={[x, y, z]}
            scale={leafSize}
            rotation={[-(Math.PI / 2) * seededRandom(id), angle, 0]}
            color={leafColor}
          />,
        );
      }

      branchGroups.push(
        <group
          key={`branch-${b}`}
          position={[0, 0, 0]}
          rotation={[0, branchRotation, 0]}
        >
          <PlantStem
            id={id}
            age={ageFactor}
            heightFactor={branchHeightFactor}
            curveFactor={curveFactor}
          />
          {leaves}
        </group>,
      );
    }
    return branchGroups;
  }, [ageFactor, growth, bloomStrength, foliageCovering, leafCount, leafColors, bloomColor]);

  const flowerPositions = useMemo(() => {
    const count = Math.floor(seededRandom(id) * (5)) + 2 + ageFactor * 3; // between 3 and 20
    const arr = [];
    for (let i = 0; i < count; i++) {
      const angle = seededRandom(id) * Math.PI * 2;
      const radius = 0.3 + seededRandom(id) * 0.7; // random radial distance
      const x = Math.cos(angle) * radius;
      const z = Math.sin(angle) * radius;
      // negative Y so it's *below* the top sometimes
      const y = -(seededRandom(id) * 2);
      const rotationY = seededRandom(id) * Math.PI * 2;
      arr.push({
        position: [x, y, z],
        rotation: [1.0 + seededRandom(id), rotationY, 0],
      });
    }
    return arr;
  }, []);

  return (
    <group scale={ageFactor / 50}>
      {branches}
      <group position={[0, ageFactor, 0]}>
        {flowerPositions.map((fp, index) => (
          <group
            key={`flower-${index}`}
            position={fp.position}
            rotation={fp.rotation}
          >
            <PlantFlower bloomStrength={bloomStrength} color={bloomColor} />
          </group>
        ))}
      </group>
    </group>
  );
}

export default BasicPlant;
