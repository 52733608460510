import React, { useCallback, useMemo, useState } from 'react';
import {
  Button, Card, Divider, Grid,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useShallow } from 'zustand/react/shallow';
import CustomDateTimePicker from '../DateTimePicker';
import AddressAutocomplete from '../AddressAutocomplete';
import useUiStore, { SELECTION_MODE } from '../../stores/uiStore';
import useScenarioStore from '../../stores/scenarioStore';
import {
  degreesToRadians,
  getBoundingBox,
  radiansToDegrees,
} from '../../utils/geoCalculationUtils';
import useViewStore from '../../stores/viewStore';
import BalconyDetailsContent from './BalconyDetailsContent';
import KeyValueLabel from '../../components/KeyValueLabel';
import EditableCardSection from '../EditableCardSection';

export default function SourroundingContent() {
  const { setSelectedBuilding, selectedBuilding, balconyCalculatedRotationY } = useScenarioStore();
  const { setSelectionMode, selectedPlace } = useUiStore();
  const [setFocusOnId] = useViewStore(useShallow((state) => [state.setFocusOnId]));

  const balconyDirectionLabel = useMemo(() => {
    const rotationInDegrees = radiansToDegrees(balconyCalculatedRotationY);
    if (rotationInDegrees >= 260) {
      return 'North';
    }
    if (rotationInDegrees >= 160) {
      return 'East';
    }
    if (rotationInDegrees >= 70) {
      return 'South';
    }
    return 'West';
  }, [balconyCalculatedRotationY]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <EditableCardSection
          disableEdit
          label="Location"
          LabelIcon={LocationOnIcon}
          renderChildrenFunction={(_) => (
            <Grid container spacing={2} padding={0}>
              <Grid item xs={12}>
                <KeyValueLabel label="Address" value={selectedPlace?.description || 'Not selected'} />
              </Grid>
              <Grid item xs={6}>
                <KeyValueLabel label="Balcony direction" value={balconyDirectionLabel} />
              </Grid>
              <Grid item xs={6}>
                <Button
                  sx={{ float: 'right', marginTop: 1 }}
                  color="primary"
                  variant="contained"
                  onClick={() => { setSelectionMode(SELECTION_MODE.BUILDING); setFocusOnId(selectedBuilding.id); }}
                >
                  Setup
                </Button>
              </Grid>
            </Grid>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <BalconyDetailsContent />
      </Grid>
    </Grid>
  );
}
