/* eslint-disable no-param-reassign */
import create from 'zustand';
import React from 'react';
import { immer } from 'zustand/middleware/immer';
import { persist } from 'zustand/middleware';
import {
  Home as HomeIcon,
  Deck as DeckIcon,
  Nature as NatureIcon,
  ShowChart as ShowChartIcon,
} from '@mui/icons-material';
import SourroundingContent from '../fragments/sidebar/SourroundingContent';
import BalconyDetailsContent from '../fragments/sidebar/BalconyDetailsContent';
import EntitiesContent from '../fragments/sidebar/EntitiesContent';
import PlantsContent from '../fragments/sidebar/PlantsContent';
import PlantDetails from '../fragments/overlay/PlantDetails';
import ShadeDetailsPage from '../fragments/overlay/ShadeDetails';
import AnalyzeContent from '../fragments/sidebar/AnalyzeContent';

export const SELECTION_MODE = { BUILDING: 'BUILDING', BALCONY_POSITION: 'BALCONY_POSITION' };
export const TABS_CONFIG = Object.freeze({
  SURROUNDING: {
    id: 'SURROUNDING', name: 'Basic', Component: SourroundingContent, icon: <HomeIcon />,
  },
  ENTITIES: {
    id: 'ENTITIES', name: 'Furniture', Component: EntitiesContent, icon: <DeckIcon />,
  },
  PLANTS: {
    id: 'PLANTS', name: 'Plants', Component: PlantsContent, icon: <NatureIcon />,
  },
  ANALYZE: {
    id: 'ANALYZE', name: 'Analyze', Component: AnalyzeContent, icon: (<ShowChartIcon />),
  },
});

export const OVERLAY_CONFIG = Object.freeze({
  PLANT_DETAILS: {
    id: 'PLANT_DETAILS', name: 'Select plant', Component: PlantDetails, maxWidth: 'xl',
  },
  SHADE_DETAILS: { id: 'SHADE_DETAILS', name: 'Shade Details', Component: ShadeDetailsPage },
});
export const VIEW_EVENTS = {
  ZOOM_IN: 'ZOOM_IN',
  ZOOM_OUT: 'ZOOM_OUT',
};
const useUiStore = create(
  persist(
    immer((set) => ({
      selectedTab: TABS_CONFIG.SURROUNDING.id,
      setSelectedTab: (selectedTab) => set((state) => {
        state.selectedTab = selectedTab;
      }),
      selectedPlace: {
        description: 'Breitscheidplatz, Berlin, Deutschland',
        matched_substrings: [
          {
            length: 14,
            offset: 0,
          },
        ],
        place_id: 'ChIJ7Yx6df9QqEcR2jVqo3TsiLI',
        reference: 'ChIJ7Yx6df9QqEcR2jVqo3TsiLI',
        structured_formatting: {
          main_text: 'Breitscheidplatz',
          main_text_matched_substrings: [
            {
              length: 14,
              offset: 0,
            },
          ],
          secondary_text: 'Berlin, Deutschland',
        },
        terms: [
          {
            offset: 0,
            value: 'Breitscheidplatz',
          },
          {
            offset: 18,
            value: 'Berlin',
          },
          {
            offset: 26,
            value: 'Deutschland',
          },
        ],
        types: [
          'geocode',
          'town_square',
        ],
        boundingBox: {
          min: {
            lat: 52.50113241357632,
            lon: 13.329321775058263,
          },
          max: {
            lat: 52.508326986423675,
            lon: 13.341141424941737,
          },
        },
        lat: 52.5047297,
        lon: 13.3352316,
      },
      setSelectedPlace: (selectedPlace) => set((state) => {
        state.selectedPlace = selectedPlace;
      }),
      selectionMode: null,
      setSelectionMode: (selectionMode) => set((state) => {
        state.selectionMode = selectionMode;
      }),
      selectedOverlayConfig: null,
      overlayData: null,
      openPlantDetails: (plantId, defaultPage) => set((state) => {
        state.selectedOverlayConfig = OVERLAY_CONFIG.PLANT_DETAILS.id;
        state.overlayData = { plantId, defaultPage };
      }),
      openShadeDetails: (entityId) => set((state) => {
        state.selectedOverlayConfig = OVERLAY_CONFIG.SHADE_DETAILS.id;
        state.overlayData = { entityId };
      }),
      closeOverlay: () => set((state) => {
        state.selectedOverlayConfig = null;
        state.overlayData = null;
      }),
      newEvents: [],
      addNewEvent: (newEvent) => set((state) => {
        state.newEvents = [...state.newEvents, newEvent];
      }),
      clearEvents: () => set((state) => {
        state.newEvents = [];
      }),
      showShadowLayer: false,
      setShowShadowLayer: (showShadowLayer) => set((state) => {
        state.showShadowLayer = showShadowLayer;
      }),
      transformControlActive: false,
      setTransformControlActive: (transformControlActive) => set((state) => {
        state.transformControlActive = transformControlActive;
      }),
      isFullscreen: false,
      setIsFullscreen: (fullscreen) => set((state) => {
        state.isFullscreen = fullscreen;
      }),
      openedEditableSections: [],
      addOpenedEditableSection: (openedEditableSection) => set((state) => {
        if (state.openedEditableSections.includes(openedEditableSection)) return;
        state.openedEditableSections = [...state.openedEditableSections, openedEditableSection];
      }),
      removeOpenedEditableSection: (openedEditableSection) => set((state) => {
        state.openedEditableSections = state.openedEditableSections.filter((section) => section !== openedEditableSection);
      }),
      clickedOnCanvasEntity: null,
      setClickedOnCanvasEntity: (clickedOnCanvasEntity) => set((state) => {
        state.clickedOnCanvasEntity = clickedOnCanvasEntity;
      }),
    })),
    {
      name: 'ui-store', // unique name
      getStorage: () => localStorage,
      partialize: (state) => Object.fromEntries(
        Object.entries(state).filter(([key]) => !['transformControlActive', 'clickedOnCanvasEntity'].includes(key)),
      ),
    },
  ),
);

export default useUiStore;
