import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';
import useUiStore, { TABS_CONFIG } from '../../stores/uiStore';
import useViewStore from '../../stores/viewStore';
import useIsMobile from '../../hooks/useIsMobile';
import usePlantsStore from '../../stores/plantsStore';

function DotMarker() {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '8px',
        left: '2px',
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        backgroundColor: '#ff6d50',
        boxShadow: '0 0 4px rgba(33,150,243,0.5)',
      }}
    />
  );
}

export default function NavigationBar() {
  const [selectedTab, setSelectedTab, selectionMode, setClickedOnCanvasEntity, showShadowLayer] = useUiStore((state) => [
    state.selectedTab,
    state.setSelectedTab,
    state.selectionMode,
    state.setClickedOnCanvasEntity,
    state.showShadowLayer,
  ]);
  const [setFocusOnId] = useViewStore((state) => [state.setFocusOnId]);
  const [
    growingSimulationEnabled,
  ] = usePlantsStore(
    useShallow((state) => [
      state.growingSimulationEnabled,
    ]),
  );

  const isMobile = useIsMobile();

  const handleTabChange = (tab) => {
    if (selectionMode) {
      return;
    }
    setClickedOnCanvasEntity(null);
    setFocusOnId(null);
    setSelectedTab(tab.id);
  };

  const NavigationSx = isMobile ? {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 22,
    bgcolor: '#8CAA62',
    borderTop: 1,
    borderColor: 'divider',
  } : {
    py: 2,
    borderBottom: 1,
    borderColor: 'divider',
    backgroundColor: '#8CAA62',
  };

  const buttonsAreDeactivated = useMemo(() => selectionMode != null, [selectionMode]);

  const checkShowPointMarker = useCallback((key) => TABS_CONFIG.ANALYZE.id === key
      && (
        growingSimulationEnabled || showShadowLayer
      ), [selectedTab, growingSimulationEnabled, showShadowLayer]);

  return (
    <BottomNavigation
      value={selectedTab}
      onChange={(_, value) => handleTabChange(TABS_CONFIG[value])}
      showLabels
      sx={NavigationSx}
    >
      {Object.keys(TABS_CONFIG).map((key) => (
        <BottomNavigationAction
          key={key}
          disabled={buttonsAreDeactivated}
          label={TABS_CONFIG[key].name}
          value={key}
          icon={(
            <Box sx={{ position: 'relative', padding: '4px' }}>
              {TABS_CONFIG[key].icon}
              {checkShowPointMarker(key) && !buttonsAreDeactivated && <DotMarker />}
            </Box>
          )}
          sx={{
            minWidth: 'auto',
            px: isMobile ? 2 : 1,
            color: buttonsAreDeactivated ? '#d3d3d3' : '#fbfbfb',
            '&.Mui-selected': {
              color: buttonsAreDeactivated ? '#d5d5d5' : '#fff',
              fontWeight: 'bold',
            },
            '@keyframes pulse': {
              '0%': { transform: 'scale(0.93)' },
              '50%': { transform: 'scale(1)' },
              '100%': { transform: 'scale(0.93)' },
            },
            animation: checkShowPointMarker(key) ? 'pulse 2s infinite' : 'none',
          }}
        />
      ))}
    </BottomNavigation>
  );
}
